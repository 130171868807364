<template>
<div>
  <div class="card">
    <div class="card-body">
      <div class="row">
        <display-field v-if="isUpdate" label="Processor ID" group-class="col-6">{{tpa.tpId}}</display-field>
        <input-text v-else label="Processor ID" v-model="tpa.tpId" group-class="col-6" focus="true"
          :errors="errors" error-key="tpId"></input-text>
        <div class="col-6">
          <div class="form-check col-12 ms-3 py-2">
            <input type="checkbox" class="form-check-input" v-model="tpa.active"/>
            <label class="form-check-label" style="font-weight: 600;">Is Active</label>
          </div>
        </div>

        <div class="col-12 my-3">
          <div class="form-check form-check-inline">
            <input class="form-check-input" type="radio" v-model="tpa.implType" value="LAMBDA"/>
            <label class="form-check-label">AWS Lambda</label>
          </div>
          <div class="form-check form-check-inline">
            <input class="form-check-input" type="radio" v-model="tpa.implType" value="INLINE"/>
            <label class="form-check-label">Inline</label>
          </div>
          <div class="form-check form-check-inline">
            <input class="form-check-input" type="radio" v-model="tpa.implType" value="LAMBDA_V0"/>
            <label class="form-check-label">AWS Lambda (Old)</label>
          </div>
        </div>
        <template v-if="tpa.implType === 'INLINE'">
          <input-text label="Implementation Class" v-model="tpa.implSpec" group-class="col-12"
            :errors="errors" error-key="implSpec"></input-text>
        </template>
        <template v-else>
          <input-text label="Lambda Function Name" v-model="tpa.implSpec" group-class="col-12"
            :errors="errors" error-key="implSpec"></input-text>
        </template>

        <input-text label="Group Code" v-model="tpa.group" group-class="col-6"></input-text>
        <input-text label="Description" v-model="tpa.tpDesc" group-class="col-12"
          :errors="errors" error-key="tpDesc"></input-text>

      </div>
      <div class="row">
        <input-number label="Load Weight" v-model="tpa.loadWeight" group-class="col-2"
          :errors="errors" error-key="loadWeight"></input-number>
        <input-number label="Monthly Threshold" v-model="tpa.monthlyThreshold" group-class="col-3" :prepend="'$'"
          :errors="errors" error-key="monthlyThreshold"></input-number>
        <input-number label="Daily Threshold" v-model="tpa.dailyThreshold" group-class="col-3" :prepend="'$'"
          :errors="errors" error-key="dailyThreshold"></input-number>
      </div>
      <div class="row">
        <input-number label="Setup Fee" v-model="tpa.setupFee" group-class="col-2" :prepend="'$'"
          :errors="errors" error-key="setupFee"></input-number>
        <input-number label="Annual Fee" v-model="tpa.annualFee" group-class="col-2" :prepend="'$'"
          :errors="errors" error-key="annualFee"></input-number>
        <input-number label="Rolling Reserve" v-model="tpa.rollingReserve" group-class="col-2" :append="'%'" input-class="text-end"
          :errors="errors" error-key="rollingReserve"></input-number>
      </div>
      <div class="row">
        <input-number label="Processing Fee" v-model="tpa.processingFee" group-class="col-2" :append="'%'" input-class="text-end"
          :errors="errors" error-key="processingFee"></input-number>
        <input-number label="Transaction Fee" v-model="tpa.transactionFee" group-class="col-2" :prepend="'$'"
          :errors="errors" error-key="transactionFee"></input-number>
        <input-number label="Settlement Fee" v-model="tpa.settlementFee" group-class="col-2" :prepend="'$'"
          :errors="errors" error-key="settlementFee"></input-number>
      </div>
      <div class="row">
        <input-number label="Chargeback Fee" v-model="tpa.chargebackFee" group-class="col-2" :prepend="'$'"
          :errors="errors" error-key="chargebackFee"></input-number>
        <input-number label="Refund Fee" v-model="tpa.refundFee" group-class="col-2" :prepend="'$'"
          :errors="errors" error-key="refundFee"></input-number>
        <input-number label="Refunded CB Fee" v-model="tpa.refundedCbFee" group-class="col-2" :prepend="'$'"
          :errors="errors" error-key="refundedCbFee"></input-number>
      </div>
    </div>
  </div>
  <br>
  <h5 class="text-start mb-3">Proc Attributes</h5>
  <div class="mt-2">
    <table class="table table-sm">
      <thead class="table-dark small">
        <tr>
          <th width="30%">Attribute Name</th>
          <th width="65%">Attribute Value</th>
          <th width="5%">&nbsp;</th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="(attr, index) in attribs" v-bind:key="index">
          <td><input class="form-control form-control-sm" type="text" v-model="attr.name"/></td>
          <td><input class="form-control form-control-sm" type="text" v-model="attr.value"/></td>
          <td class="text-center"><a href="#"><i class="fas fa-trash-alt" v-on:click="onDelAttrib(index)"></i></a></td>
        </tr>
        <tr>
          <td colspan="2">&nbsp;</td>
          <td class="text-center"><a href="#"><i class="fas fa-plus" v-on:click="onAddAttrib()"></i></a></td>
        </tr>
      </tbody>
    </table>
  </div>
  <h5 class="text-start mb-3">Proc Error Handling Rules</h5>
  <div class="mt-2">
    <table class="table table-sm">
      <thead class="table-dark small">
      <tr>
        <th width="30%">Attribute Name</th>
        <th width="10%">&nbsp;</th>
        <th width="30%">Condition Value</th>
        <th width="25">Error Code</th>
        <th width="5%">&nbsp;</th>
      </tr>
      </thead>
      <tbody>
      <tr v-for="(rule, index) in procErrorHandlingRules" v-bind:key="index">
        <td><input class="form-control form-control-sm" type="text" v-model="rule.attrName"/></td>
        <td class="text-center">contains</td>
        <td><input class="form-control form-control-sm" type="text" v-model="rule.condValue"/></td>
        <td>
          <select class="form-control form-control-sm" v-model="rule.ccProcResultCode">
            <option value="SUCCESS">00 - Charge to card successful</option>
            <option value="AUTHREQD">01 - 3DS/VBV authorization required</option>
            <option value="PENDING">02 - Pending</option>
            <option value="PROCERROR">50 - Processor Error</option>
            <option value="MAXPROCLIMIT">60 - Processor limit reached</option>
            <option value="MAXGATEWAYLIMIT">61 - Daily gateway limit exceeded</option>
            <option value="WEEKLYGATEWAYLIMIT">62 - Weekly gateway limit exceeded</option>
            <option value="MONTHLYGATEWAYLIMIT">63 - Monthly gateway limit exceeded</option>
            <option value="DAILYCARDCOUNT">64 - Daily card txn count exceeded</option>
            <option value="WEEKLYCARDCOUNT">65 - Weekly card txn count exceeded</option>
            <option value="MONTHLYCARDCOUNT">66 - Monthly card txn count exceeded</option>
            <option value="NON3DSCARD">67 - Card not enrolled in 3D secure</option>
            <option value="DONOTHONOUR">70 - Do not honour</option>
            <option value="CURRENCYINVALID">71 - Invalid currency code</option>
            <option value="BLACKLISTEDEMAIL">72 - Blacklisted email</option>
            <option value="MAXTICKETLIMIT">73 - Over Max Ticket limit</option>
            <option value="COUNTRYNOTALLOWED">74 - Country not allowed</option>
            <option value="IPADDRESSBLOCKED">75 - IP address is blocked</option>
            <option value="PHONEINVALID">76 - Invalid mobile number</option>
            <option value="MASTERCARDVIOLATION">77 - Mastercard Policy Violation</option>
            <option value="MAXTXNFRQ">79 - Txn frequency exceeds limit</option>
            <option value="ADDRMISMATCH">81 - Address mismatch</option>
            <option value="CARDLIMIT">82 - Card limit exceeded</option>
            <option value="MAXDAILYTXN">83 - Daily card limit exceeded</option>
            <option value="EXPIREDCARD">84 - Expired Card</option>
            <option value="DUPTXN">85 - Duplicate transaction</option>
            <option value="RESTRICTED">86 - Restricted card</option>
            <option value="HIGHRISK">87 - High Risk</option>
            <option value="NOPROCESSOR">88 - No processor available</option>
            <option value="AUTHTIMEDOUT">89 - 3DS/VBV authorization timed out</option>
            <option value="CARDNOTSUPPORTED">90 - Card not supported</option>
            <option value="CARDINVALID">91 - Card details invalid</option>
            <option value="CVVINVALID">92 - CVV/CVC invalid</option>
            <option value="INSUFFICIENTFUNDS">93 - Insufficient funds</option>
            <option value="NONUSDCARD">94 - Non-USD Card</option>
            <option value="NOTWHITELISTED">95 - Card not whitelisted</option>
            <option value="AUTHFAILED">96 - 3DS/VBV authorization failed</option>
            <option value="FRAUD">97 - Suspected fraudulent card</option>
            <option value="DECLINED">98 - Declined</option>
            <option value="SYSERR">99 - System error</option>
          </select>
        </td>
        <td class="text-center"><a href="#"><i class="fas fa-trash-alt" v-on:click="onDelRule(index)"></i></a></td>
      </tr>
      <tr>
        <td colspan="4">&nbsp;</td>
        <td class="text-center"><a href="#"><i class="fas fa-plus" v-on:click="onAddRule()"></i></a></td>
      </tr>
      </tbody>
    </table>
  </div>
  <div class="text-end">
    <button class="btn btn-secondary me-2" v-on:click="onCancel()">Cancel</button>
    <button class="btn btn-primary" v-on:click="onSave()">Save</button>
  </div>
</div>
</template>

<script>
import { ErrorsContainer } from '../utils/ErrorsContainer.js'
export default {
  name: 'TpAttribsForm',
  props: {
    tpAttrib: Object
  },
  data () {
    return {
      tpa: {},
      attribs: [],
      procErrorHandlingRules: [],
      mode: 'create',
      errors: new ErrorsContainer()
    }
  },
  computed: {
    isUpdate: function () {
      if (this.mode === 'update') {
        return true
      }
      return false
    },
    isCreate: function () {
      if (this.mode === 'create') {
        return true
      }
      return false
    }
  },
  mounted () {
    if (this.tpAttrib) {
      if (this.tpAttrib.tpId) {
        this.mode = 'update'
      } else {
        this.mode = 'create'
      }
      Object.assign(this.tpa, this.tpAttrib)
      this.procErrorHandlingRules = this.tpa.procErrorHandlingRules
      this.propsToAttribs(this.tpAttrib.attribs)
    } else {
      this.mode = 'create'
    }
    window.scrollTo(0,0)
  },
  methods: {
    onAddAttrib: function () {
      this.attribs.push({ name: '', value: '' })
    },
    onDelAttrib: function (idx) {
      this.attribs.splice(idx, 1)
    },
    onAddRule: function () {
      this.procErrorHandlingRules.push({ attrName: '', condValue: '', ccProcResultCode: '' })
    },
    onDelRule: function (idx) {
      this.procErrorHandlingRules.splice(idx, 1)
    },
    onSave: function () {
      this.$forceUpdate()
      this.attribsToMap()
      Object.assign(this.procErrorHandlingRules, this.tpa.procErrorHandlingRules)
      this.tpa.procErrorHandlingRules = this.procErrorHandlingRules

      this.errors.clearAll()
      this.axios.post('/ax/tpattribs/' + this.mode, this.tpa)
        .then(response => {
          this.$emit('saved')
        })
        .catch(error => {
          if (error.response.status === 422) {
            if (error.response.data.fieldErrors) {
              this.errors.setFldErrors(error.response.data.fieldErrors)
            } else {
              this.errors.addError('tpId', error.response.data.msgText)
            }
          }
        })
    },
    onCancel: function () {
      this.$emit('cancelled')
    },
    propsToAttribs: function (props) {
      for (var key in props) {
        this.attribs.push({ name: key, value: props[key] })
      }
    },
    attribsToMap: function () {
      var attribMap = {}
      for (var i = 0; i < this.attribs.length; i++) {
        attribMap[this.attribs[i].name] = this.attribs[i].value
        /*
        attribText += this.attribs[i].name + '=' + this.attribs[i].value
        if (i < (this.attribs.length - 1)) {
          attribText += '|'
        }
 */
      }
      this.tpa.attribs = attribMap
    }
  }
}
</script>
