<template>
<div>
  <page-title title="Summaries" row-class="mb-1"/>

  <div class="row text-secondary small mb-3 mt-0 pt-0">
    <div class="col-auto mx-auto">
      <span v-if="timeAgo">As of {{ timeAgo }}</span>
    </div>
  </div>

  <div class="row">
    <div class="card w-100 px-0">
      <div class="card-header">
        <ul class="nav nav-tabs card-header-tabs">
          <li class="nav-item"><a class="nav-link" :class="{ active: isActive(1) }" @click="tabNum = 1">Merchant Summary</a></li>
          <li class="nav-item"><a class="nav-link" :class="{ active: isActive(2) }" @click="tabNum = 2">Processor Summary</a></li>
          <li class="nav-item"><a class="nav-link" :class="{ active: isActive(3) }" @click="tabNum = 3">Chargeback Summary</a></li>
        </ul>
      </div>
      <div class="card-body">
        <merchant-txns-overview v-if="tabNum === 1" ref="mxOverview"></merchant-txns-overview>
        <processor-txn-summary v-if="tabNum === 2" ref="procOverview"></processor-txn-summary>
        <chargeback-summary v-if="tabNum === 3" ref="cbOverview"></chargeback-summary>
      </div>
    </div>
  </div>
</div>
</template>

<script>
import moment from 'moment'
import MerchantTxnsOverview from '../components/MerchantTxnsOverview.vue'
import ProcessorTxnSummary from '../components/ProcessorTxnSummary.vue'
import ChargebackSummary from '../components/ChargebackSummary.vue'
export default {
  name: 'Summaries',
  components: {
    MerchantTxnsOverview,
    ProcessorTxnSummary,
    ChargebackSummary
  },
  data () {
    return {
      poller: null,
      refreshTimer: null,
      lastRefresh: new Date(),
      timeAgo: null,
      tabNum: 1
    }
  },
  mounted () {
    this.poller = setInterval(this.refresh, 300000)
    this.refreshTimer = setInterval(this.setTimeAgo, 60000)
    this.$watch('tabNum', this.onTabChange)
    this.setTimeAgo()
  },
  beforeDestroy () {
    if (this.poller) {
      clearInterval(this.poller)
    }
    if (this.refreshTimer) {
      clearInterval(this.refreshTimer)
    }
  },
  methods: {
    setTimeAgo: function () {
      this.timeAgo = moment(this.lastRefresh).fromNow()
    },
    isActive: function (n) {
      if (n === this.tabNum) {
        return true
      }
      return false
    },
    onTabChange: function () {
      this.lastRefresh = new Date()
      this.setTimeAgo()
    },
    refresh: function () {
      if (this.tabNum === 1) {
        this.$refs.mxOverview.getSummaries()
      } else if (this.tabNum === 2) {
        this.$refs.procOverview.getSummary()
      } else if (this.tabNum === 3) {
        this.$refs.cbOverview.getSummaries()
      }
      this.lastRefresh = new Date()
      this.setTimeAgo()
    }
  }
}
</script>

<style scoped>
.nav-link { font-weight: 500; font-size: small; }
.active { font-weight: bold; color: blue !important; }
</style>
