import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    token: JSON.parse(sessionStorage.getItem('axtoken') || '{}'),
    searchParams: null,
    periodOpt: null
  },
  mutations: {
    saveToken (state, t) {
      sessionStorage.setItem('axtoken', JSON.stringify(t))
      state.token = t
    },
    clearToken (state, t) {
      sessionStorage.removeItem('axtoken')
      state.token = {}
    },
    saveSearchParams (state, p) {
      state.searchParams = p
    },
    clearSearchParams (state, p) {
      state.searchParams = null
    },
    savePeriodOpt (state, opt) {
      state.periodOpt = opt
    }
  },
  actions: {

  }
})
