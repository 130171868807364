import { ErrorsContainer } from '../utils/ErrorsContainer.js'
export default {
  data () {
    return {
      errors: new ErrorsContainer(),
      tzList: [],
      isLoading: false
    }
  },
  methods: {
    handleError: function (error) {
      this.isLoading = false
      if (error.response && error.response.data && error.response.data.msgCode) {
        const errMsg = error.response.data
        this.$toasted.error(errMsg.msgText)
      } else {
        console.log(JSON.stringify(error))
        this.$toasted.error('Unexpected system error ... please try again later')
      }
    },
    handleValidationErrors: function (error) {
      this.isLoading = false
      this.errors.clearAll()
      if (error.response.data && error.response.data.msgCode) {
        const errMsg = error.response.data
        if (errMsg.msgCode === 'FLD.ERRORS') {
          this.errors.setFldErrors(errMsg.fieldErrors)
        } else {
          this.$toasted.error(errMsg.msgText)
        }
      } else {
        console.log(JSON.stringify(error))
        this.$toasted.error('Unexpected system error ... please try again later')
      }
    },
    getTimezones: function () {
      this.axios.get('/ax/refdata/TIMEZONE')
      .then(response => {
        this.tzList = response.data
      })
      .catch(error => this.handleError(error))
    },
    lookupTzDesc: function (tzcode) {
      for (const tz of this.tzList) {
        if (tzcode === tz.refCode) {
          return tz.refValue
        }
      }
      return tzcode
    }
  }
}
