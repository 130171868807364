<template>
<div class="container">
  <div class="row mb-4 fw-bold text-info">
    <div class="col-auto">Transaction: {{txn.txnId}}</div>
    <div class="col-auto ms-auto">Amount: ${{txn.txnAmount.value  | number('0,0.00')}} {{txn.txnAmount.currency}}</div>
  </div>
  
  <div class="row mb-2">
    <div class="col-auto form-group">
      <label>Process Date</label>
      <input class="form-control" type="datetime-local" v-model="memoReq.procDateStr"/>
    </div>
    <div class="col-auto">
      <input-select v-model="memoReq.procTimezone" group-class="col-auto" label="Timezone">
        <option v-for="tz in tzList" :key="tz.refCode" :value="tz.refCode">{{tz.refValue}}</option>
      </input-select>
    </div>
  </div>
  
  <div class="row mb-2">
    <div class="col form-group">
      <label>Description/Reason</label>
      <textarea  class="form-control" rows="3" v-model="memoReq.description"></textarea>
    </div>
  </div>
  
  <div class="row">
    <input-text label="Processor reference" v-model="memoReq.procRef" placeholder="processor's reference number" group-class="col"/>
  </div>
  
  <div class="row mt-4">
    <div v-if="processing" class="col-auto ms-auto text-primary">
      <b-spinner variant='primary' label='Please wait'></b-spinner>
      <span class="ms-3 mt-1">Processing ... Please wait</span>
    </div>
    <div v-else class="col-auto ms-auto">
      <button class="btn btn-secondary me-2" @click.prevent="onCancel()">Cancel</button>
      <button class="btn btn-primary" @click.prevent="onSave()">Save</button>
    </div>
  </div>
  
</div>
</template>

<script>
import HelpersMixin from '../utils/HelpersMixin.js'
export default {
  name: 'MemoTxnForm',
  mixins: [HelpersMixin],
  props: {
    txn: Object,
    memoType: String
  },
  data () {
    return {
      processing: false, 
      memoReq: { refTxnId: this.txn.txnId, txnAmount: this.txn.txnAmount, procDateStr: null, timezone: 'UTC' }
    }
  },
  mounted () {
    this.getTimezones()
  },
  methods: {
    onSave: function () {
      if (!this.memoReq.procDateStr || !this.memoReq.procTimezone) {
        this.$toasted.error('Process Date and Timezone required')
      }
      this.processing = true
      var apiPath = '/ax/txn/' + this.memoType.toLowerCase()
      this.axios.post(apiPath, this.memoReq)
        .then(response => {
          this.processing = false
          this.$emit('saved')
        })
        .catch(error => {
          this.processing = false
          this.handleError(error)
        })
    },
    onCancel: function () {
      this.$emit('cancelled')
    }
  }
}
</script>

<style scoped>
label { font-size: 75%; margin-bottom: 1px; font-weight: 600; }
</style>
