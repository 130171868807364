<template>
<div>
  <wait-loading :loading="isLoading"/>

  <div class="row mt-2 mb-3">
    <a href="#" @click.prevent="prevDay" class="col-auto ms-auto" v-b-tooltip.hover title="Previous Day" style="text-decoration: none;">&lt;&nbsp;</a>
    <input-date v-model="inDate" group-class="col-auto" input-class="fw-bold"></input-date>
    <a href="#" @click.prevent="nextDay" class="col-auto me-auto" v-b-tooltip.hover title="Next Day" style="text-decoration: none;">&nbsp;&gt;</a>
    <a href="#" @click.prevent="getSummary()" class="col-auto me-2 mt-2 text-info" v-b-tooltip.hover title="Refresh"><i class="fas fa-sync"></i></a>
  </div>
  
  <div>
    <div class="mt-2">
      <table class="table table-bordered small w-100">
        <thead class="table-dark">
          <tr>
            <th rowspan="2" class="text-center">Processor ID</th>
            <th colspan="2" class="text-center">Failed</th>
            <th colspan="2" class="text-center">Success</th>
            <th colspan="2" class="text-center">Total Processed</th>
            <th colspan="2" class="text-center">Success Rate</th>
            <th colspan="2" class="text-center">MTD Success</th>
          </tr>
          <tr>
            <th class="text-center bg-secondary">Count</th>
            <th class="text-center bg-secondary">Amount</th>
            <th class="text-center bg-secondary">Count</th>
            <th class="text-center bg-secondary">Amount</th>
            <th class="text-center bg-secondary">Count</th>
            <th class="text-center bg-secondary">Amount</th>
            <th class="text-center bg-secondary">on Count</th>
            <th class="text-center bg-secondary">on Amount</th>
            <th class="text-center bg-secondary">Count</th>
            <th class="text-center bg-secondary">Amount</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="(ps, index) in sortByProcId(psummary.procLoadMap)" v-bind:key="index">
            <td class="text-start">{{ps.procId}}</td>
            <td class="text-center failedCol">{{ps.failedCount}}</td>
            <td class="text-end pe-4 failedCol">{{ps.failedAmount | number('0,0.00')}}</td>
            <td class="text-center successCol">{{ps.accumCount}}</td>
            <td class="text-end pe-4 successCol">{{ps.accumAmount | number('0,0.00')}}</td>
            <td class="text-center bg-light">{{ps.processedCount}}</td>
            <td class="text-end pe-4 bg-light">{{ps.processedAmount | number('0,0.00')}}</td>
            <td class="text-end pe-4">{{calcSuccessRateOnCount(ps) | number('0,0.00')}} %</td>
            <td class="text-end pe-4">{{calcSuccessRateOnAmount(ps) | number('0,0.00')}} %</td>
            <td class="text-end pe-4">{{ps.currentMtdSuccessCount}}</td>
            <td class="text-end pe-4">{{ps.currentMtdSuccessAmount | number('0,0.00')}}</td>
          </tr>
          <tr class="bg-light fw-bold">
            <td class="text-start pt-2">TOTALS</td>
            <td class="text-center pt-2 failedCol">{{psummary.failedCount}}</td>
            <td class="text-end pe-4 pt-2 failedCol">{{psummary.failedAmount | number('0,0.00')}}</td>
            <td class="text-center pt-2 successCol">{{psummary.successCount}}</td>
            <td class="text-end pe-4 pt-2 successCol">{{psummary.successAmount | number('0,0.00')}}</td>
            <td class="text-center pt-2">{{psummary.processedCount}}</td>
            <td class="text-end pe-4 pt-2">{{psummary.processedAmount | number('0,0.00')}}</td>
            <td class="text-end pe-4">{{totalSuccessRateOnCount | number('0,0.00')}} %</td>
            <td class="text-end pe-4">{{totalSuccessRateOnAmount | number('0,0.00')}} %</td>
            <td class="text-end pe-4 pt-2">{{totalMtdSuccess.count}}</td>
            <td class="text-end pe-4 pt-2">{{totalMtdSuccess.amount | number('0,0.00')}}</td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</div>
</template>

<script>
import HelpersMixin from '../utils/HelpersMixin.js'
import moment from 'moment'
export default {
  name: 'ProcessorTxnSummary',
  mixins: [HelpersMixin],
  data () {
    return {
      isLoading: false,
      year: '',
      month: '',
      day: '',
      psummary: {},
      inDate: ''
    }
  },
  computed: {
    totalMtdSuccess: function () {
      var count = 0
      var amount = 0
      if (this.psummary && this.psummary.procLoadMap) {
        for (var ps of Object.values(this.psummary.procLoadMap)) {
          count += ps.currentMtdSuccessCount
          amount += ps.currentMtdSuccessAmount
        }
      }
      return { count: count, amount: amount }
    },
    totalSuccessRateOnCount: function () {
      if (!this.psummary || this.psummary.processedCount === 0) {
        return 0
      }
      return this.psummary.successCount / this.psummary.processedCount * 100
    },
    totalSuccessRateOnAmount: function () {
      if (!this.psummary || this.psummary.processedAmount === 0) {
        return 0
      }
      return this.psummary.successAmount / this.psummary.processedAmount * 100
    }
  },
  mounted () {
    var d = new Date()
    this.extractYMDStr(d)
    this.getSummary()
    this.$watch('inDate', this.getSummary)
  },
  methods: {
    getSummary: function () {
      this.axios.get('/ax/procloadsummary/' + this.inDate.replace(/-/g, ''))
        .then(response => {
          this.isLoading = false
          this.psummary = response.data
        })
        .catch(error => {
          this.isLoading = false
          this.handleError(error)
        })
    },
    nextDay: function () {
      var d = new Date(this.inDate)
      var m = moment(d).utc()
      var next = m.add(1, 'days')
      this.extractYMDStr(next.toDate())
    },
    prevDay: function () {
      var d = new Date(this.inDate)
      var m = moment(d).utc()
      var prev = m.subtract(1, 'days')
      this.extractYMDStr(prev.toDate())
    },
    extractYMDStr: function (d) {
      this.year = d.getUTCFullYear().toString()
      var m = d.getUTCMonth() + 1
      if (m < 10) {
        this.month = '0' + m.toString()
      } else {
        this.month = m.toString()
      }
      var dt = d.getUTCDate()
      if (dt < 10) {
        this.day = '0' + dt.toString()
      } else {
        this.day = dt.toString()
      }
      this.inDate = this.year + '-' + this.month + '-' + this.day
    },
    calcSuccessRateOnCount: function (ps) {
      if (!ps || ps.processedCount === 0) {
        return 0
      }
      return (ps.accumCount / ps.processedCount * 100)
    },
    calcSuccessRateOnAmount: function (ps) {
      if (!ps || ps.processedAmount === 0) {
        return 0
      }
      return (ps.accumAmount / ps.processedAmount * 100)
    },
    sortByProcId: function (plMap) {
      if (!plMap) {
        return null
      }
      return Array.from(Object.values(plMap)).sort((a, b) => (a.procId.toLowerCase() > b.procId.toLowerCase()) ? 1 : -1)
    }
  }
}
</script>

<style scoped>
.successCol { background-color: #ccffcc }
.failedCol { background-color: #ffd6cc }
</style>
