<template>
<div class="container">
  <page-title title="Refunds, Chargebacks and Adjustments"/>
  <wait-loading :loading="isLoading"/>

  <div class="row small mb-2">
    <b-tabs v-model="tabIndex" class="col" content-class="bg-light border border-top-0 px-2" active-nav-item-class="bg-light text-primary fw-bold">
      <b-tab title="Date Range" @click="onTabSelect('dateRange', 0)" class="py-2 px-2">
        <div class="row smaall">
          <div class="col-auto">
            <input-select v-model="rangeField" input-class="fw-bold bg-warning">
              <option value="createDate">Create Date</option>
              <option value="statusDate">Status Date</option>
              <option value="processDate">Process Date</option>
            </input-select>
          </div>
          <div class="col-auto">
            <period-selector @periodUpdated="onPeriodUpdated" @dateRangeChanged="onDateRangeChanged" default-period="LAST3DAYS" />
          </div>
          <div class="col-auto ms-auto">
            <button class="btn btn-info btn-sm pb-2" @click="generateExportFile"><i class="fas fa-file-export" v-b-tooltip.hover title="Generate Export File"></i></button>
          </div>
        </div>
        <div class="row">
          <small class="col fst-italic fw-bold mt-2">Filters:</small>
        </div>
        <div class="row small bg-light">
          <input-select v-model="txnTypeFilter" group-class="col-auto" input-class="form-select-sm">
            <option value="ALL">Any Type</option>
            <option value="CHARGEBACK">CHARGEBACK</option>
            <option value="REFUND">REFUND</option>
            <option value="DR_ADJ">DR_ADJ</option>
            <option value="CR_ADJ">CR_ADJ</option>
          </input-select>
          <input-select v-model="merchantFilter" group-class="col-auto" input-class="form-select-sm">
            <option value="ALL">All Merchants</option>
            <option v-for="merchant in merchantSelectList" :key="merchant.merchantId" :value="merchant.merchantId">{{merchant.name}}</option>
          </input-select>
          <input-select v-model="accountFilter"  group-class="col-auto" input-class="form-select-sm">
            <option value="ALL">All Accounts</option>
            <option v-for="acct in selMerchant.accounts" :key="acct.accountId" :value="acct.accountId">{{acct.accountName}}</option>
          </input-select>
          <input-select v-model="procIdFilter" group-class="col-auto" input-class="form-select-sm">
            <option value="ALL">Any Processor</option>
            <option disabled>--------------</option>
            <option v-for="grp in procGroups" :key="grp" :value="'GRP-' + grp">Group - {{grp}}</option>
            <option disabled>--------------</option>
            <option v-for="proc in activeProcs" :key="proc.procId">{{proc.procId}}</option>
          </input-select>
        </div>
      </b-tab>
      
      <b-tab title="Memo ID" @click="onTabSelect('memoId', 1)" class="py-2 px-2">
        <div class="row input-group">
          <input-text v-model="searchInput" group-class="col-md-6" placeholder="Memo ID" @keyup.enter="search(0)"/>
          <button class="btn btn-primary btn-sm ms-2 input-group-append col-auto" @click="search(0)">Go</button>
        </div>       
      </b-tab>
      
      <b-tab title="Origin Txn ID(s)" @click="onTabSelect('refTxnId', 2)" class="py-2 px-2">
        <div class="row">
          <textarea rows="5" v-model="searchInput" class="form-control col-auto"></textarea>
        </div>
        <div class="row">
          <button class="btn btn-primary col-auto ms-auto mt-2" @click="search(0)">Go</button>
        </div>
      </b-tab>
      
      <b-tab title="Processor Ref(s)" @click="onTabSelect('procRef', 3)" class="py-2 px-2">
        <div class="row">
          <textarea rows="5" v-model="searchInput" class="form-control col-auto"></textarea>
        </div>
        <div class="row">
          <button class="btn btn-primary col-auto ms-auto mt-2" @click="search(0)">Go</button>
        </div>
      </b-tab>
      
    </b-tabs>
  </div>
    
  <div v-if="view === 'list'" class="row mt-3">
    <paginated-list :result="result" :search-params="searchParams" :search-fxn="search">
      <table class="table table-striped table-hover small">
        <thead class="table-dark">
          <tr>
            <th>CB/Refund ID</th>
            <th>
              <span v-if="rangeField === 'createDate'">Create Date</span>
              <span v-if="rangeField === 'processDate'">Process Date</span>
              <span v-if="rangeField === 'statusDate'">Status Date</span>
            </th>
            <th>Type</th>
            <th>Account</th>
            <th>Processor</th>
            <th>Origin Txn ID</th>
            <th>Origin Txn Date</th>
            <th>Amount</th>
            <th>Status</th>
          </tr>
        </thead>
        <tbody>
          <tr v-if="isLoading">
            <td colspan="9" class="text-center text-info fw-bold py-3">
              <b-spinner small variant='info' label='Please wait'></b-spinner>
              <span class="ms-2">  Please wait ... processing</span>
            </td>
          </tr>
          <tr v-if="!pendingSearch && (!result.totalRecs || result.totalRecs === 0)">
            <td colspan="9" class="text-center text-warning fw-bold py-3">No memo transactions found for specified criteria</td>
          </tr>
          <tr v-for="(txn, idx) in result.resultList" :key="txn.memoId" @click="showRefTxn(txn, idx)" style="cursor: pointer;">
            <td class="text-nowrap">{{txn.memoId}}</td>
            <td class="text-nowrap">
              <display-date :value="txn[rangeField]" timezone="UTC" hideyear/><br/>
              <small class="text-info"><display-date :value="txn[rangeField]" :timezone="auxTz" hideyear/></small>
            </td>
            <td>{{txn.txnType}}</td>
            <td>
              <p class="text-truncate pb-0 mb-0" style="max-width: 10em;">{{txn.merchantName}}</p>
              <p class="text-info py-0 my-0">{{txn.accountId}}</p>
            </td>
            <td class="text-nowrap">{{txn.procId}}</td>
            <td class="text-nowrap">{{txn.refTxnId}}</td>
            <td class="text-nowrap">
              <display-date :value="txn.refTxnDate" timezone="UTC" hideyear/><br/>
              <small class="text-info"><display-date :value="txn.refTxnDate" :timezone="auxTz" hideyear/></small>
            </td>
            <td class="text-end text-nowrap">{{txn.txnAmount.value | number('0,0.00')}} {{txn.currency}}</td>
            <td>{{txn.status}}</td>
          </tr>
        </tbody>
      </table>
    </paginated-list>
  </div>
  
  <b-modal id="ref-txn" size="lg" title="Memo Transaction" hide-footer header-bg-variant="info">
    <div class="row p-1 mx-1 border border-info small">
      <display-field label="Memo ID" group-class="col col-6">{{selMemo.memoId}}</display-field>
      <display-field label="Memo Type" group-class="col col-6">{{selMemo.txnType}}
        <button class="btn btn-outline-warning btn-sm small ms-3 py-0 mb-1" v-b-tooltip.hover title="Reclassify" @click="showReclassifyOptions"><i class="fas fa-edit"></i></button>
      </display-field>
      <display-field label="Created On" group-class="col col-6">
        <display-date :value="selMemo.createDate" timezone="UTC"/><br/>
        <small class="text-info"><display-date :value="selMemo.createDate" :timezone="auxTz"/></small>
      </display-field>
      <display-field label="Created By" group-class="col col-6">{{selMemo.createdBy}}</display-field>
      <display-field label="Status Date" group-class="col-6">
        <display-date :value="selMemo.statusDate" timezone="UTC"/><br/>
        <small class="text-info"><display-date :value="selMemo.statusDate" :timezone="auxTz"/></small>
      </display-field>
      <display-field label="Process Date" group-class="col-6">
        <display-date :value="selMemo.processDate" :timezone="selMemo.procTimezone"/>
        <button class="btn btn-outline-warning btn-sm small ms-3 py-0 mb-1" v-b-tooltip.hover title="Update" @click="showUpdateProcDateForm"><i class="fas fa-edit"></i></button>
        <br/>
        <small class="text-info"><display-date :value="selMemo.processDate" :timezone="auxTz"/></small>
      </display-field>
      <display-field label="Processor Reference" grroup-class="col-12">{{selMemo.procRef}}</display-field>
      <display-field label="Notes">
        <p v-for="(note, idx) in selMemo.notes" :key="idx">{{note}}</p>
      </display-field>
    </div>
    <div class="row p-1 mx-1 mt-1 border border-info">
      <div class="col-12 text-center text-secondary my-2 fw-bold">Origin Transaction Details</div>
      <div class="col-12">
        <txn-detail :txn-id="selMemo.refTxnId" @closed="$bvModal.hide('ref-txn')" ref="txnDetail"></txn-detail>
      </div>
    </div>
  </b-modal>
  
  <b-modal id="reclassify-txn" size="sm" title="Reclassify Transaction" hide-footer header-bg-variant="info">
    <div class="row">
      <input-select v-model="reclassType" label="Reclassify as" group-class="col mx-auto">
        <option value="CHARGEBACK" v-if="selMemo.txnType !== 'CHARGEBACK'">CHARGEBACK</option>
        <option value="REFUND" v-if="selMemo.txnType !== 'REFUND'">REFUND</option>
        <option value="REFUNDEDCB" v-if="selMemo.txnType !== 'REFUNDEDCB'">REFUNDEDCB</option>
      </input-select>
    </div>
    <div class="row">
      <div class="col-auto mx-auto">
        <button class="btn btn-primary mx-2" @click="confirmReclassify()">Save</button>
        <button class="btn btn-secondary mx-2" @click="$bvModal.hide('reclassify-txn')">Cancel</button>
      </div>
    </div>
  </b-modal>
  
  <b-modal id="update-procdate" size="sm" title="Update Process Date" hide-footer header-bg-variant="info">
    <div class="container">
      <div class="row">
        <div class="col-auto form-group">
          <label>Process Date</label>
          <input class="form-control" type="datetime-local" v-model="procDateStr"/>
        </div>
      </div>
      <div class="row">
        <div class="col-auto">
          <input-select v-model="procTimezone" group-class="col-auto" label="Timezone">
            <option v-for="tz in tzList" :key="tz.refCode" :value="tz.refCode">{{tz.refValue}}</option>
          </input-select>
        </div>
      </div>
      <div class="row mt-3">
        <div class="col-auto ms-auto">
          <button class="btn btn-secondary me-2" @click.prevent="$bvModal.hide('update-procdate')">Cancel</button>
          <button class="btn btn-primary" @click.prevent="onSaveProcDate()">Save</button>
        </div>
      </div>
    </div>
  </b-modal>
  
  <file-exporter ref="memolistexport"
    apiGenerate="/ax/memolistexport/generate"
    :paramsForGenerate="searchParams"
    apiRetrieve="/ax/memolistexport/link/">
  </file-exporter>
  
</div>
</template>

<script>
import TxnDetail from '../views/TxnDetail.vue'
import moment from 'moment'
import HelpersMixin from '../utils/HelpersMixin.js'
import ProcListMixin from '../utils/ProcListMixin.js'
import PeriodSelector from '../components/PeriodSelector.vue'
import PaginatedList from '../components/PaginatedList.vue'
import FileExporter from '../components/FileExporter.vue'
export default {
  name: 'MemoTxnList',
  mixins: [HelpersMixin, ProcListMixin],
  components: {
    TxnDetail, PeriodSelector, PaginatedList, FileExporter
  },
  data () {
    return {
      view: 'list',
      searchParams: { offset: 0, size: 50 },
      searchType: 'dateRange',
      searchInput: '',
      result: {},
      dateStart: '',
      dateEnd: '',
      timezone: 'UTC',
      auxTz: this.$localtz,
      selMemo: {},
      selIdx: 0,
      txnTypeFilter: 'ALL',
      procIdFilter: 'ALL',
      merchantFilter: 'ALL',
      accountFilter: 'ALL',
      merchantSelectList: [],
      selMerchant: {},
      reclassType: '',
      tabIndex: 0,
      pendingSearch: false,
      procDateStr: null,
      procTimezone: 'UTC',
      rangeField: 'statusDate'
    }
  },
  mounted () {
    this.getProcList()
    this.getMerchantList()
    this.getTimezones()
    this.searchParams = { offset: 0, size: 50 }

    var mnow = moment().utc()
    var weekAgo = moment(mnow).subtract(3, 'days')
    this.dateStart = weekAgo.format('YYYY-MM-DD')
    this.dateEnd = mnow.format('YYYY-MM-DD')
    // note: the onPeriodUpdated function will be triggerred by the PeriodSelector component when it is mounted
    //       which in turn trigger the initial call to search
  },
  watch: {
    searchType: function (newValue, oldValue) {
      this.result = {}
      if (newValue === 'dateRange') {
        this.search()
      } else {
        this.searchInput = ''
        this.searchFirstName = ''
        this.searchLastName = ''
      }
    },
    txnTypeFilter: function () {
      this.search(0)
    },
    procIdFilter: function () {
      this.search(0)
    },
    merchantFilter: function (newValue) {
      if (newValue !== 'ALL') {
        this.selMerchant = this.merchantSelectList.find(me => me.merchantId === newValue);
      } else {
        this.selMerchant = {}
      }
        this.accountFilter = 'ALL'

      this.search(0)
    },
    accountFilter: function() {
      this.search(0)
    },
    rangeField: function () {
      this.search(0)
    }
  },
  methods: {
    search: function (offset) {
      if (offset !== null) {
        this.searchParams.offset = offset
      }
      if (this.searchType === 'dateRange') {
        this.searchParams.rangeField = this.rangeField
        this.searchParams.sortField = this.rangeField
        this.searchParams.dateStart = this.dateStart
        this.searchParams.dateEnd = this.dateEnd
        this.searchParams.timezone = this.timezone
        this.searchParams.criteria = []
        this.searchParams.subCriterion = {}
        if (this.txnTypeFilter && this.txnTypeFilter !== 'ALL') {
          this.searchParams.criteria.push({ fieldName: 'txnType', value: this.txnTypeFilter, matchType: 'TERM' })
        }
        if (this.procIdFilter && this.procIdFilter !== 'ALL') {
          if (this.procIdFilter.startsWith('GRP-')) {
            const groupCode = this.procIdFilter.substring(4)
            var subCriteria = []
            for (const proc of this.getGroupProcs(groupCode)) {
              subCriteria.push({ fieldName: 'procId.keyword', value: proc, matchType: 'SHOULD' })
            }
            this.searchParams.subCriterion.matchType = 'MATCH'
            this.searchParams.subCriterion.criteria = subCriteria
          } else {
            this.searchParams.criteria.push({ fieldName: 'procId', value: this.procIdFilter, matchType: 'TERM' })
          }
        }
        if (this.merchantFilter && this.merchantFilter !== 'ALL') {
          this.searchParams.criteria.push({ fieldName: 'merchantId', value: this.merchantFilter, matchType: 'TERM' })
        }
        if (this.accountFilter && this.accountFilter !== 'ALL') {
            this.searchParams.criteria.push({ fieldName: 'accountId', value: this.accountFilter, matchType: 'TERM' })
          }
      } else {
        this.searchParams.dateStart = null
        this.searchParams.dateEnd = null
        this.searchParams.criteria = []
        if (this.searchType === 'refTxnId') {
          this.searchParams.criteria.push({ fieldName: 'refTxnId.keyword', value: this.searchInput, matchType: 'INLIST' })
          this.searchParams.sortField = 'refTxnId.keyword'
          this.searchParams.sort = 'ASC'
        } else if (this.searchType === 'procRef') {
            this.searchParams.criteria.push({ fieldName: 'procRef.keyword', value: this.searchInput, matchType: 'INLIST' })
            this.searchParams.sortField = 'procRef.keyword'
            this.searchParams.sort = 'ASC'
        } else if (this.searchType === 'memoId') {
          this.searchParams.criteria.push({ fieldName: 'memoId', value: this.searchInput, matchType: 'TERM' })
        }
      }

      this.isLoading = true
      this.pendingSearch = false
      this.result = {}
      this.axios.post('/ax/memotxns', this.searchParams)
        .then(response => {
          this.isLoading = false
          this.result = response.data
        })
        .catch(error => this.handleError(error) )
    },
    showDetails: function (memoId) {
      this.currentMemoId = memoId
      this.view = 'detail'
    },
    refreshList: function () {
      this.search()
    },
    showRefTxn: function (memo, idx) {
      this.selMemo = memo
      this.selIdx = idx
      this.$bvModal.show('ref-txn')
    },
    showReclassifyOptions: function () {
      this.reclassType = ''
      this.$bvModal.show('reclassify-txn')
    },
    showUpdateProcDateForm: function () {
      if (this.selMemo.processDate) {
        const procDate = moment(this.selMemo.processDate)
        const procTimezone = this.selMemo.procTimezone ? this.selMemo.procTimezone : 'UTC'
        this.procDateStr = procDate.tz(procTimezone).format('YYYY-MM-DDTHH:mm')
      } else {
        this.procDateStr = null
      }
      this.procTimezone = this.selMemo.procTimezone
      this.$bvModal.show('update-procdate')
    },
    confirmReclassify: function () {
      this.$bvModal.msgBoxConfirm('Are you sure you want to change this memo from ' + this.selMemo.txnType + ' to ' + this.reclassType + '?', {
        title: 'Please Confirm',
        okTitle: 'Proceed',
        cancelTitle: 'Abort',
        bodyClass: 'fw-bold text-danger text-center',
        centered: true
      })
      .then(value => {
        if (value === true) {
          this.processReclassify()
        }
      })
      .catch(error => this.handleError(error) )
    },
    processReclassify: function () {
      this.axios.post('/ax/memotxn/reclassify', {memoId: this.selMemo.memoId, memoType: this.reclassType})
      .then(response => {
        this.$bvModal.hide('reclassify-txn')
        this.selMemo = response.data
        this.$refs.txnDetail.getTxnDetail()
        this.result.resultList[this.selIdx] = this.selMemo
      })
      .catch(error => this.handleError(error) )
    },
    onSaveProcDate: function () {
      this.axios.post('/ax/memotxn/updateprocdate', {memoId: this.selMemo.memoId, procDateStr: this.procDateStr, procTimezone: this.procTimezone})
      .then(response => {
        this.$bvModal.hide('update-procdate')
        this.selMemo = response.data
        this.$refs.txnDetail.getTxnDetail()
        this.result.resultList[this.selIdx] = this.selMemo
      })
      .catch(error => this.handleError(error) )
    },
    getMerchantList: function () {
      this.axios.get('/ax/merchants')
      .then(response => {
        this.merchantSelectList = response.data
        this.merchantSelectList.sort((m1, m2) => (m1.name > m2.name) ? 1 : -1 )
        this.selMerchant = {}
      })
      .catch(error => this.handleError(error))
    },
    onTabSelect: function (selected, idx) {
      this.tabIndex = idx
      this.searchType = selected
      this.searchInput = ''
      this.result = {}
      this.searchParams = { offset: 0, size: 50 }
    },
    onPeriodUpdated: function (eventData) {
      this.pendingSearch = true
      this.dateStart = eventData.startDate
      this.dateEnd = eventData.endDate
      this.timezone = eventData.timezone
      this.auxTz = eventData.auxTz
      this.search(0)
    },
    onDateRangeChanged: function () {
      this.pendingSearch = true
    },
    generateExportFile: function () {
      this.$refs.memolistexport.generate()
    }
  }
}
</script>

<style scoped>
label { font-size: 75%; margin-bottom: 1px; font-weight: 600; }
</style>
