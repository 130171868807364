<template>
<div>
  <div class="row" v-if="showUpdateMenu">
    <div class="col-12 text-end">
      <b-dropdown id="otherDropdown" variant="outine">
        <template slot="button-content">
          <i class="fas fa-tools text-primary"></i>
        </template>
        <template v-if="txn.status === 'SUCCESS'">
        <b-dropdown-item @click="doMemoTxn('CHARGEBACK')" class="ddi-small" variant="primary">CHARGEBACK</b-dropdown-item>
        <b-dropdown-item @click="doMemoTxn('REFUND')" class="ddi-small" variant="primary">REFUND</b-dropdown-item>
        <b-dropdown-item @click="doMemoTxn('REFUNDEDCB')" class="ddi-small" variant="primary">REFUNDED CB</b-dropdown-item>
        <b-dropdown-item @click="doUpdateTxn('TO_FAILED')" class="ddi-small" variant="primary">CHANGE TO FAILED</b-dropdown-item>
        </template>
        <template v-if="txn.status === 'PENDING'">
        <b-dropdown-item @click="doUpdateTxn('TO_SUCCESS')" class="ddi-small" variant="primary">CHANGE TO SUCCESS</b-dropdown-item>
        <b-dropdown-item @click="doUpdateTxn('TO_FAILED')" class="ddi-small" variant="primary">CHANGE TO FAILED</b-dropdown-item>
        </template>
        <template v-else>
        <b-dropdown-item @click="doUpdateTxn('TO_SUCCESS')" class="ddi-small" variant="primary">CHANGE TO SUCCESS</b-dropdown-item>
        <b-dropdown-item @click="doUpdateTxn('CHG_RESULT')" class="ddi-small" variant="primary">UPDATE FAILED RESULT</b-dropdown-item>
        </template>
        <b-dropdown-item @click="onCheckProcResult" class="ddi-small" variant="primary">CHECK PROCESSOR RESULT</b-dropdown-item>
      </b-dropdown>
    </div>
  </div>
  <div class="row">
    <display-field label="Transaction ID" group-class="col col-6">{{txnId}}</display-field>
    <display-field label="Status" group-class="col col-6 fw-bold">
      <span :class="txn.status === 'SUCCESS' ? 'text-success' : 'text-danger'">{{txn.status}}</span>
      <span v-if="txn.tags && txn.tags.length > 0" class="text-danger ms-3">{{txn.tags}}</span>
    </display-field>
    <display-field label="Processor ID" group-class="col col-6">{{txn.procId}}</display-field>
    <display-field label="Processing Result" group-class="col col-6 fw-bold">{{txn.procStatusCode}} - {{txn.procStatusText}}</display-field>
    <display-field label="Processor Reference" group-class="col col-6">{{txn.procRef}}</display-field>
    <display-field label="Descriptor" group-class="col col-6">
      {{txn.procDescriptor}}
      <a href="#" @click.prevent="showDescriptorUpdateForm"><i class="fas fa-edit text-warning ms-2"></i></a>
    </display-field>
    <display-field label="Transaction Date" group-class="col col-6">
      <display-date :value="txn.createDate" timezone="UTC"/><br/>
      <small class="text-info"><display-date :value="txn.createDate" :timezone="$localtz"/></small>
    </display-field>
    <display-field label="Status Date" group-class="col col-6">
      <display-date :value="txn.statusDate" timezone="UTC"/><br/>
      <small class="text-info"><display-date :value="txn.statusDate" :timezone="$localtz"/></small>
    </display-field>
    <display-field group-class="col col-6"></display-field>
    <display-field label="Transaction Amount" group-class="col col-6 fw-bold">
      {{txn.txnAmount.value | number('0,0.00')}} {{txn.txnAmount.currency}}
    </display-field>
    <display-field label="Merchant ID" group-class="col col-6">{{txn.merchantId}}</display-field>
    <display-field label="Account ID" group-class="col col-6">{{txn.accountId}}</display-field>
    <display-field label="Merchant Name" group-class="col col-6">{{txn.merchantName}}</display-field>
    <display-field label="Payment Reference ID" group-class="col col-6">{{txn.paymentRef}}</display-field>
    <display-field label="Card Number" group-class="col col-6">{{txn.maskedCardNo}}</display-field>
    <display-field label="Card Expiry" group-class="col col-6">{{txn.cardExpiryMonth}} / {{txn.cardExpiryYear}}</display-field>
    <display-field label="Customer Name">{{txn.customerFirstName}} {{txn.customerLastName}}</display-field>
    <display-field label="Customer Email" group-class="col col-6">{{txn.customerEmail}}</display-field>
    <display-field label="Customer Phone" group-class="col col-6">{{txn.phoneNumber}}</display-field>
    <display-field label="Customer Address">
      {{txn.customerAddress.addressLine1}}<br/> 
      {{txn.customerAddress.cityTown}} {{txn.customerAddress.stateProv}}<br/>
      {{txn.customerAddress.postalCode}} {{txn.customerAddress.country}}
    </display-field>
    <display-field label="Response Time">{{txn.procResponseEnd - txn.procRequestStart}} ms</display-field>
    <display-field label="Processor Response">{{txn.rawProcResult}}</display-field>
  </div>

  <b-modal id="memo-txn" size="lg" :title="memoType" hide-footer header-bg-variant="warning">
    <memo-txn-form :txn="txn" :memo-type="memoType"
      @saved="onMemoTxnSaved"
      @cancelled="$bvModal.hide('memo-txn')">
    </memo-txn-form>
  </b-modal>
  
  <b-modal id="txn-update" size="lg" title="Update Status/Result" hide-footer header-bg-variant="warning">
    <txn-update-form :txn="txn" :updateType="updateType"
      @saved="onUpdateTxnSaved"
      @cancelled="$bvModal.hide('txn-update')">
    </txn-update-form>
  </b-modal>
  
  <b-modal id="descriptor-update" title="Update Descriptor" hide-footer header-bg-variant="warning">
    <div class="row">
      <input-text v-model="descriptor" group-class="col" placeholder="Enter the updated descriptor"/>
      
      <button class="btn btn-primary col-auto mb-2 me-2" @click="onSaveDescriptor">Save</button>
    </div>
  </b-modal>
  
  <b-modal id="proc-result" size="md" title="Processor Result Query" hide-footer>
    <proc-result-query :txn="txn"></proc-result-query>
  </b-modal>
</div>
</template>

<script>
import MemoTxnForm from '../components/MemoTxnForm.vue'
import TxnUpdateForm from '../components/TxnUpdateForm.vue'
import ProcResultQuery from '../components/ProcResultQuery.vue'
export default {
  name: 'TxnDetail',
  props: {
    txnId: [String, Number], 
    showUpdateMenu: { type: Boolean, default: false }
  },
  components: {
    MemoTxnForm, TxnUpdateForm, ProcResultQuery
  },
  data () {
    return {
      txn: { txnAmount: { value: 0, currency: 'USD' }, customerAddress: {}, createDate: {} },
      memoType: '',
      updateType: '',
      descriptor: '',
      updateMenuEnabled: true,
      dtlChanged: false
    }
  },
  beforeDestroy: function () {
    if (this.dtlChanged) {
      this.$emit('dtlChanged')
    }
  },
  mounted () {
    this.getTxnDetail()
  },
  methods: {
    getTxnDetail: function () {
      this.axios.get('/ax/paytxns/' + this.txnId)
        .then(response => {
          this.txn = response.data
        })
        .catch(error => {
          console.log(error.response.status)
        })
    },
    closeDetail: function () {
      this.$emit('closed')
    },
    doMemoTxn: function (memoType) {
      this.memoType = memoType
      this.$bvModal.show('memo-txn')
    },
    onMemoTxnSaved: function () {
      this.dtlChanged = true
      this.$bvModal.hide('memo-txn')
      this.getTxnDetail()
      this.$toasted.success(this.memoType + ' recorded successfully')
    },
    doUpdateTxn: function (updateType) {
      this.updateType = updateType
      this.$bvModal.show('txn-update')
    },
    onUpdateTxnSaved: function () {
      this.dtlChanged = true
      this.$bvModal.hide('txn-update')
      this.getTxnDetail()
      this.$toasted.success('Transaction updated successfully')
    },
    onCheckProcResult: function () {
      this.$bvModal.show('proc-result')
    },
    showDescriptorUpdateForm: function () {
      this.descriptor = ''
      this.$bvModal.show('descriptor-update')
    },
    onSaveDescriptor: function () {
      this.axios.post('/ax/paytxn/updatedescriptor', {txnId: this.txnId, descriptor: this.descriptor})
      .then(response => {
        this.txn = response.data
        this.$bvModal.hide('descriptor-update')
        this.$toasted.success('Descriptor updated successfully')
      })
      .catch(error => this.handelError(error))
    },
    isShowTools: function () {
      if (!this.txn || this.txn.status !== 'SUCCESS') {
        return false
      }
      if (this.txn.tags) {
        if (this.txn.tags.includes('REFUND_REQUESTED')) {
          return true
        } else {
          return false
        }
      }
      return true
    }
  }
}
</script>

<style scoped>
dt { font-size: .8em; padding-top: .2em; font-weight: 600; min-height: 1.8em; }
dd { border-bottom: 1px inset; min-height: 1.8em; }
.ddi-small { font-size: x-small;}
</style>
