<template>
<div class="row">
  <div class="col-12 small text-info">
    <span v-if="mxu.lastLogin">
    Last logged-in on <display-date :value="mxu.lastLogin"/>
    </span>
    <span v-if="mxu.locked" class="text-danger font-weight-bold ms-3">LOCKED-OUT</span>
  </div>
  
  <div class="col-12 text-end" v-if="mode === 'update' && formDisabled">
    <a href="#" @click="onPwReset" v-b-tooltip.hover title="Reset Password" class="me-3"><i class="fas fa-key"></i></a>
    <a href="#" @click="onEdit" v-b-tooltip.hover title="Edit" class="me-3"><i class="fas fa-edit"></i></a>
    <a href="#" @click="onDelete" v-b-tooltip.hover title="Delete"><i class="fas fa-trash"></i></a>
  </div>
  
  <div class="col-12">
    <form>
      <fieldset :disabled="formDisabled">
      <input-text v-if="mode === 'add'" label="User ID" v-model="mxu.userId" placeholder="enter login ID"
        :errors="errors" error-key="userId"/>
      <display-field v-else label="User ID" group-class="mt-2 mb-3 bg-light">{{mxu.userId}}</display-field>
      <input-text label="First Name" v-model="mxu.firstName" placeholder="enter first name"
        :errors="errors" error-key="firstName"/>
      <input-text label="Last Name" v-model="mxu.lastName" placeholder="enter last name"
        :errors="errors" error-key="lastName"/>
      <input-text label="Email Address" v-model="mxu.emailAddress" placeholder="enter email address"
        :errors="errors" error-key="emailAddress"/>
      </fieldset>
    </form>
    <div class="text-end mt-5 mb-3" v-if="!formDisabled">
      <button class="btn btn-secondary me-2" @click="onCancel()">Cancel</button>
      <button class="btn btn-primary" @click="onSave()">Save</button>
    </div>
  </div>
</div>
</template>

<script>
import HelpersMixin from '../utils/HelpersMixin.js'
import { ErrorsContainer } from '../utils/ErrorsContainer.js'
export default {
  name: 'MerchantUserForm',
  mixins: [HelpersMixin],
  props: {
    merchantId: String,
    merchantUser: Object
  },
  data () {
    return {
      mxu: { merchantId: this.merchantId, userId: '' },
      errors: new ErrorsContainer(),
      mode: 'add',
      formDisabled: true
    }
  },
  mounted () {
    if (this.merchantUser && this.merchantUser.userId) {
      this.mode = 'update'
      Object.assign(this.mxu, this.merchantUser)
    } else {
      this.mode = 'add'
      this.formDisabled = false
    }
  },
  methods: {
    onSave: function () {
      this.$forceUpdate()
      this.errors.clearAll()
      this.axios.post('/ax/mxuser/' + this.mode, this.mxu)
        .then(() => {
          this.$emit('saved')
          if (this.mode === 'add') {
            this.$toasted.success('User ' + this.mxu.userId + ' created. Initial password has been generated and sent to ' + this.mxu.emailAddress)
          }
        })
        .catch(error => {
          if (error.response.status === 422) {
            this.errors.setFldErrors(error.response.data.fieldErrors)
          }
        })
    },
    onCancel: function () {
      this.$emit('cancelled')
    },
    onEdit: function () {
        this.formDisabled = false
        this.showNewPass = false
        this.newPass = ''
    },
    onDelete: function () {
        this.$bvModal.msgBoxConfirm("Are you sure you want to DELETE this user?", {
            title: 'Please Confirm',
            bodyTextVariant: 'danger',
            size: 'sm',
            buttonSize: 'sm',
            centered: true
          })
          .then(value => {
            console.log('confirm value: ' + value)
            if (value) {
              this.submitDelete()
            }
          })
    },
    submitDelete: function () {
      this.axios.post('/ax/mxuser/remove', {merchantId: this.merchantId, userId: this.mxu.userId})
      .then(() => {
        this.$toasted.success('User ' + this.mxu.userId + ' deleted.')
        this.$emit('saved')
      })
      .catch(error => {
        this.handleError(error)
        this.formDisabled = false
      })
    },
    onPwReset: function () {
      this.$bvModal.msgBoxConfirm("Are you sure you want to RESET this user's password?", {
        title: 'Please Confirm',
        bodyTextVariant: 'danger',
        size: 'sm',
        buttonSize: 'sm',
        centered: true
      })
      .then(value => {
        console.log('confirm value: ' + value)
        if (value) {
          this.submitPwReset()
        }
      })
    },
    submitPwReset: function () {
      console.log('submitting pw reset')
      this.axios.post('/ax/mxuser/pwreset', {merchantId: this.merchantId, userId: this.mxu.userId})
      .then(() => {
        this.$toasted.success('A new password has been generated and sent to ' + this.mxu.emailAddress)
        this.$emit('saved')
      })
      .catch(error => {
        this.handleError(error)
        this.formDisabled = false
      })
    }
  }
}
</script>
