<template>
<div class="container">
  <div class="row">
    <input-select label="Company" v-model="mx.company" placeholder="select company" group-class="col-auto">
      <option>payhumax</option>
      <option>zeni</option>
    </input-select>
    <div v-if="mode === 'update'" class="col-auto ms-auto pt-3">
      <span class="fw-bold me-2">Merchant ID:</span>
      <span class="fw-bold text-info">{{merchantId}}</span>
    </div>
  </div>
  <div class="row">
    <input-text label="Merchant Name" v-model="mx.name" placeholder="enter merchant's full name"
      :errors="errors" error-key='name' group-class="col-6"/>
    <input-text label="Alias" v-model="mx.merchantAlias" placeholder="enter short name"
      :errors="errors" error-key="merchantAlias" group-class="col-6"/>
  </div>
  <div class="row">
    <input-text label="Web Site" v-model="mx.webSite" group-class="col"/>
  </div>
  <div class="row mt-3">
    <div  class="col-6 border-end">
      <input-text label="Address" v-model="mx.address.addressLine1" placeholder="address line 1"/>
      <input-text v-model="mx.address.addressLine2" placeholder="address line 2"/>
      <input-text v-model="mx.address.cityTown" placeholder="city/town"/>
      <input-text v-model="mx.address.stateProv" placeholder="state/province"/>
      <input-select v-model="mx.address.country" placeholder="select country">
        <option v-for="c in countries" :key="c.code" :value="c.code">{{c.name}}</option>
      </input-select>
      <input-text v-model="mx.address.postalCode" placeholder="postal code"/>
    </div>
    <div class="col-6">
      <input-text label="Contact Person Name" v-model="mx.contactName"
        :errors="errors" error-key='contactName'/>
      <input-text label="Contact Email" v-model="mx.contactEmail"
        :errors="errors" error-key='contactEmail'/>
      <input-text label="Contact Phone" v-model="mx.contactPhone"
        :errors="errors" error-key='contactPhone'/>
    </div>
  </div>
  <div class="row mt-2">
    <input-text label="Default Result URL" v-model="mx.defaultResultUrl" placeholder="enter default result URL" group-class="col"/>
  </div>
  <div class="row">
    <input-text label="Default Callback URL" v-model="mx.defaultCallbackUrl" placeholder="enter default callback URL" group-class="col"/>
  </div>
  <div class="row mt-2">
    <div class="col-6">
      <input-switch label="Merchant Notification" v-model="mx.notifyMerchant" showstate/>
      <input-text label="Notification Email Recipient" v-model="mx.notificationEmail" v-if="mx.notifyMerchant"/>
      <input-text label="Notification From Email" v-model="mx.notificationFromEmail" v-if="mx.notifyMerchant"/>
      <input-text label="Notification From Name" v-model="mx.notificationFromName" v-if="mx.notifyMerchant"/>
    </div>
    <div class="col-3 ms-auto">
      <input-switch label="Weekly Reports" v-model="mx.weeklyReports" showstate/>
      <input-switch label="Check Order Fail Velocity" v-model="mx.checkOrderFailVelocity"/>
    </div>
  </div>
  <div class="row my-4 pt-3 border-top">
    <div class="col-auto ms-auto">
      <button class="btn btn-secondary me-2" @click="onCancel()">Cancel</button>
      <button class="btn btn-primary" @click="onSave()">Save</button>
    </div>
  </div>
</div>
</template>

<script>
import { ErrorsContainer } from '../utils/ErrorsContainer.js'
import { countriesList } from '../utils/Countries.js'
export default {
  name: 'MerchantForm',
  props: ['merchantId'],
  data () {
    return {
      mx: { address: {}, notifyMerchant: false, weeklyReports: false },
      errors: new ErrorsContainer(),
      countries: countriesList,
      mode: 'add'
    }
  },
  mounted () {
    if (this.merchantId) {
      this.mode = 'update'
      this.getMerchant()
    }
  },
  methods: {
    getMerchant: function () {
      this.axios.get('/ax/merchant/' + this.merchantId)
        .then(response => {
          this.mx = response.data
          if (!this.mx.address) {
            this.mx.address = {}
          }
        })
        .catch(error => {
          console.log(error.response.status)
        })
    },
    onSave: function () {
      this.errors.clearAll()
      this.axios.post('/ax/merchant/' + this.mode, this.mx)
        .then(response => {
          console.log(response.data)
          if (this.mode === 'add') {
            this.$router.push('/merchant/' + response.data.merchantId)
          } else {
            this.$router.push('/merchant/' + this.merchantId)
          }
        })
        .catch(error => {
          if (error.response.status === 422) {
            this.errors.setFldErrors(error.response.data.fieldErrors)
          }
        })
    },
    onCancel: function () {
      this.$router.push('/merchants')
    }
  }
}
</script>
