<template>
<div class="row justify-content-center mt-5">
  <div class="col-md-5">
    <div class="alert alert-danger" v-if="error.hasError">{{error.errorMsg}}</div>
    <div class="card">
      <div class="card-body">
        <form @submit.prevent="onLogin" action="">
          <div class="form-group mb-3">
            <label>User ID</label>
            <input type="text"
              class="form-control" name="loginId" v-model="login.loginId" />
          </div>
          <div class="form-group mb-3">
            <label>Password</label>
            <input type="password"
              class="form-control" name="loginPass" v-model="login.loginPass" />
          </div>
          <div class="text-end">
            <button type="submit" class="btn btn-primary">Login</button>
          </div>
        </form>
      </div>
    </div>
  </div>
</div>
</template>

<script>
export default {
  name: 'AdminLogin',
  data () {
    return {
      login: { loginId: '', loginPass: '' },
      error: { hasError: false, errorMsg: '' }
    }
  },
  methods: {
    onLogin: function () {
      this.error.hasError = false
      this.error.errorMsg = ''
      var formData = new FormData()
      formData.append('loginId', this.login.loginId)
      formData.append('loginPass', this.login.loginPass)
      this.axios.post('/ax/login', formData, { headers: { 'Content-Type': 'application/x-www-form-urlencoded' } })
        .then(response => {
          this.$store.commit('saveToken', response.data)
          this.$store.commit('clearSearchParams')
          this.$router.push('/summaries')
        })
        .catch(error => {
          console.log(error.response.status + ': ' + error.response.data)
          this.error.hasError = true
          this.error.errorMsg = 'Invalid Login Credentials'
        })
    }
  }
}
</script>

<style scoped>
label {
  margin-bottom: .1em;
  font-size: .75em;
  font-weight: bold;
}
</style>
