<template>
<div class="container">
  <wait-loading :loading="isLoading"/>
  
  <div class="row mt-3">
    <a class="col-auto ms-auto" href="#" @click.prevent="prevMonth()"><i class="fas fa-angle-left" v-b-tooltip.hover title="Previous Month"></i></a>
    <input-select v-model="year" group-class="col-auto">
      <option v-for="(yearOpt, index) in yearOpts" :key="'yropt-' + index" :value="yearOpt">{{yearOpt}}</option>
    </input-select>
    <input-select v-model="month" group-class="col-auto">
      <option value="1">January</option>
      <option value="2">February</option>
      <option value="3">March</option>
      <option value="4">April</option>
      <option value="5">May</option>
      <option value="6">June</option>
      <option value="7">July</option>
      <option value="8">August</option>
      <option value="9">September</option>
      <option value="10">October</option>
      <option value="11">November</option>
      <option value="12">December</option>
    </input-select>
    <a class="col-auto" href="#" @click.prevent="nextMonth()"><i class="fas fa-angle-right" v-b-tooltip.hover title="Next Month"></i></a>
    <a class="col-auto me-auto" href="#" @click.prevent="getSummaries()" v-b-tooltip.hover title="Refresh"><i class="fas fa-sync"></i></a>
  </div>
  
  <div class="row mt-4">
    <div class="col-12 table-responsive-md">
      <table class="table table-bordered table-sm small">
        <thead class="table-dark">
          <tr>
            <th rowspan="2" class="align-bottom text-center">Merchant / Account</th>
            <th colspan="2" class="text-center">MTD Success</th>
            <th colspan="2" class="text-center">MTD Chargebacks</th>
            <th colspan="2" class="text-center">Chargeback Rate</th>
          </tr>
          <tr class="small">
            <th class="text-info text-center bg-secondary" width="10%">Count</th>
            <th class="text-info text-center bg-secondary" width="15%">Amount</th>
            <th class="text-info text-center bg-secondary" width="10%">Count</th>
            <th class="text-info text-center bg-secondary" width="15%">Amount</th>
            <th class="text-info text-center bg-secondary" width="10%">(on count)</th>
            <th class="text-info text-center bg-secondary" width="10%">(on amount)</th>
          </tr>
        </thead>
        <tbody>
          <tr v-if="!isLoading && cbSummList && cbSummList.length === 0">
            <td colspan="9" class="text-center text-warning fw-bold py-3">No data found for specified month</td>
          </tr>
          <template v-for="(cbsumm, idx) in cbSummList">
          <tr v-if="idx === 0 || cbsumm.merchantId !== cbSummList[idx - 1].merchantId" :key="'summ-' + idx">
            <td colspan="7" class="fst-italic fw-bold small text-primary">{{cbsumm.merchantName}} ({{cbsumm.merchantId}})</td>
          </tr>
          <tr :key="'summ2-' + idx">
            <td class="ps-4">{{cbsumm.accountId}} - {{cbsumm.accountName}}</td>
            <td class="text-center">{{cbsumm.successCount}}</td>
            <td class="text-end pe-3">{{cbsumm.successAmount | number('0,0.00')}}</td>
            <td class="text-center">{{cbsumm.cbCount}}</td>
            <td class="text-end pe-3">{{cbsumm.cbAmount | number('0,0.00')}}</td>
            <td class="text-center" :class="{'fw-bold bg-danger text-white':cbsumm.cbCountOverThreshold}">{{cbsumm.cbRateOnCount | number('0,0.00')}} %</td>
            <td class="text-center" :class="{'fw-bold bg-danger text-white':cbsumm.cbAmountOverThreshold}">{{cbsumm.cbRateOnAmount | number('0,0.00')}} %</td>
          </tr>
          </template>
        </tbody>
      </table>
    </div>
  </div>
  
</div>
</template>

<script>
import HelpersMixin from '../utils/HelpersMixin.js'
import moment from 'moment'
export default {
  name: 'ChargebackSummary',
  mixins: [HelpersMixin],
  data () {
    return {
      cbSummList: [],
      yearOpts: [],
      year: '2023',
      month: '0'
    }
  },
  mounted () {
    var d = new Date()
    var y = d.getUTCFullYear()
    var m = d.getUTCMonth() + 1

    for (let i = 0; i < 5; i++) {
      var x = y - i
      this.yearOpts[i] = x.toString()
    }
 
    this.year = y.toString()
    this.month = m.toString()
    
    this.getSummaries()
    this.$watch('year', this.getSummaries)
    this.$watch('month', this.getSummaries)
  },
  methods: {
    getSummaries: function () {
      this.axios.get('/ax/chargebacksummary/' + this.year + '/' + this.month)
      .then(response => {
        this.isLoading = false
        this.cbSummList = response.data
      })
      .catch(error => this.handleError(error))
    },
    prevMonth: function () {
      var y = Number(this.year)
      var m = Number(this.month) - 1
      if (m === 0) {
        m = 12
        y = y - 1
      }
      this.year = y.toString()
      this.month = m.toString()
    },
    nextMonth: function () {
      var y = Number(this.year)
      var m = Number(this.month) + 1
      if (m > 12) {
        m = 1
        y = y + 1
      }
      this.year = y.toString()
      this.month = m.toString()
    }
  }
}
</script>