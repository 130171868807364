<template>
<div class="container">
  <div class="row border border-info small pt-2">
    <div class="row">
      <display-field label="Request Date" group-class="col-4"><display-date :value="rr.requestDate" timezone="UTC"/></display-field>
      <display-field label="Requested By" group-class="col-4">{{rr.requestedBy}}</display-field>
      <display-field label="Status" group-class="col-4">{{rr.status}}
        <span v-if="rr && rr.status === 'PENDING'"><a href="#" @click.prevent="confirmCancelRequest" v-b-tooltip.hover title="Cancel Request"><i class="fa-regular fa-trash-can text-danger ps-3"></i></a></span>
      </display-field>
    </div>
    <div class="row">
      <display-field label="Reason" group-class="col-12">{{rr.reason}}</display-field>
    </div>
    <div class="row" v-if="rr.notes">
      <display-field label="Notes" group-class="col-12">
        <ul>
          <li v-for="(rrnote, idx) in rr.notes" :key="idx">{{rrnote}}</li>
        </ul>
      </display-field>
    </div>
  </div>
  
  <div class="row border border-info mt-2" v-if="rr && rr.refTxnId">
    <div class="col-12 text-center text-secondary my-2 fw-bold">Origin Transaction Details</div>
    <div class="'col-12">
      <txn-detail :txn-id="rr.refTxnId" :show-update-menu="txnActionsAllowed" ref="txnDetail" @dtlChanged="txnUpdated"></txn-detail>
    </div>
  </div>
</div>
</template>

<script>
import HelpersMixin from '../utils/HelpersMixin.js'
import TxnDetail from '../views/TxnDetail.vue'
export default {
  name: 'RefundRequestDetails',
  mixins: [HelpersMixin],
  components: { TxnDetail },
  props: ['requestId'],
  data () {
    return {
      rr: {}
    }
  },
  computed: {
    txnActionsAllowed () {
      if (this.rr && this.rr.status === 'PENDING') {
        return true
      } else {
        return false
      }
    }
  },
  mounted () {
    this.getRequest()
  },
  methods: {
    getRequest: function () {
      this.axios.get('/ax/refundrequest/' + this.requestId)
      .then(response => {
        this.rr = response.data
      })
      .catch(error => this.handleError(error) )
    },
    confirmCancelRequest: function () {
      this.$bvModal.msgBoxConfirm('Are you sure you want to cancel this refund request?', {
        title: 'Please Confirm',
        okTitle: 'Proceed',
        cancelTitle: 'Abort',
        bodyClass: 'fw-bold text-danger text-center',
        centered: true
      })
      .then(value => {
        if (value === true) {
          this.processCancelRequest()
        }
      })
      .catch(error => this.handleError(error) )
    },
    processCancelRequest: function () {
      this.axios.post('/ax/refundrequest/cancel', { requestId: this.requestId} )
      .then(() => {
        this.getRequest()
        this.$refs.txnDetail.getTxnDetail()
        this.$emit('requestupdated')
        this.$toasted.success('Refund Request cancelled')
      })
      .catch(error => this.handleError(error) )
    },
    txnUpdated: function () {
      this.$emit('requestupdated')
      this.getRequest()
    }
  }
}
</script>