import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import axios from 'axios'
import moment from 'moment-timezone'
import VueMoment from 'vue-moment'
import Toasted from 'vue-toasted'
import VueAxios from 'vue-axios'
import Vue2Filters from 'vue2-filters'
import BootstrapVue from 'bootstrap-vue'
import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap-vue/dist/bootstrap-vue.css'
import AdminHeader from './components/AdminHeader.vue'
import InputText from './components/InputText.vue'
import InputNumber from './components/InputNumber.vue'
import InputSelect from './components/InputSelect.vue'
import InputDate from './components/InputDate.vue'
import InputSwitch from './components/InputSwitch.vue'
import DisplayField from './components/DisplayField.vue'
import DisplayDate from './components/DisplayDate.vue'
import WaitModal from './components/WaitModal.vue'
import PageTitle from './components/PageTitle.vue'
import WaitLoading from './components/WaitLoading.vue'

Vue.config.productionTip = false
Vue.use(VueAxios, axios)
Vue.use(VueMoment, {
  moment
})
Vue.use(Vue2Filters)
Vue.use(BootstrapVue)
Vue.use(Toasted, {
  theme: 'bubble',
  position: 'top-right',
  duration: 5000,
  fullWidth: false,
  closeOnSwipe: true,
  iconPack: 'fontawesome',
  icon: {
    name: 'fa-check'
  },
  action: [
    {
      icon: 'fa-times-circle',
      onClick: (e, toastObject) => {
        toastObject.goAway(0)
      }
    }
  ]
})

Vue.component('admin-header', AdminHeader)
Vue.component('page-title', PageTitle)
Vue.component('input-text', InputText)
Vue.component('input-number', InputNumber)
Vue.component('input-select', InputSelect)
Vue.component('input-date', InputDate)
Vue.component('input-switch', InputSwitch)
Vue.component('display-field', DisplayField)
Vue.component('display-date', DisplayDate)
Vue.component('wait-modal', WaitModal)
Vue.component('wait-loading', WaitLoading)

axios.interceptors.response.use(function (response) {
  return response
}, function (error) {
  console.log(error.response.data)
  if (error.response.status === 403) {
    router.push('/login').catch(() => {})
  }
  return Promise.reject(error)
})

axios.interceptors.request.use(
  config => {
    config.headers['ccpgadmin-authz'] = store.state.token.tokenId
    return config
  },
  error => { Promise.reject(error) }
)

const localtz = Intl.DateTimeFormat().resolvedOptions().timeZone
Vue.prototype.$localtz = localtz

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
