<template>
<div>
  <div class="row">
    <display-field label="Transaction ID">{{txn.txnId}}</display-field>
    <display-field label="Processor ID">{{txn.procId}}</display-field>
    <display-field label="Processor Reference">{{txn.procRef}}</display-field>
  </div>
  <div v-if="procResult" class="row">
    <display-field label="Result Code">{{procResult.resultCode}}</display-field>
    <display-field label="Processor Result">{{procResult.rawResultText}}</display-field>
  </div>
  <div v-if="hasError" class="row p-3 text-center text-danger">
    <div class="col-12">{{errorMsg}}</div>
  </div>
  <div class="row justify-content-center" v-if="isLoading">
    <b-spinner variant='primary' label='Please wait'></b-spinner>
    <span class="ms-3 mt-1">Querying processor ... Please wait</span>
  </div>
</div>
</template>

<script>
export default {
  name: 'ProcResultQuery',
  props: ['txn'],
  data () {
    return {
      procResult: null,
      isLoading: false,
      hasError: false,
      errorMsg: ''
    }
  },
  mounted () {
    this.getProcResult()
  },
  methods: {
    getProcResult: function () {
      this.procResult = null
      this.hasError = false
      this.isLoading = true
      this.errorMsg = ''
      this.axios.get('/ax/paytxns/procresult/' + this.txn.txnId)
      .then(response => {
        this.procResult = response.data
        this.isLoading = false
      })
      .catch(error => {
        this.isLoading = false
        this.hasError = true
        if (error.response.status === 422) {
          this.errorMsg = error.response.data.msgText
        } else {
          console.log(JSON.stringify(error.response))
          this.errorMsg = 'Unexpected error. HTTP Status: ' + error.response.status
        }
      })
    }
  }
}
</script>
