<template>
<div>
  <div class="row justify-content-center" v-if="isLoading">
    <b-spinner variant='primary' label='Please wait'></b-spinner>
    <span class="ms-3 mt-1">Loading ... Please wait</span>
  </div>
  <div>
    <h5 class="text-center mb-3">Merchants</h5>
    <div class="text-end small text-info mb-2"><a @click.prevent="createNew()" href="#"><i class="fas fa-plus-square"></i> Create New</a>
    </div>
    <table class="table table-striped table-hover small">
      <thead class="table-dark">
        <tr>
          <th>
            <a href="#" @click.prevent="sortList('merchantId')" class="text-white text-decoration-none">Merchant ID
              <span v-if="sortBy === 'merchantId'"><i class="fa-solid fa-arrow-down-short-wide ms-1 text-info"></i></span>
              <span v-else><i class="fa-solid fa-arrow-down-short-wide ms-1 text-secondary"></i></span>
            </a>
          </th>
          <th>
            <a href="#" @click.prevent="sortList('merchantAlias')" class="text-white text-decoration-none">Alias
              <span v-if="sortBy === 'merchantAlias'"><i class="fa-solid fa-arrow-down-short-wide ms-1 text-info"></i></span>
              <span v-else><i class="fa-solid fa-arrow-down-short-wide ms-1 text-secondary"></i></span>
            </a>
          </th>
          <th>
            <a href="#" @click.prevent="sortList('name')" class="text-white text-decoration-none">Merchant Name
              <span v-if="sortBy === 'name'"><i class="fa-solid fa-arrow-down-short-wide ms-1 text-info"></i></span>
              <span v-else><i class="fa-solid fa-arrow-down-short-wide ms-1 text-secondary"></i></span>
            </a>
          </th>
          <th>
            <a href="#" @click.prevent="sortList('lastUpdate')" class="text-white text-decoration-none">Last Update
              <span v-if="sortBy === 'lastUpdate'"><i class="fa-solid fa-arrow-down-short-wide ms-1 text-info"></i></span>
              <span v-else><i class="fa-solid fa-arrow-down-short-wide ms-1 text-secondary"></i></span>
            </a>
          </th>
          <th>Status</th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="mx in merchants" :key="mx.merchantId" @click="showDetails(mx.merchantId)" style="cursor: pointer;">
          <td>{{mx.merchantId}}</td>
          <td>{{mx.merchantAlias}}</td>
          <td>{{mx.name}}</td>
          <td><display-date :value="mx.lastUpdate"/></td>
          <td>{{mx.status}}</td>
        </tr>
      </tbody>
    </table>
  </div>
</div>
</template>

<script>
export default {
  name: 'MerchantList',
  data () {
    return {
      isLoading: false,
      sortBy: 'name',
      merchants: {}
    }
  },
  mounted () {
    this.getMerchants()
  },
  methods: {
    getMerchants: function () {
      this.axios.get('/ax/merchants')
        .then(response => {
          this.isLoading = false
          this.merchants = response.data
          this.sortList()
          this.merchants.sort((m1, m2) => (m1[this.sortBy] > m2[this.sortBy]) ? 1 : -1 )
        })
        .catch(error => {
          this.isLoading = false
          console.log(error.response.status)
        })
    },
    sortList: function (fld) {
      if (fld) {
        this.sortBy = fld
      }
      this.merchants.sort((a,b) => {
        return a[this.sortBy].localeCompare(b[this.sortBy], undefined, {sensitivity: 'base'})
      })
    },
    showDetails: function (merchantId) {
      this.$router.push('/merchant/' + merchantId)
    },
    createNew: function () {
      this.$router.push('/merchantform/')
    }
  }
}
</script>
