<template>
<div>
  <div class="text-end small text-info mb-2">
    <a @click.prevent="showForm()" href="#"><i class="fas fa-plus-square"></i> Add Account</a>
  </div>
  <table class="table small table-hover" v-if="mxAccounts.length > 0">
    <thead class="table-dark">
      <tr>
        <th>
          <a href="#" @click.prevent="sortList('accountId')" class="text-white text-decoration-none">Account ID
            <span v-if="sortBy === 'accountId'"><i class="fa-solid fa-arrow-down-short-wide ms-1 text-info"></i></span>
            <span v-else><i class="fa-solid fa-arrow-down-short-wide ms-1 text-secondary"></i></span>
          </a>
        </th>
        <th>
          <a href="#" @click.prevent="sortList('accountName')" class="text-white text-decoration-none">Account Name
            <span v-if="sortBy === 'accountName'"><i class="fa-solid fa-arrow-down-short-wide ms-1 text-info"></i></span>
            <span v-else><i class="fa-solid fa-arrow-down-short-wide ms-1 text-secondary"></i></span>
          </a>
        </th>
        <th>Website URL / Notes</th>
        <th>Daily Limit</th>
        <th>Monthly Limit</th>
        <th>Status</th>
      </tr>
    </thead>
    <tbody>
      <tr v-for="mxa in mxAccounts" :key="mxa.accountId" @click="showForm(mxa)" style="cursor: pointer;">
        <td>{{mxa.accountId}}</td>
        <td>{{mxa.accountName}}</td>
        <td>
          <span>{{mxa.website ? mxa.website : '---'}}</span>
          <span v-if="mxa.notes" class="text-info small"><br/>{{mxa.notes}}</span>
        </td>
        <td>{{mxa.dailyLimit | number('0,0.00')}}</td>
        <td>{{mxa.monthlyLimit | number('0,0.00')}}</td>
        <td>{{mxa.active ? 'ACTIVE' : 'INACTIVE'}}</td>
      </tr>
    </tbody>
  </table>
  <div v-else class="text-center text-warning">No Merchant Accounts Found</div>
  <b-modal id="mxa-form" :title="'Merchant Account (' + selectedAcct.accountId + ')'" hide-footer size="lg">
    <merchant-account-form :merchant-id="merchantId" :merchant-account="selectedAcct"
      @cancelled="$bvModal.hide('mxa-form')"
      @saved="refreshList">
    </merchant-account-form>
  </b-modal>
</div>
</template>

<script>
import MerchantAccountForm from '../components/MerchantAccountForm.vue'
export default {
  name: 'MerchantAccounts',
  components: {
    MerchantAccountForm
  },
  props: ['merchantId'],
  data () {
    return {
      mxAccounts: [],
      selectedAcct: {},
      sortBy: 'accountName'
    }
  },
  mounted () {
    this.getMerchantAccounts()
  },
  methods: {
    getMerchantAccounts: function () {
      this.axios.get('/ax/mxaccounts/' + this.merchantId)
        .then(response => {
          this.mxAccounts = response.data
          this.sortList()
        })
        .catch(error => {
          console.log(error.response.status)
        })
    },
    sortList: function (fld) {
      if (fld) {
        this.sortBy = fld
      }
      this.mxAccounts.sort((a,b) => {
        return a[this.sortBy].localeCompare(b[this.sortBy], undefined, {sensitivity: 'base'})
      })
    },
    showForm: function (mxa) {
      if (mxa) {
        this.selectedAcct = mxa
      } else {
        this.selectedAcct = {}
      }
      this.$bvModal.show('mxa-form')
    },
    refreshList: function () {
      this.$bvModal.hide('mxa-form')
      this.getMerchantAccounts()
    }
  }
}
</script>
