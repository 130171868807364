import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from './views/Home.vue'
import AdminLogin from './views/AdminLogin.vue'
import TxnList from './views/TxnList.vue'
import TxnDetail from './views/TxnDetail.vue'
import MerchantList from './views/MerchantList.vue'
import MerchantDetail from './views/MerchantDetail.vue'
import MerchantForm from './views/MerchantForm.vue'
import TpAttribs from './views/TpAttribs.vue'
import CacheRefresh from './views/CacheRefresh.vue'
import RoutingRules from './views/RoutingRules.vue'
import AdminPwChange from './views/AdminPwChange.vue'
import Summaries from './views/Summaries.vue'
import MemoTxnList from './views/MemoTxnList.vue'
import RefundRequests from './views/RefundRequests.vue'
import MercSettlementCalc from './views/MercSettlementCalc.vue'
import ProcSettlementCalc from './views/ProcSettlementCalc.vue'
import ProcReserveCalc from './views/ProcReserveCalc.vue'
import ReserveReleaseCalc from './views/ReserveReleaseCalc.vue'
import MercSettlementReports from './views/MercSettlementReports.vue'
import EmailExtract from './views/EmailExtract.vue'
import ReferenceData from './views/ReferenceData.vue'
import store from './store'

Vue.use(VueRouter)

const routes = [
    {
      path: '/',
      name: 'home',
      component: Home
    },
    {
      path: '/login',
      name: 'login',
      component: AdminLogin
    },
    {
      path: '/summaries',
      name: 'summaries',
      component: Summaries
    },
    {
      path: '/transactions',
      name: 'transactions',
      component: TxnList
    },
    {
      path: '/transaction/:txnId',
      name: 'transaction',
      component: TxnDetail,
      props: true
    },
    {
      path: '/merchants',
      name: 'merchants',
      component: MerchantList
    },
    {
      path: '/merchant/:merchantId',
      name: 'merchant',
      component: MerchantDetail,
      props: true
    },
    {
      path: '/merchantform/:merchantId?',
      name: 'merchantform',
      component: MerchantForm,
      props: true
    },
    {
      path: '/tpattribs',
      name: 'tpattribs',
      component: TpAttribs
    },
    {
      path: '/cacherefresh',
      name: 'cacherefresh',
      component: CacheRefresh
    },
    {
      path: '/routingrules',
      name: 'routingrules',
      component: RoutingRules
    },
    {
      path: '/mercsettlementcalc',
      name: 'mercsettlementcalc',
      component: MercSettlementCalc
    },
    {
      path: '/procsettlementcalc',
      name: 'procsettlementcalc',
      component: ProcSettlementCalc
    },
    {
      path: '/procreservecalc',
      name: 'procreservecalc',
      component: ProcReserveCalc
    },
    {
      path: '/reservereleasecalc',
      name: 'reservereleasecalc',
      component: ReserveReleaseCalc
    },
    {
      path: '/memotxns',
      name: 'memotxns',
      component: MemoTxnList
    },
    {
      path: '/refundrequests',
      name: 'refundrequests',
      component: RefundRequests
    },
    {
      path: '/mercsettlementreports',
      name: 'mercsettlementreports',
      component: MercSettlementReports
    },
    {
      path: '/emailextract',
      name: 'emailextract',
      component: EmailExtract
    },
    {
      path: '/referencedata',
      name: 'referencedata',
      component: ReferenceData
    },
    {
      path: '/pwchange',
      name: 'pwchange',
      component: AdminPwChange
    }
  ]

const router = new VueRouter({
  routes
})

router.beforeEach((to, from, next) => {
  if (store.state.token && store.state.token.tokenId) {
    next()
  } else {
    if (to.name === 'login') {
      next()
    } else {
      next('/login')
    }
  }
})

export default router
