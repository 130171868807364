<template>
<div>
  <wait-loading :loading="isLoading"/>

  <div class="row mt-2 mb-3">
    <a href="#" @click.prevent="prevDay" class="col-auto ms-auto" v-b-tooltip.hover title="Previous Day" style="text-decoration: none;">&lt;&nbsp;</a>
    <input-date v-model="inDate" group-class="col-auto" input-class="fw-bold"></input-date>
    <a href="#" @click.prevent="nextDay" class="col-auto me-auto" v-b-tooltip.hover title="Next Day" style="text-decoration: none;">&nbsp;&gt;</a>
    <a href="#" @click.prevent="getSummaries()" class="col-auto me-2 mt-2 text-info" v-b-tooltip.hover title="Refresh"><i class="fas fa-sync"></i></a>
  </div>
  
  <div class="row">
    <div class="col-12">
    <table class="table table-bordered table-sm small w-100">
      <thead class="table-light">
        <tr>
          <th rowspan="3" class="align-bottom text-center">Merchant / Account</th>
          <th v-if="colview === 'dayview'" colspan="6" class="text-center text-white bg-dark">
            {{monthNames[parseInt(month) - 1]}} {{dayOfMonth}}, {{year}}
          </th>
          <th v-else class="text-center text-white bg-dark"><a href="javascript:void(0);" @click="colview = 'dayview'">{{month}}/{{dayOfMonth}}...</a></th>
          <th v-if="colview === 'mtdview'" colspan="6" class="text-center text-white bg-dark">Month-to-Date</th>
          <th v-else class="text-center text-white bg-dark"><a href="javascript:void(0);" @click="colview = 'mtdview'">MTD...</a></th>
        </tr>
        <tr class="text-center">
          <th v-if="colview === 'mtdview'">...</th>
          <th colspan="2">Failed</th>
          <th colspan="2">Success</th>
          <th colspan="2">Success Rate</th>
          <th v-if="colview === 'dayview'">...</th>
        </tr>
        <tr class="small text-center">
          <th v-if="colview === 'mtdview'" width="5%">...</th>
          <th class="text-info" width="10%">Count</th>
          <th class="text-info" width="15%">Amount</th>
          <th class="text-info" width="10%">Count</th>
          <th class="text-info" width="15%">Amount</th>
          <th class="text-info" width="10%">(on count)</th>
          <th class="text-info" width="10%">(on amount)</th>
          <th v-if="colview === 'dayview'" width="5%">...</th>
        </tr>
      </thead>
      <tbody>
        <template v-for="(msumm, idx) in msummaries">
        <tr v-if="idx === 0 || msumm.merchantId !== msummaries[idx - 1].merchantId" :key="idx">
          <td colspan="8" class="font-italic fw-bold small text-primary">{{msumm.merchantName}} ({{msumm.merchantId}})</td>
        </tr>
        <tr  :key="msumm.recordKey">
          <td>
            &nbsp;&nbsp;&nbsp;
            <a class="ms-2" @click="showMxProcSummary(msumm.merchantId, msumm.accountId)" v-b-tooltip.hover title="Breakdown by Processor"><i class="fas fa-list"></i></a>
            {{msumm.accountId}} - {{msumm.accountName}}
          </td>
          <td class="text-center" v-if="colview === 'mtdview'">...</td>
          <td class="text-center failedCol">{{getFailedCount(msumm)}}</td>
          <td class="text-end failedCol pe-3">{{getFailedAmount(msumm) | number('0,0.00')}}</td>
          <td class="text-center successCol">{{getSuccessCount(msumm)}}</td>
          <td class="text-end successCol pe-3">{{getSuccessAmount(msumm) | number('0,0.00')}}</td>
          <td class="text-end">{{getSuccessRateOnCount(msumm) | number('0,0.00')}} %</td>
          <td class="text-end">{{getSuccessRateOnAmount(msumm) | number('0,0.00')}} %</td>
          <td class="text-center" v-if="colview === 'dayview'">...</td>
        </tr>
        </template>
      </tbody>
      <tfoot class="table-light">
        <tr>
          <th class="py-3 fw-bold text-center">Totals</th>
          <th class="py-3 text-center" v-if="colview === 'mtdview'">...</th>
          <th class="py-3 text-center">{{grandTotals.failedCount}}</th>
          <th class="py-3 text-end pe-3">{{grandTotals.failedAmount | number('0,0.00')}}</th>
          <th class="py-3 text-center">{{grandTotals.successCount}}</th>
          <th class="py-3 text-end pe-3">{{grandTotals.successAmount | number('0,0.00')}}</th>
          <th class="py-3 text-end">{{grandTotals.successRateOnCount | number('0,0.00')}} %</th>
          <th class="py-3 text-end">{{grandTotals.successRateOnAmount | number('0,0.00')}} %</th>
          <th class="py-3 text-center" v-if="colview === 'dayview'">...</th>
        </tr>
      </tfoot>
    </table>
    </div>
  </div>

  <b-modal id="mx-proc-summ" size="lg" title="Load Breakdown by Processor - for the month" hide-footer>
    <merchant-proc-summary :merchant-id="selMerchantId" :account-id="selAccountId" :year=year :month=month>
    </merchant-proc-summary>
  </b-modal>
</div>
</template>

<script>
import HelpersMixin from '../utils/HelpersMixin.js'
import MerchantProcSummary from '../components/MerchantProcSummary.vue'
import moment from 'moment'
export default {
  name: 'MerchantTxnsOverview',
  mixins: [HelpersMixin],
  components: {
    MerchantProcSummary
  },
  data () {
    return {
      inDate: '',
      monthNames: ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'],
      isLoading: false,
      colview: 'dayview',
      year: '',
      month: '',
      dayOfMonth: '',
      msummaries: {},
      selMerchantId: '',
      selAccountId: ''
    }
  },
  computed: {
    grandTotals: function () {
      var gt = { successCount: 0, successAmount: 0, failedCount: 0, failedAmount: 0, successRateOnCount: 0, successRateOnAmount: 0 }
      if (this.msummaries) {
        for (var ms of Object.values(this.msummaries)) {
          gt.successCount += this.getSuccessCount(ms)
          gt.successAmount += this.getSuccessAmount(ms)
          gt.failedCount += this.getFailedCount(ms)
          gt.failedAmount += this.getFailedAmount(ms)
        }
        gt.successRateOnCount = (gt.successCount + gt.failedCount) === 0 ? 0 : gt.successCount / (gt.successCount + gt.failedCount) * 100
        gt.successRateOnAmount = gt.successAmount + gt.failedAmount === 0 ? 0 : gt.successAmount / (gt.successAmount + gt.failedAmount) * 100
      }
      return gt
    }
  },
  watch: {
    inDate: function () {
      var d = new Date(this.inDate)
      this.extractYMDStr(d)
    }
  },
  mounted () {
    var d = new Date()
    this.extractYMDStr(d)
    this.getSummaries()
    this.$watch('year', this.getSummaries)
    this.$watch('month', this.getSummaries)
  },
  methods: {
    getSummaries: function () {
      this.axios.get('/ax/merchanttxnsummaries/' + this.year + this.month)
        .then(response => {
          this.isLoading = false
          this.msummaries = response.data
        })
        .catch(error => {
          this.isLoading = false
          this.handleError(error)
        })
    },
    prevDay: function () {
      var d = new Date(this.inDate)
      var m = moment(d).utc()
      var prev = m.subtract(1, 'days')
      this.extractYMDStr(prev.toDate())
    },
    nextDay: function () {
      var d = new Date(this.inDate)
      var m = moment(d).utc()
      var next = m.add(1, 'days')
      this.extractYMDStr(next.toDate())
    },
    extractYMDStr: function (d) {
      this.year = d.getUTCFullYear().toString()
      var m = d.getUTCMonth() + 1
      if (m < 10) {
        this.month = '0' + m.toString()
      } else {
        this.month = m.toString()
      }
      var dt = d.getUTCDate()
      this.dayOfMonth = dt.toString()
      this.inDate = this.year + '-' + this.month + '-' + (dt < 10 ? '0' + this.dayOfMonth : this.dayOfMonth)
    },
    getFailedCount: function (msumm) {
      if (this.colview === 'mtdview') {
        return msumm.mtdFailedCount
      }
      if (!msumm.daySummaries || !msumm.daySummaries[this.dayOfMonth]) {
        return 0
      }
      return msumm.daySummaries[this.dayOfMonth].failedCount
    },
    getFailedAmount: function (msumm) {
      if (this.colview === 'mtdview') {
        return msumm.mtdFailedAmount
      }
      if (!msumm.daySummaries || !msumm.daySummaries[this.dayOfMonth]) {
        return 0
      }
      return msumm.daySummaries[this.dayOfMonth].failedAmount
    },
    getSuccessCount: function (msumm) {
      if (this.colview === 'mtdview') {
        return msumm.mtdCount
      }
      if (!msumm.daySummaries || !msumm.daySummaries[this.dayOfMonth]) {
        return 0
      }
      return msumm.daySummaries[this.dayOfMonth].count
    },
    getSuccessAmount: function (msumm) {
      if (this.colview === 'mtdview') {
        return msumm.mtdAmount
      }
      if (!msumm.daySummaries || !msumm.daySummaries[this.dayOfMonth]) {
        return 0
      }
      return msumm.daySummaries[this.dayOfMonth].amount
    },
    getSuccessRateOnCount: function (msumm) {
      if (this.colview === 'mtdview') {
        return msumm.mtdCount / (msumm.mtdCount + msumm.mtdFailedCount) * 100
      }
      if (!msumm.daySummaries || !msumm.daySummaries[this.dayOfMonth]) {
        return 0
      }
      return msumm.daySummaries[this.dayOfMonth].count / (msumm.daySummaries[this.dayOfMonth].count + msumm.daySummaries[this.dayOfMonth].failedCount) * 100
    },
    getSuccessRateOnAmount: function (msumm) {
      if (this.colview === 'mtdview') {
        return msumm.mtdAmount / (msumm.mtdAmount + msumm.mtdFailedAmount) * 100
      }
      if (!msumm.daySummaries || !msumm.daySummaries[this.dayOfMonth]) {
        return 0
      }
      return msumm.daySummaries[this.dayOfMonth].amount / (msumm.daySummaries[this.dayOfMonth].amount + msumm.daySummaries[this.dayOfMonth].failedAmount) * 100
    },
    showMxProcSummary: function (merchantId, accountId) {
      this.selMerchantId = merchantId
      this.selAccountId = accountId
      this.$bvModal.show('mx-proc-summ')
    }
  }
}
</script>

<style scoped>
.successCol { background-color: #ccffcc }
.failedCol { background-color: #ffd6cc }
</style>
