<template>
<span>
  <template v-if="value">{{value | moment("timezone", tz, format)}}</template>
  <template v-else>--</template>
</span>
</template>

<script>
export default {
  name: 'DisplayDate',
  props: {
    value: [String, Number, Date, Object], 
    timezone: String,
    showSeconds: { type: Boolean, default: false },
    hideyear: { type: Boolean, default: false },
    dateonly: { type: Boolean, default: false }
    },
  data () {
    return {
      formatShort: 'MMM DD - HH:mm',
      formatDefault: 'YYYY-MM-DD HH:mm',
      formatDateOnly: 'YYYY-MM-DD'
    }
  },
  computed: {
    tz: function () {
      if (this.timezone) {
        return this.timezone
      } else {
        return this.$localtz
      }
    },
    format: function () {
      if (this.dateonly) {
        return this.formatDateOnly
      } else if (this.hideyear) {
        return this.formatShort + (this.showSeconds ? ':ss zz' : ' zz')
      } else {
        return this.formatDefault + (this.showSeconds ? ':ss zz' : ' zz')
      }
    }
  }
}
</script>