<template>
<div>
  <div class="text-end small text-info mb-2">
    <a @click="showForm()" href="#"><i class="fas fa-plus-square"></i> Add User</a>
  </div>
  <table class="table small table-hover" v-if="mxUsers.length > 0">
    <thead class="table-dark">
      <tr>
        <th>User ID</th>
        <th>Name</th>
        <th>Email Address</th>
        <th>Created on</th>
        <th>Last Logged-in</th>
      </tr>
    </thead>
    <tbody>
      <tr v-for="mxu in mxUsers" :key="mxu.userId" @click="showForm(mxu)" style="cursor: pointer;">
        <td>{{mxu.userId}}</td>
        <td>{{mxu.firstName}} {{mxu.lastName}}</td>
        <td>{{mxu.emailAddress}}</td>
        <td><display-date :value="mxu.createDate"/></td>
        <td><display-date :value="mxu.lastLogin"/></td>
      </tr>
    </tbody>
  </table>
  <div v-else class="text-center text-warning">No Merchant Users Found</div>
  <b-modal id="mxu-form" title="Merchant User" hide-footer>
    <merchant-user-form :merchant-id="merchantId" :merchant-user="selectedUser"
      @cancelled="$bvModal.hide('mxu-form')"
      @saved="refreshList">
    </merchant-user-form>
  </b-modal>
</div>
</template>

<script>
import MerchantUserForm from '../components/MerchantUserForm.vue'
export default {
  name: 'MerchantUsers',
  components: {
    MerchantUserForm
  },
  props: ['merchantId'],
  data () {
    return {
      mxUsers: [],
      selectedUser: {}
    }
  },
  mounted () {
    this.getMerchantUsers()
  },
  methods: {
    getMerchantUsers: function () {
      this.axios.get('/ax/mxusers/' + this.merchantId)
        .then(response => {
          this.mxUsers = response.data
        })
        .catch(error => {
          console.log(error.response.status)
        })
    },
    showForm: function (mxu) {
      if (mxu) {
        this.selectedUser = mxu
      } else {
        this.selectedUser = {}
      }
      this.$bvModal.show('mxu-form')
    },
    refreshList: function () {
      this.$bvModal.hide('mxu-form')
      this.getMerchantUsers()
    }
  }
}
</script>
