<template>
<div>
  <div class="row justify-content-center" v-if="isLoading">
    <b-spinner variant='primary' label='Please wait'></b-spinner>
    <span class="ms-3 mt-1">Loading ... Please wait</span>
  </div>
  <div v-else>
    <h5 class="text-center mb-4">
      <span class="small">{{mxProcSumm.merchantId}}-{{mxProcSumm.accountId}}</span><br/>
      <span class="text-info">{{mxProcSumm.merchantName}} - {{mxProcSumm.accountName}}</span>
    </h5>
    <table class="table table-bordered table-sm small w-100">
      <thead class="table-dark">
        <tr>
          <th rowspan="2" class="text-center" width="25%">Processor</th>
          <th colspan="2" class="text-center" width="25%">Failed</th>
          <th colspan="2" class="text-center" width="25%">Success</th>
          <th rowspan="2" class="text-center" width="25%">% of Processor Total</th>
        </tr>
        <tr class="small text-info">
          <th class="text-center bg-secondary">Count</th>
          <th class="text-center bg-secondary">Amount</th>
          <th class="text-center bg-secondary">Count</th>
          <th class="text-center bg-secondary">Amount</th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="(mpl, index) in mxProcSumm.procLoadList" v-bind:key="index">
          <td class="text-start">{{mpl.key}}</td>
          <td class="text-center failedCol">{{mpl.failedCount}}</td>
          <td class="text-end pe-4 failedCol">{{mpl.failedAmount | number('0,0.00')}}</td>
          <td class="text-center successCol">{{mpl.successCount}}</td>
          <td class="text-end pe-4 successCol">{{mpl.successAmount | number('0,0.00')}}</td>
          <td class="text-end bg-light pe-4">{{mpl.pctOfProcSuccessAmountTotal * 100 | number('0,0.00')}} %</td>
        </tr>
      </tbody>
    </table>
  </div>
</div>
</template>

<script>
export default {
  name: 'MerchantProcSummary',
  props: {
    merchantId: String,
    accountId: String,
    year: String,
    month: String
  },
  data () {
    return {
      isLoading: true,
      mxProcSumm: {}
    }
  },
  mounted () {
    this.getMxProcSumm()
  },
  methods: {
    getMxProcSumm: function () {
      this.isLoading = true
      var apiUri = '/ax/merchantprocsummary/' + this.merchantId + '/' + this.accountId + '/' + this.year + '/' + this.month
      this.axios.get(apiUri)
        .then(response => {
          this.isLoading = false
          this.mxProcSumm = response.data
        })
        .catch(error => {
          this.isLoading = false
          console.log(error.response.status)
        })
    }
  }
}
</script>
