<template>
<div>
  <h5 class="text-center mb-5">Processor Settlement Calculator</h5>

  <div v-if="view === 'form'">
    <div class="border py-2 px-2 mx-0 mb-2">
    <div class="row mb-2">
      <div class="col-2">Time Zone</div>
      <div class="col-auto">
        <input-select v-model="timezone">
          <option v-for="tz in tzList" :key="tz.refCode" :value="tz.refCode">{{tz.refValue}}</option>
        </input-select>
      </div>
    </div>
    
    <div class="row mb-2">
      <div class="col-2">Transactions Date Range</div>
      <input-date v-model="txnDateStart" group-class="col-auto"></input-date>
      <div class="col-auto ms-0 ps-0">
        <input v-model="txnTimeStart" class="form-control" type="time"/>
      </div>
      <div class="col-auto mx-2">to</div>
      <input-date v-model="txnDateEnd" group-class="col-auto"></input-date>
      <div class="col-auto ms-0 ps-0">
        <input v-model="txnTimeEnd" class="form-control" type="time"/>
      </div>
    </div>
    
    <div class="row mb-2">
      <div class="col-2">CB/Refunds Date Range</div>
      <input-date v-model="adjDateStart" group-class="col-auto"></input-date>
      <div class="col-auto ms-0 ps-0">
        <input v-model="adjTimeStart" class="form-control" type="time"/>
      </div>
      <div class="col-auto mx-2">to</div>
      <input-date v-model="adjDateEnd" group-class="col-auto"></input-date>
      <div class="col-auto ms-0 ps-0">
        <input v-model="adjTimeEnd" class="form-control" type="time"/>
      </div>
    </div>
    </div>

    <proc-multi-select ref="procMultiSelect"></proc-multi-select>
    
    <div class="row mt-5">
      <button class="btn btn-primary col col-auto mx-auto" @click="calcSettlement">Calculate Settlement</button>
    </div>
  </div>
  
  <div v-if="view === 'result'">
    <div class="text-end text-info"><a @click="view = 'form'">Reset</a></div>
    <div class="row">
      <div class="col-5">
        <h5>Summary</h5>
        <table class="table table-bordered table-sm small">
          <thead class="table-dark">
            <tr><th>Transaction Type</th><th>Count</th></tr>
          </thead>
          <tbody>
            <tr><td>Success</td><td>{{setlm.successCount}}</td></tr>
            <tr><td>Failed</td><td>{{setlm.failedCount}}</td></tr>
            <tr><td>Chargebacks</td><td>{{setlm.chargebackCount}}</td></tr>
            <tr><td>Refunds</td><td>{{setlm.refundCount}}</td></tr>
            <tr><td>Refunded Chargebacks</td><td>{{setlm.refundedCbCount}}</td></tr>
          </tbody>
        </table>
      </div>
      <div class="col-6 text-center pt-4 border border-info my-3 mx-auto setlm-header text-info">
        <p class="mt-0 pt-0 small">{{lookupTzDesc(timezone)}}</p>
        <p class="mb-0">Txns: {{txnDateStart}}T{{txnTimeStart}} - {{txnDateEnd}}T{{txnTimeEnd}}</p>
        <p class="mb-0">Adjs: {{adjDateStart}}T{{adjTimeStart}} - {{adjDateEnd}}T{{adjTimeEnd}}</p>
        <p class="mt-2">{{procIds}}</p>
      </div>
    </div>
    <div class="row">
      <table class="table table-bordered table-sm">
        <thead class="table-dark">
          <tr><th>&nbsp;</th><th class="text-end pe-2">Amount (USD)</th></tr>
        </thead>
        <tbody>
          <tr class="fw-bold">
            <td>Total Amount of Successful Transactions (net of adjustments)</td>
            <td class="text-end">${{setlm.successAmount | number('0,0.00')}}</td>
          </tr>
          <tr class="small" style="line-height: 2px;"><td colspan="2">&nbsp;</td></tr>
          <tr>
            <td>Rolling Reserve <span class="small ps-2 text-info">({{setlm.rollingReservePct}}%)</span></td>
            <td class="text-end">$({{setlm.rollingReserve | number('0,0.00')}})</td>
          </tr>
          <tr>
            <td>Processing Fee <span class="small ps-2 text-info">({{setlm.processingFeePct}}%)</span></td>
            <td class="text-end">$({{setlm.processingFee | number('0,0.00')}})</td>
          </tr>
          <tr>
            <td>Transaction Fee <span class="small ps-2 text-info">(${{setlm.transactionFeeRate}} per transaction)</span></td>
            <td class="text-end">$({{setlm.transactionFee | number('0,0.00')}})</td>
          </tr>
          <tr>
            <td>Chargeback Amount</td>
            <td class="text-end">$({{setlm.chargebackAmount | number('0,0.00')}})</td>
          </tr>
          <tr>
            <td>Chargeback Fee <span class="small ps-2 text-info">(${{setlm.chargebackFeeRate}} per chargeback)</span></td>
            <td class="text-end">$({{setlm.chargebackFee | number('0,0.00')}})</td>
          </tr>
          <tr>
            <td>Refund Amount</td>
            <td class="text-end">$({{setlm.refundAmount | number('0,0.00')}})</td>
          </tr>
          <tr>
            <td>Refund Fee <span class="small ps-2 text-info">(${{setlm.refundFeeRate}} per refund)</span></td>
            <td class="text-end">$({{setlm.refundFee | number('0,0.00')}})</td>
          </tr>
          <tr>
            <td>Refunded CB Amount</td>
            <td class="text-end">$({{setlm.refundedCbAmount | number('0,0.00')}})</td>
          </tr>
          <tr>
            <td>Refunded CB Fee <span class="small ps-2 text-info">(${{setlm.refundedCbFeeRate}} per refunded CB)</span></td>
            <td class="text-end">$({{setlm.refundedCbFee | number('0,0.00')}})</td>
          </tr>
          <tr>
            <td>Settlement Fee</td>
            <td class="text-end">$({{setlm.settlementFee | number('0,0.00')}})</td>
          </tr>
          <tr class="small" style="line-height: 5px;"><td colspan="2">&nbsp;</td></tr>
          <tr class="bg-dark text-white fw-bold">
            <td>Net Settlement</td>
            <td class="text-end">${{setlm.netSettlement | number('0,0.00')}}</td>
          </tr>
          <tr><td colspan="2">&nbsp;</td></tr>
          <tr class="small text-warning font-italic">
            <td>Expected Reserve Released <span class="small ps-2 text-info">({{setlm.releasedDateStart}} - {{setlm.releasedDateEnd}})</span></td>
            <td class="text-end">${{setlm.reserveReleased | number('0,0.00')}}</td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
  
  <wait-modal :loading="isLoading" message="Processing Settlement ... please wait"/>
</div>
</template>

<script>
import moment from 'moment'
import HelpersMixin from '../utils/HelpersMixin.js'
import ProcMultiSelect from '../components/ProcMultiSelect.vue'
export default {
  name: 'ProcSettlementCalc',
  components: { ProcMultiSelect },
  mixins: [HelpersMixin],
  data () {
    return {
      txnDateStart: '',
      txnTimeStart: '00:00',
      txnDateEnd: '',
      txnTimeEnd: '23:59',
      adjDateStart: '',
      adjTimeStart: '00:00',
      adjDateEnd: '',
      adjTimeEnd: '23:59',
      timezone: 'UTC',
      procIds: [],
      setlm: {},
      isLoading: false,
      view: 'form'
    }
  },
  mounted () {
    this.getTimezones()
    
    var baseMoment = moment().utc().subtract(7, 'days')
    this.txnDateStart = baseMoment.startOf('isoweek').format('YYYY-MM-DD')
    this.txnDateEnd = baseMoment.endOf('isoweek').format('YYYY-MM-DD')
    this.adjDateStart = baseMoment.startOf('isoweek').format('YYYY-MM-DD')
    this.adjDateEnd = baseMoment.endOf('isoweek').format('YYYY-MM-DD')
  },
  methods: {
    calcSettlement: function () {
      this.procIds = this.$refs.procMultiSelect.getSelectedProcs()
      if (this.procIds.length < 1) {
        this.$toasted.error('Please select 1 or more processors')
        return
      }
      this.isLoading = true
      const payload = {
              procIds: this.procIds,
              txnDateStart: this.txnDateStart,
              txnTimeStart: this.txnTimeStart,
              txnDateEnd: this.txnDateEnd,
              txnTimeEnd: this.txnTimeEnd,
              adjDateStart:  this.adjDateStart,
              adjTimeStart: this.adjTimeStart,
              adjDateEnd: this.adjDateEnd,
              adjTimeEnd: this.adjTimeEnd,
              timezone: this.timezone
      }
      this.axios.post('/ax/procsettlement', payload)
      .then(response => {
        this.isLoading = false
        this.setlm = response.data
        this.view = 'result'
      })
      .catch(error => {
        this.isLoading = false
        this.handleError(error)
      })
    }
  }
}
</script>

<style scoped>
.setlm-header { background-color: #ffffe6; font-weight: bold;}
</style>
