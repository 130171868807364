<template>
<div class="row justify-content-center">
  <div class="w-50 mt-5">
    <table class="table table-hover">
      <tbody>
        <tr  v-for="b in beanNames" :key="b">
          <td>{{b}}</td>
          <td><a href="#" @click.prevent="refreshBean(b)" title="refresh cache"><i class="fas fa-sync"></i></a></td>
        </tr>
      </tbody>
    </table>
  </div>
</div>
</template>

<script>
export default {
  name: 'CacheRefresh',
  data: () => ({
    beanNames: ['tpAttribsSvc', 'ccProcessorSvcImpl', 'referenceDataSvc', 'paymentTxnAdminSvcImpl', 'emailTemplateSvc', 'velocityMonitorSvcImpl'],
    retrieving: false
  }),
  methods: {
    refreshBean (beanName) {
      var urlstr = '/ax/refresh/' + beanName
      this.retrieving = true
      this.axios.get(urlstr)
        .then(response => {
          console.log(response)
          this.$toasted.success('Cache refresh request submitted for ' + beanName)
          this.retrieving = false
        })
        .catch(error => {
          console.log(error)
          this.$toasted.error('Unexpected error encountered ... check console log')
          this.retrieving = false
        })
    }
  }
}
</script>
