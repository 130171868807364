<template>
<div class="container">
  <page-title title="Refund Requests"/>

  <div class="row mb-5">
    <div class="col-auto">
      <period-selector @periodUpdated="onPeriodUpdated" @dateRangeChanged="onDateRangeChanged" default-period="LAST3DAYS" />
    </div>
    <input-select v-model="statusFilter" group-class="col-auto">
      <option value="ALL">Any Status</option>
      <option value="PENDING">PENDING</option>
      <option value="COMPLETED">COMPLETED</option>
      <option value="CANCELLED">CANCELLED</option>
    </input-select>
  </div>

  <paginated-list :result="result" :search-params="searchParams" :search-fxn="search">
    <table class="table table-sm small">
      <thead class="table-dark">
        <tr>
          <th>Request ID</th>
          <th>Request Date</th>
          <th>Status Date</th>
          <th>Txn ID</th>
          <th class="text-center">Amount</th>
          <th>Status</th>
        </tr>
      </thead>
      <tbody>
        <tr v-if="isLoading">
          <td colspan="6" class="text-center text-info fw-bold py-3">
            <b-spinner small variant='info' label='Please wait'></b-spinner>
            <span class="ms-2">  Please wait ... processing</span>
          </td>
        </tr>
        <tr v-if="!pendingSearch && (!result.totalRecs || result.totalRecs === 0)">
          <td colspan="6" class="text-center text-warning fw-bold py-3">No refund requests found for specified criteria</td>
        </tr>
        <tr v-for="rr in result.resultList" :key="rr.requestId" @click="showDetails(rr.requestId)">
          <td>{{rr.requestId}}</td>
          <td class="text-nowrap">
            <display-date :value="rr.requestDate" timezone="UTC" hideyear/><br/>
            <small class="text-info"><display-date :value="rr.requestDate" :timezone="auxTz" hideyear/></small>
          </td>
          <td class="text-nowrap">
            <display-date :value="rr.statusDate" timezone="UTC" hideyear/><br/>
            <small class="text-info"><display-date :value="rr.statusDate" :timezone="auxTz" hideyear/></small>
          </td>
          <td>{{rr.refTxnId}}</td>
          <td class="text-end text-nowrap pe-3">{{rr.amount.value | number('0,0.00')}} {{rr.amount.currency}}</td>
          <td>{{rr.status}}</td>
        </tr>
      </tbody>
    </table>
  </paginated-list>
  
  <b-modal id="rr-details" size="lg" :title="'Refund Request - ' + selectedRequestId" hide-footer header-bg-variant="info" @hidden="onDetailsClosed()">
    <refund-request-details :requestId="selectedRequestId" @requestupdated="requestUpdated = true"/>
  </b-modal>
</div>
</template>

<script>
import HelpersMixin from '../utils/HelpersMixin.js'
import PeriodSelector from '../components/PeriodSelector.vue'
import PaginatedList from '../components/PaginatedList.vue'
import RefundRequestDetails from '../components/RefundRequestDetails.vue'
export default {
  name: 'RefundRequests',
  mixins: [HelpersMixin],
  components: { PeriodSelector, PaginatedList, RefundRequestDetails },
  data () {
    return {
      searchParams: { offset: 0, size: 50, sortField: 'requestDate' },
      result: {},
      selectedRequestId: '',
      requestUpdated: false,
      dateStart: '',
      dateEnd: '',
      timezone: 'UTC',
      auxTz: this.$localtz,
      statusFilter: 'PENDING',
      pendingSearch: false
    }
  },
  watch: {
    statusFilter: function () {
      this.search(0)
    },
  },
  methods: {
    search: function (offset) {
      if (offset !== null) {
        this.searchParams.offset = offset
      }
      this.searchParams.dateStart = this.dateStart
      this.searchParams.dateEnd = this.dateEnd
      this.searchParams.timezone = this.timezone
      this.searchParams.criteria = []
      if (this.statusFilter && this.statusFilter !== 'ALL') {
        this.searchParams.criteria.push({ fieldName: 'status', value: this.statusFilter, matchType: 'TERM' })
      }
      this.isLoading = true
      this.pendingSearch = false
      this.result = {}
      this.axios.post('/ax/refundrequest/search', this.searchParams)
        .then(response => {
          this.isLoading = false
          this.result = response.data
        })
        .catch(error => this.handleError(error) )
    },
    showDetails: function (requestId) {
      this.requestUpdated = false
      this.selectedRequestId = requestId
      this.$bvModal.show('rr-details')
    },
    onDetailsClosed: function () {
      if (this.requestUpdated) {
        this.search()
      }
    },
    onPeriodUpdated: function (eventData) {
      this.pendingSearch = true
      this.dateStart = eventData.startDate
      this.dateEnd = eventData.endDate
      this.timezone = eventData.timezone
      this.auxTz = eventData.auxTz
      this.search(0)
    },
    onDateRangeChanged: function () {
      this.pendingSearch = true
    }
    
  }
}
</script>