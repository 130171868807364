export default {
  data () {
    return {
      procList: [],
      activeProcs: [],
      procGroups: []
    }
  },
  methods: {
    getProcList: function (successFxn) {
      this.axios.get('/ax/tpattribs/procinfolist')
      .then(response => {
        this.procList = response.data
        this.extractActiveProcs()
        this.extractProcGroups()
        if (successFxn) {
          successFxn()
        }
      })
      .catch(error => this.handleError(error))
    },
    extractActiveProcs: function () {
      for (const proc of this.procList) {
        if (proc.active && proc.active === true) {
          this.activeProcs.push(proc)
        }
      }
    },
    extractProcGroups: function () {
      const totalProcs = this.procList.length
      for (let i = 0; i < totalProcs; i++) {
        const proc = this.procList[i]
        if (proc.procGroup) {
          if (!this.procGroups.includes(proc.procGroup)) {
            this.procGroups.push(proc.procGroup)
          }
        }
      }
    },
    getGroupProcs: function (group) {
      var groupProcs = []
      for (const proc of this.procList) {
        if (proc.procGroup === group) {
          groupProcs.push(proc.procId)
        }
      }
      return groupProcs
    }
  }
}
