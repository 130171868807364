<template>
<div class="container">
  <page-title title="Transactions"/>

  <div class="row small mb-2">
    <b-tabs v-model="tabIndex" class="col" content-class="bg-light border border-top-0 px-2" active-nav-item-class="bg-light text-primary fw-bold">
      <b-tab title="Date Range" @click="onTabSelect('dateRange', 0)" class="py-2 px-2">
        <div class="row">
          <div class="col-auto">
            <period-selector :default-period="lastPeriodOpt" :date-range-start="lastDateRangeStart" :date-range-end="lastDateRangeEnd"
                @periodUpdated="onPeriodUpdated" @dateRangeChanged="onDateRangeChanged" />
          </div>
          <div class="col-auto ms-auto">
            <button class="btn btn-info btn-sm pb-2" @click="generateExportFile"><i class="fas fa-file-export" v-b-tooltip.hover title="Generate Export File"></i></button>
          </div>
        </div>
        <div class="row"><small class="col fst-italic fw-bold mt-2 ms-1">Filters:</small></div>
        <div class="row small border py-2 mx-1">
          <input-select v-model="merchantFilter" group-class="col-auto" input-class="form-select-sm">
            <option value="ALL">All Merchants</option>
            <option v-for="merchant in merchantSelectList" :key="merchant.merchantId" :value="merchant.merchantId">{{merchant.name}}</option>
          </input-select>
          <input-select v-model="accountFilter"  group-class="col-auto" input-class="form-select-sm">
            <option value="ALL">All Accounts</option>
            <option v-for="acct in selMerchant.accounts" :key="acct.accountId" :value="acct.accountId">{{acct.accountName}}</option>
          </input-select>
          <input-select v-model="procIdFilter" group-class="col-auto" input-class="form-select-sm">
            <option value="ALL">Any Processor</option>
            <option disabled>--------------</option>
            <option v-for="grp in procGroups" :key="grp" :value="'GRP-' + grp">Group - {{grp}}</option>
            <option disabled>--------------</option>
            <option v-for="proc in activeProcs" :key="proc.procId">{{proc.procId}}</option>
          </input-select>
          <input-select v-model="statusFilter" group-class="col-auto" input-class="form-select-sm">
            <option value="ALL">Any Status</option>
            <option value="PENDING">PENDING</option>
            <option value="CANCELLED">CANCELLED</option>
            <option value="FAILED">FAILED</option>
            <option value="SUCCESS">SUCCESS</option>
          </input-select>
          <input-select v-model="resultCodeFilter" group-class="col-auto" input-class="form-select-sm">
            <option value="ALL">Any Result Code</option>
            <option v-for="(resultCode, index) in procResultCodes" :key="index" :value="resultCode.code">{{resultCode.value}}</option>
          </input-select>
          <input-select v-model="tagsFilter" group-class="col-auto" input-class="form-select-sm">
            <option value="ALL">Any Tag or None</option>
            <option value="CHARGED_BACK REFUNDED REFUNDED_CB">Any Tag</option>
            <option value="CHARGED_BACK">CHARGED_BACK</option>
            <option value="REFUNDED">REFUNDED</option>
            <option value="REFUNDED_CB">REFUNDED_CB</option>
            <option value="REFUND_REQUESTED">REFUND_REQUESTED</option>
          </input-select>
        </div>
      </b-tab>

      <b-tab title="Txn ID(s)" @click="onTabSelect('txnIdList', 1)" class="py-2 px-2">
        <div class="row">
          <textarea rows="5" v-model="searchInput" class="form-control col-auto"></textarea>
        </div>
        <div class="row">
          <button class="btn btn-primary col-auto ms-auto mt-2" @click="search(0)">Go</button>
        </div>
      </b-tab>

      <b-tab title="Payment Ref(s)" @click="onTabSelect('paymentRef', 2)" class="py-2 px-2">
        <div class="row">
          <textarea rows="5" v-model="searchInput" class="form-control col-auto"></textarea>
        </div>
        <div class="row">
          <button class="btn btn-primary col-auto ms-auto mt-2" @click="search(0)">Go</button>
        </div>
      </b-tab>

      <b-tab title="Card Number" @click="onTabSelect('cardNoLast4', 3)" class="py-2 px-2">
        <div class="row input-group">
          <input-text v-model="searchInput" group-class="col-md-4" placeholder="Card Last 4 digits" @keyup.enter="search(0)"/>
          <button class="btn btn-primary btn-sm ms-2 mb-2 col-auto" @click="search(0)">Go</button>
        </div>
      </b-tab>

      <b-tab title="Customer Name" @click="onTabSelect('custName', 4)" class="py-2 px-2">
        <div class="row input-group">
          <input-text v-model="searchFirstName" group-class="col-md-4" placeholder="First Name"/>
          <input-text v-model="searchLastName" group-class="col-md-4" placeholder="Last Name"/>
          <button class="btn btn-primary btn-sm ms-2 mb-2 col-auto" @click="search(0)">Go</button>
        </div>
      </b-tab>

      <b-tab title="Customer Email" @click="onTabSelect('customerEmail', 5)" class="py-2 px-2">
        <div class="row input-group">
          <input-text v-model="searchInput" group-class="col-md-6" placeholder="Email Address" @keyup.enter="search(0)"/>
          <button class="btn btn-primary btn-sm ms-2 mb-2 col-auto" @click="search(0)">Go</button>
        </div>
      </b-tab>

      <b-tab title="Processor Ref(s)" @click="onTabSelect('procRef', 6)" class="py-2 px-2">
        <div class="row">
          <textarea rows="5" v-model="searchInput" class="form-control col-auto"></textarea>
        </div>
        <div class="row">
          <button class="btn btn-primary col-auto ms-auto mt-2" @click="search(0)">Go</button>
        </div>
      </b-tab>

      <b-tab title="Phone No." @click="onTabSelect('phoneNumber', 7)" class="py-2 px-2">
        <div class="row input-group">
          <input-text v-model="searchInput" group-class="col-md-3" placeholder="Phone No." @keyup.enter="search(0)"/>
          <button class="btn btn-primary btn-sm ms-2 mb-2 col-auto" @click="search(0)">Go</button>
        </div>
      </b-tab>

    </b-tabs>
  </div>

  <div class="row">
    <paginated-list :result="result" :search-params="searchParams" :search-fxn="search">
    <table class="table table-striped table-hover small">
      <thead class="table-dark">
        <tr>
          <th>Txn ID</th>
          <th>Create Date</th>
          <th>Status Date</th>
          <th>Merchant</th>
          <th>Ref ID</th>
          <th>Proc ID</th>
          <th>Name / Card</th>
          <th>Amount</th>
          <th>Status</th>
        </tr>
      </thead>
      <tbody>
        <tr v-if="isLoading">
          <td colspan="9" class="text-center text-info fw-bold py-3">
            <b-spinner small variant='info' label='Please wait'></b-spinner>
            <span class="ms-2">  Please wait ... processing</span>
          </td>
        </tr>
        <tr v-if="!pendingSearch && (!result.totalRecs || result.totalRecs === 0)">
          <td colspan="9" class="text-center text-warning fw-bold py-3">No transactions found for specified criteria</td>
        </tr>
        <tr v-for="txn in result.resultList" :key="txn.txnId" @click="showDetails(txn.txnId)" style="cursor: pointer;">
          <td class="text-nowrap">{{txn.txnId}}</td>
          <td class="text-nowrap">
            <display-date :value="txn.txnDate" timezone="UTC" hideyear/><br/>
            <small class="text-info"><display-date :value="txn.txnDate" :timezone="auxTz" hideyear/></small>
          </td>
          <td class="text-nowrap">
            <display-date :value="txn.statusDate" timezone="UTC" hideyear/><br/>
            <small class="text-info"><display-date :value="txn.statusDate" :timezone="auxTz" hideyear/></small>
          </td>
          <td>
            <p class="text-truncate pb-0 mb-0" style="max-width: 10em;">{{txn.merchantName}}</p>
            <p class="text-info py-0 my-0">{{txn.accountId}}</p>
          </td>
          <td><span class="d-inline-block text-truncate" style="max-width: 7em;">{{txn.paymentRef}}</span></td>
          <td class="text-nowrap">{{txn.procId}}</td>
          <td class="text-truncate">
            <p class="text-truncate pb-0 mb-0" style="max-width: 10em;">
              {{txn.customerFirstName ? txn.customerFirstName.charAt(0) : ''}}... {{txn.customerLastName}}
            </p>
            <span class="text-info">{{getCardDisplay(txn.maskedCardNo)}}</span>
          </td>
          <td class="text-end text-nowrap">{{txn.amount | number('0,0.00')}} {{txn.currency}}</td>
          <td>{{txn.status}}
            <p v-if="txn.status === 'FAILED'" class="small text-truncate text-danger fw-bolder pb-0 mb-0" style="max-width: 8em;">{{txn.procStatusText}}</p>
            <template v-if="txn.tags && txn.tags.length > 0">
              <br/>
              <small class="text-danger fw-bolder px-0 py-0" v-for="(tag, index) in txn.tags" :key="index">{{tag}}</small>
            </template>
          </td>
        </tr>
      </tbody>
    </table>
    </paginated-list>
  </div>


  <b-modal id="txn-details" size="lg" hide-footer title="Transaction Details" header-bg-variant="info" @hidden="onDetailModalClosed">
    <txn-detail :txn-id="currentTxnId" showUpdateMenu @dtlChanged="txnDetailUpdated = true"></txn-detail>
  </b-modal>

  <file-exporter ref="txnlistexport"
    apiGenerate="/ax/txnlistexport/generate"
    :paramsForGenerate="searchParams"
    apiRetrieve="/ax/txnlistexport/link/">
  </file-exporter>
</div>
</template>

<script>
import HelpersMixin from '../utils/HelpersMixin.js'
import ProcListMixin from '../utils/ProcListMixin.js'
import TxnDetail from '../views/TxnDetail.vue'
import PeriodSelector from '../components/PeriodSelector.vue'
import PaginatedList from '../components/PaginatedList.vue'
import FileExporter from '../components/FileExporter.vue'
export default {
  name: 'TxnList',
  mixins: [HelpersMixin, ProcListMixin],
  components: {
    TxnDetail, PeriodSelector, FileExporter, PaginatedList
  },
  data () {
    return {
      isLoading: true,
      searchParams: { offset: 0, size: 50, sortField: 'statusDate' },
      result: {},
      dateStart: '',
      dateEnd: '',
      timezone: 'UTC',
      auxTz: this.$localtz,
      currentTxnId: 0,
      searchType: 'dateRange',
      searchInput: '',
      searchFirstName: '',
      searchLastName: '',
      txnDetailUpdated: false,
      statusFilter: 'ALL',
      resultCodeFilter: 'ALL',
      procIdFilter: 'ALL',
      merchantFilter: 'ALL',
      accountFilter: 'ALL',
      tagsFilter: 'ALL',
      procResultCodes: [],
      merchantSelectList: [],
      selMerchant: {},
      tabIndex: 0,
      pendingSearch: false
    }
  },
  computed: {
    lastPeriodOpt() {
      if (this.$store.state.periodOpt) {
        return this.$store.state.periodOpt
      } else {
        return 'LAST3DAYS'
      }
    },
    lastDateRangeStart() {
      if (this.$store.state.searchParams) {
        return this.$store.state.searchParams.dateStart
      } else {
        return null
      }
    },
    lastDateRangeEnd() {
      if (this.$store.state.searchParams) {
        return this.$store.state.searchParams.dateEnd
      } else {
        return null
      }
    }
  },
  mounted () {
    this.getProcResultCodes()
    this.getProcList()
    this.getMerchantList()

    if (this.$store.state.searchParams) {
      this.searchParams = this.$store.state.searchParams
    } else {
      this.searchParams = { offset: 0, size: 50 }
    }

    // note: the onPeriodUpdated function will be triggerred by the PeriodSelector component when it is mounted
    //       which in turn trigger the initial call to search
  },
  watch: {
    searchType: function (newValue, oldValue) {
      this.clearList()
      if (newValue === 'dateRange') {
        this.search()
      } else {
        this.searchInput = ''
        this.searchFirstName = ''
        this.searchLastName = ''
      }
    },
    statusFilter: function () {
      this.search(0)
    },
    resultCodeFilter: function () {
      this.search(0)
    },
    procIdFilter: function () {
      this.search(0)
    },
    merchantFilter: function (newValue, oldValue) {
      if (newValue !== 'ALL') {
        this.selMerchant = this.merchantSelectList.find(me => me.merchantId === newValue);
      } else {
        this.selMerchant = {}
      }
        this.accountFilter = 'ALL'

      this.search(0)
    },
    accountFilter: function() {
      this.search(0)
    },
    tagsFilter: function () {
      this.search(0)
    }
  },
  methods: {
    search: function (offset) {
      if (offset !== null) {
        this.searchParams.offset = offset
      }
      this.searchParams.sortField = 'statusDate'
      this.searchParams.sort = 'DESC'
      if (this.searchType === 'dateRange') {
        this.searchParams.dateStart = this.dateStart
        this.searchParams.dateEnd = this.dateEnd
        this.searchParams.timezone = this.timezone
        this.searchParams.criteria = []
        this.searchParams.subCriterion = {}
        if (this.statusFilter && this.statusFilter !== 'ALL') {
          this.searchParams.criteria.push({ fieldName: 'status', value: this.statusFilter, matchType: 'TERM' })
        }
        if (this.resultCodeFilter && this.resultCodeFilter !== 'ALL') {
          this.searchParams.criteria.push({ fieldName: 'procStatusCode', value: this.resultCodeFilter, matchType: 'TERM' })
        }
        if (this.procIdFilter && this.procIdFilter !== 'ALL') {
          if (this.procIdFilter.startsWith('GRP-')) {
            const groupCode = this.procIdFilter.substring(4)
            var subCriteria = []
            for (const proc of this.getGroupProcs(groupCode)) {
              subCriteria.push({ fieldName: 'procId.keyword', value: proc, matchType: 'SHOULD' })
            }
            this.searchParams.subCriterion.matchType = 'MATCH'
            this.searchParams.subCriterion.criteria = subCriteria
          } else {
            this.searchParams.criteria.push({ fieldName: 'procId', value: this.procIdFilter, matchType: 'TERM' })
          }
        }
        if (this.merchantFilter && this.merchantFilter !== 'ALL') {
          this.searchParams.criteria.push({ fieldName: 'merchantId', value: this.merchantFilter, matchType: 'TERM' })
        }
        if (this.accountFilter && this.accountFilter !== 'ALL') {
          this.searchParams.criteria.push({ fieldName: 'accountId', value: this.accountFilter, matchType: 'TERM' })
        }
        if (this.tagsFilter && this.tagsFilter !== 'ALL') {
          this.searchParams.criteria.push({ fieldName: 'tags', value: this.tagsFilter, matchType: 'MATCH' })
        }
      } else {
        this.searchParams.dateStart = null
        this.searchParams.dateEnd = null
        this.searchParams.criteria = []
        if (this.searchType === 'custName') {
          if (this.searchFirstName) {
            this.searchParams.criteria.push({ fieldName: 'customerFirstName', value: this.searchFirstName.toLowerCase() + '.*', matchType: 'REGEX' })
          }
          if (this.searchLastName) {
            this.searchParams.criteria.push({ fieldName: 'customerLastName', value: this.searchLastName.toLowerCase() + '.*', matchType: 'REGEX' })
          }
        } else if (this.searchType === 'cardNoLast4') {
          this.searchParams.criteria.push({ fieldName: 'maskedCardNo', value: '*' + this.searchInput, matchType: 'MATCH' })
        } else if (this.searchType === 'txnIdList') {
          this.searchParams.criteria.push({ fieldName: 'txnId', value: this.searchInput, matchType: 'INLIST' })
          this.searchParams.sortField = 'txnId'
          this.searchParams.sort = 'ASC'
        } else if (this.searchType === 'paymentRef') {
          this.searchParams.criteria.push({ fieldName: 'paymentRef.keyword', value: this.searchInput, matchType: 'INLIST' })
          this.searchParams.sortField = 'paymentRef.keyword'
          this.searchParams.sort = 'ASC'
        } else if (this.searchType === 'procRef') {
          this.searchParams.criteria.push({ fieldName: 'procRef.keyword', value: this.searchInput, matchType: 'INLIST' })
          this.searchParams.sortField = 'procRef.keyword'
          this.searchParams.sort = 'ASC'
        } else if (this.searchType === 'customerEmail') {
          this.searchParams.criteria.push({ fieldName: this.searchType, value: this.searchInput, matchType: 'MATCHPHRASE' })
        } else if (this.searchType === 'phoneNumber') {
            this.searchParams.criteria.push({ fieldName: this.searchType, value: '.*' + this.searchInput + '.*', matchType: 'REGEX' })
        } else {
          this.searchParams.criteria.push({ fieldName: this.searchType, value: this.searchInput, matchType: 'MATCH' })
        }
      }

      this.isLoading = true
      this.pendingSearch = false
      this.result = {}
      this.axios.post('/ax/paytxns', this.searchParams)
        .then(response => {
          this.isLoading = false
          this.result = response.data
          if (this.searchType === 'dateRange') {
            this.$store.commit('saveSearchParams', this.searchParams)
          }
        })
        .catch(error => {
          this.isLoading = false
          this.handleError(error)
        })
    },
    showDetails: function (txnId) {
      this.currentTxnId = txnId
      this.$bvModal.show('txn-details')
    },
    getCardDisplay: function (card) {
      var i = card.length
      if (i > 4) {
        return card.substring(0, 1) + ' *** ' + card.substring(i - 4, i)
      } else {
        return card
      }
    },
    clearList: function () {
      this.result = {}
    },
    generateExportFile: function () {
      this.$refs.txnlistexport.generate()
    },
    getProcResultCodes: function () {
      this.axios.get('/ax/procresultcodes')
      .then(response => {
        this.procResultCodes = response.data
      })
      .catch(error => this.handleError(error))
    },
    getMerchantList: function () {
      this.axios.get('/ax/merchants')
      .then(response => {
        this.merchantSelectList = response.data
        this.merchantSelectList.sort((m1, m2) => (m1.name > m2.name) ? 1 : -1 )
        this.selMerchant = {}
      })
      .catch(error => this.handleError(error))
    },
    onTabSelect: function (selected, idx) {
      this.tabIndex = idx
      this.searchType = selected
      this.searchInput = ''
      this.result = {}
      if (selected === 'dateRange') {
        this.searchParams = this.$store.state.searchParams
      } else {
        this.searchParams = { offset: 0, size: 50 }
      }
    },
    onDetailModalClosed: function () {
      if (this.txnDetailUpdated) {
        this.search()
      }
    },
    onPeriodUpdated: function (eventData) {
      this.pendingSearch = true
      this.$store.commit('savePeriodOpt', eventData.periodOpt)
      this.dateStart = eventData.startDate
      this.dateEnd = eventData.endDate
      this.timezone = eventData.timezone
      this.auxTz = eventData.auxTz
      this.search(0)
    },
    onDateRangeChanged: function () {
      this.pendingSearch = true
    }

  }
}
</script>
