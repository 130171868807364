<template>
<div class="row justify-content-center mt-5">
  <div class="text-center alert alert-success" v-if="changeSuccess">
    Your password has been successfully changed
  </div>
  <div class="col-md-5" v-else>
    <h5 class="text-center">Change Password</h5>
    <div class="alert alert-danger" v-if="error.hasError">{{error.errorMsg}}</div>
    <div class="card">
      <div class="card-body">
        <form @submit.prevent="onSubmit" action="">
          <div class="mb-2">
            <label>Current Password</label>
            <input type="password" class="form-control" v-model="currentPw"/>
          </div>
          <div class="mb-2">
            <label>New Password</label>
            <input type="password" class="form-control" v-model="newPw1"/>
          </div>
          <div class="mb-2">
            <label>Retype New Password</label>
            <input type="password" class="form-control" v-model="newPw2"/>
          </div>
          <div class="text-end">
            <button type="submit" class="btn btn-primary">Submit</button>
          </div>
        </form>
      </div>
    </div>
  </div>
</div>
</template>

<script>
export default {
  name: 'AdminPwChange',
  data () {
    return {
      currentPw: '',
      newPw1: '',
      newPw2: '',
      error: { hasError: false, errorMsg: '' },
      changeSuccess: false
    }
  },
  methods: {
    onSubmit: function () {
      this.error.hasError = false
      this.error.errorMsg = ''
      var formData = new FormData()
      formData.append('currentPw', this.currentPw)
      formData.append('newPw1', this.newPw1)
      formData.append('newPw2', this.newPw2)
      this.axios.post('/ax/submitpwchange', formData, { headers: { 'Content-Type': 'application/x-www-form-urlencoded', 'ccpgadmin-authz': this.$store.state.token.tokenId } })
        .then(response => {
          console.log(response.data)
          this.currentPw = ''
          this.newPw1 = ''
          this.newPw2 = ''
          this.changeSuccess = true
        })
        .catch(error => {
          console.log(error.response.status + ': ' + error.response.data)
          this.error.hasError = true
          this.error.errorMsg = error.response.data.msgText
        })
    }
  }
}
</script>

<style scoped>
label { font-size: 75%; margin-bottom: 1px; font-weight: 600; }
</style>
