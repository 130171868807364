<template>
<div>
  <div class="card">
    <div class="card-body">
      <div class="w-50">
        <h5 v-if="isUpdate" class="mb-4">{{rule.ruleId}}</h5>
        <input-text v-else label="Rule ID" v-model="rule.ruleId"
          :errors="errors" error-key="ruleId" :focus="isCreate"></input-text>
        <input-number label="Priority" v-model="rule.priority"
          :errors="errors" error-key="priority" :focus="isUpdate"></input-number>
        <input-text label="Processor ID" v-model="rule.routeToProcId"
          :errors="errors" error-key="routeToProcId"></input-text>
        <input-switch v-model="rule.skipBalancing" label="Skip Balancing"/>
      </div>
    </div>
  </div>
  <div class="mt-2">
    <table class="table table-sm">
      <thead class="table-dark small">
        <tr>
          <th width="30%">Attribute Name</th>
          <th width="10%">&nbsp;</th>
          <th width="55%">Condition Value</th>
          <th width="5%">&nbsp;</th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="(cond, index) in conds" v-bind:key="index">
          <td><input class="form-control form-control-sm" type="text" v-model="cond.attrName"/></td>
          <td>
            <select class="form-control form-control-sm" v-model="cond.condType">
              <option value="EQ">Equals</option>
              <option value="NE">Not Equals</option>
              <option value="LT">Less Than</option>
              <option value="LE">Less or Equal</option>
              <option value="GT">Greater Than</option>
              <option value="GE">Greater or Equal</option>
              <option value="STARTSWITH">Starts With</option>
              <option value="CONTAINS">Contains</option>
            </select>
          </td>
          <td><input class="form-control form-control-sm" type="text" v-model="cond.condValue"/></td>
          <td class="text-center"><a href="#"><i class="fas fa-trash-alt" v-on:click="onDelCond(index)"></i></a></td>
        </tr>
        <tr>
          <td colspan="3">&nbsp;</td>
          <td class="text-center"><a href="#"><i class="fas fa-plus" v-on:click="onAddCond()"></i></a></td>
        </tr>
      </tbody>
    </table>
  </div>
  <div class="text-end">
    <button class="btn btn-secondary me-2" v-on:click="onCancel()">Cancel</button>
    <button class="btn btn-primary" v-on:click="onSave()">Save</button>
  </div>
</div>
</template>

<script>
import { ErrorsContainer } from '../utils/ErrorsContainer.js'
export default {
  name: 'RoutingRuleForm',
  props: {
    ruleProp: Object
  },
  data () {
    return {
      boolTrue: true,
      rule: {},
      conds: [],
      mode: 'create',
      errors: new ErrorsContainer()
    }
  },
  computed: {
    isUpdate: function () {
      if (this.mode === 'update') {
        return true
      }
      return false
    },
    isCreate: function () {
      if (this.mode === 'create') {
        return true
      }
      return false
    }
  },
  mounted () {
    if (this.ruleProp) {
      if (this.ruleProp.ruleId) {
        this.mode = 'update'
      } else {
        this.mode = 'create'
      }
      Object.assign(this.rule, this.ruleProp)
      this.conds = this.rule.conditions
    } else {
      this.mode = 'create'
    }
  },
  methods: {
    onAddCond: function () {
      this.conds.push({ attrName: '', condType: 'EQ', condValue: '' })
    },
    onDelCond: function (idx) {
      this.conds.splice(idx, 1)
    },
    onSave: function () {
      this.rule.conditions = this.conds
      this.errors.clearAll()
      if (this.mode === 'create') {
        this.saveCreate()
      } else {
        this.saveUpdate()
      }
    },
    saveCreate: function () {
      this.axios.put('/ax/procroutingrules', this.rule)
        .then(response => {
          this.$emit('saved')
        })
        .catch(error => this.handleError(error))
    },
    saveUpdate: function () {
      this.axios.post('/ax/procroutingrules/' + this.rule.ruleId, this.rule)
        .then(response => {
          this.$emit('saved')
        })
        .catch(error => this.handleError(error))
    },
    handleError: function (error) {
      if (error.response.status === 422) {
        if (error.response.data.fieldErrors) {
          this.errors.setFldErrors(error.response.data.fieldErrors)
        } else {
          this.errors.addError('ruleId', error.response.data.msgText)
        }
      }
    },
    onCancel: function () {
      this.$emit('cancelled')
    }
  }
}
</script>
