<template>
<div>
  <div class="row">
    <router-link to="/merchants" class="col-md-12 small text-info ms-0 ps-0"><i class="fas fa-arrow-left"></i> Back to List</router-link>
  </div>
  <div class="row justify-content-center mb-3">
    <h5 class="col-12 text-center">Merchant Details</h5>
  </div>
  <div class="row">
    <div class="card w-100">
      <div class="card-header">
        <ul class="nav nav-tabs card-header-tabs">
          <li class="nav-item"><a class="nav-link" :class="{ active: isActive(1) }" v-on:click="tabNum = 1">Basic Info</a></li>
          <li class="nav-item"><a class="nav-link" :class="{ active: isActive(2) }" v-on:click="tabNum = 2">Accounts</a></li>
          <li class="nav-item"><a class="nav-link" :class="{ active: isActive(3) }" v-on:click="tabNum = 3">Users</a></li>
          <li class="nav-item"><a class="nav-link" :class="{ active: isActive(4) }" v-on:click="tabNum = 4">Reports</a></li>
        </ul>
      </div>
      <div class="card-body">
        <merchant-basic-info :merchant-id='merchantId' v-if="tabNum === 1"></merchant-basic-info>
        <merchant-accounts :merchant-id='merchantId' v-if="tabNum === 2"></merchant-accounts>
        <merchant-users :merchant-id='merchantId' v-if="tabNum === 3"></merchant-users>
        <merchant-reports :merchant-id='merchantId' v-if="tabNum === 4"></merchant-reports>
      </div>
    </div>
  </div>
</div>
</template>

<script>
import MerchantBasicInfo from '../components/MerchantBasicInfo.vue'
import MerchantUsers from '../components/MerchantUsers.vue'
import MerchantAccounts from '../components/MerchantAccounts.vue'
import MerchantReports from '../components/MerchantReports.vue'
export default {
  name: 'MerchantDetail',
  components: {
    MerchantBasicInfo,
    MerchantUsers,
    MerchantAccounts,
    MerchantReports
  },
  props: ['merchantId'],
  data () {
    return {
      tabNum: 1
    }
  },
  methods: {
    isActive: function (n) {
      if (n === this.tabNum) {
        return true
      }
      return false
    }
  }
}
</script>

<style scoped>
dt { font-size: .8em; padding-top: .2em; font-weight: 600;}
.nav-item { cursor: pointer; }
</style>
