<template>
<div>
  <div class="row border border-info bg-light py-2">
    <div class="col-auto small"><a  href="#" @click="clearSelection()">Clear Selection</a></div>
    <div class="col-auto ms-auto my-0 py-0" v-if="procGroups.length > 0">
      <input-select v-model="procGroup" group-class="my-0 py-0" input-class="form-control-sm">
        <option value="none">Select Group</option>
        <option v-for="grp in procGroups" v-bind:key="grp">{{grp}}</option>
      </input-select>
    </div>
  </div>
    <div class="row border border-info pt-3">
      <div class="col-lg-3 col-sm-6">
        <ul>
          <li class="form-check" v-for="px in procsCol1" v-bind:key="px.procId">
            <input class="form-check-input" type="checkbox" :value="px.procId" v-model="procIds">
            <label class="form-check-label">{{px.procId}}</label>
          </li>
        </ul>
      </div>
      <div class="col-lg-3 col-sm-6">
        <ul>
          <li class="form-check" v-for="px in procsCol2" v-bind:key="px.procId">
            <input class="form-check-input" type="checkbox" :value="px.procId" v-model="procIds">
            <label class="form-check-label">{{px.procId}}</label>
          </li>
        </ul>
      </div>
      <div class="col-lg-3 col-sm-6">
        <ul>
          <li class="form-check" v-for="px in procsCol3" v-bind:key="px.procId">
            <input class="form-check-input" type="checkbox" :value="px.procId" v-model="procIds">
            <label class="form-check-label">{{px.procId}}</label>
          </li>
        </ul>
      </div>
      <div class="col-lg-3 col-sm-6">
        <ul>
          <li class="form-check" v-for="px in procsCol4" v-bind:key="px.procId">
            <input class="form-check-input" type="checkbox" :value="px.procId" v-model="procIds">
            <label class="form-check-label">{{px.procId}}</label>
          </li>
        </ul>
      </div>
    </div>
</div>
</template>

<script>
import HelpersMixin from '../utils/HelpersMixin.js'
import ProcListMixin from '../utils/ProcListMixin.js'
export default {
  name: 'ProcMultiSelect',
  mixins: [HelpersMixin, ProcListMixin],
  data () {
    return {
      procIds: [],
      procsCol1: [],
      procsCol2: [],
      procsCol3: [],
      procsCol4: [],
      procGroup: 'none',
      groupList: []
    }
  },
  mounted () {
    this.getProcList(this.arrangeProcList)
  },
  watch: {
    procGroup: function () {
      this.selectGroup()
    }
  },
  methods: {
    arrangeProcList: function () {
      const totalProcs = this.activeProcs.length
      var maxPerCol = Math.ceil(this.procList.length / 4)
      if (maxPerCol < 8) {
        maxPerCol = 8
      }
      for (let i = 0; i < totalProcs; i++) {
        if (i < maxPerCol) {
          this.procsCol1.push(this.activeProcs[i]) 
        } else if (i < (maxPerCol * 2)) {
          this.procsCol2.push(this.activeProcs[i]) 
        } else if (i < (maxPerCol * 3)) {
          this.procsCol3.push(this.activeProcs[i]) 
        } else {
          this.procsCol4.push(this.activeProcs[i]) 
        }
      }
    },
    clearSelection: function () {
      this.procIds.splice(0, this.procIds.length)
      this.procGroup = 'none'
    },
    selectGroup: function () {
      this.procIds.splice(0, this.procIds.length)
      if (!this.procGroup || this.procGroup === 'none') {
        return
      }
      for (let i = 0; i < this.procList.length; i++) {
        if (this.procList[i].procGroup === this.procGroup) {
          this.procIds.push(this.procList[i].procId)
        }
      }
    },
    getSelectedProcs: function () {
      return this.procIds
    }
    
  }
}
</script>
