<template>
<div class="container">
  <div class="row">
    <div class="col-auto ms-auto">
      <router-link :to="'/merchantform/' + merchantId"><i class="fas fa-edit"></i></router-link>
    </div>
  </div>
  
  <div class="row">
    <display-field label="Merchant ID" group-class="col-6"><span class="fw-bold">{{mx.merchantId}}</span></display-field>
    <display-field label="Status" group-class="col-6">{{mx.status}}</display-field>
  </div>
  <div class="row">
    <display-field label="Merchant Alias" group-class="col-6"><span class="fw-bold">{{mx.merchantAlias}}</span></display-field>
    <display-field label="Merchant Name" group-class="col-6">{{mx.name}}</display-field>
  </div>
  <div class="row">
    <display-field label="API Key" group-class="col">{{mx.apiKey}}&nbsp;
      <span class="ps-3 text-info"><a @click="$bvModal.show('regen-form')"><i class="fas fa-sync"></i></a></span>
    </display-field>
  </div>
  <div class="row">
    <display-field label="Address" group-class="col-6">
      <span v-if="mx.address && mx.address.addressLine1">
        {{mx.address.addressLine1}} {{mx.address.addressLine2}}<br />
        {{mx.address.cityTown}}, {{mx.address.stateProv}}<br />
        {{mx.address.postalCode}} {{mx.address.country}}
      </span>&nbsp;
    </display-field>
    <display-field label="Contact" group-class="col-6">
      <span v-if="mx.contactName">{{mx.contactName}}<br/></span>
      <span v-if="mx.contactEmail">{{mx.contactEmail}}<br/></span>
      <span v-if="mx.contactPhone">{{mx.contactPhone}}</span>
    </display-field>
  </div>
  <div class="row">
    <display-field label="Web Site" group-class="col">{{mx.webSite}}</display-field>
  </div>
  <div class="row">
    <display-field label="Default Result URL" group-class="col">{{mx.defaultResultUrl}}</display-field>
  </div>
  <div class="row">
    <display-field label="Default Callback URL" group-class="col">{{mx.defaultCallbackUrl}}</display-field>
  </div>
  <div class="row">
    <display-field label="Notification" group-class="col-4">{{mx.notifyMerchant ? 'ENABLED' : 'DISABLED'}}</display-field>
    <display-field label="Weekly Reports" group-class="col-4">{{mx.weeklyReports ? 'ENABLED' : 'DISABLED'}}</display-field>
    <display-field label="Check Order Fail Velocity" group-class="col-4">{{mx.checkOrderFailVelocity ? 'ENABLED' : 'DISABLED'}}</display-field>
  </div>
  <div class="row">
    <display-field label="Created On" group-class="col-4">
      <display-date :value="mx.createDate"/>
    </display-field>
    <display-field label="Last Update" group-class="col-4">
      <display-date :value="mx.lastUpdate"/>
      <span v-if="mx.lastUpdateBy"> by {{mx.lastUpdateBy}}</span>&nbsp;
    </display-field>
    <display-field label="Company" group-class="col-4">{{mx.company}}</display-field>
  </div>
  
  <b-modal id="regen-form" title="Generate New API Key" hide-footer>
    <div class="text-center text-warning">
      Are you sure you want to generate a new API Key?
    </div>
    <div class="text-end mt-3">
      <button class="btn btn-secondary m-2" @click="$bvModal.hide('regen-form')">Cancel</button>
      <button class="btn btn-primary m-2" @click="submitApiKeyRegen()">Proceed</button>
    </div>
  </b-modal>
</div>
</template>

<script>
export default {
  name: 'MerchantBasicInfo',
  props: ['merchantId'],
  data () {
    return {
      mx: {}
    }
  },
  mounted () {
    this.getMerchantDetail()
  },
  methods: {
    getMerchantDetail: function () {
      this.axios.get('/ax/merchant/' + this.merchantId)
        .then(response => {
          this.mx = response.data
        })
        .catch(error => {
          console.log(error.response.status)
        })
    },
    submitApiKeyRegen: function () {
      console.log('calling regen')
      this.axios.post('/ax/merchant/' + this.merchantId + '/generateapikey', '')
        .then(response => {
          this.mx.apiKey = response.data.apiKey
          this.$bvModal.hide('regen-form')
        })
        .catch(error => {
          console.log(error.response.status)
          this.$bvModal.hide('regen-form')
        })
    }
  }
}
</script>

<style scoped>
dt { font-size: .8em; padding-top: .2em; font-weight: 600;}
dd { border-bottom: 1px inset; }
</style>
