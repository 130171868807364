<template>
<div class="row">
  <div class="d-flex flex-row w-100  small mb-2 font-italic text-info">
    <div class="ms-2">Total no. of reports: {{reports.totalRecs}}</div>
    <div class="me-2 ms-auto">
      <span v-if="hasPrev()">
        <a class="me-2" @click.prevent="showFirst()" href="#"><i class="fas fa-angle-double-left" title="first page"></i></a>
        <a class="me-2" @click.prevent="showPrev()" href="#"><i class="fas fa-angle-left" title="previous page"></i></a>
      </span>
      <span>Page {{reports.currentPage}} of {{reports.totalPages}}</span>
      <span v-if="hasNext()">
        <a class="ms-2" @click.prevent="showNext()" href="#"><i class="fas fa-angle-right" title="next page"></i></a>
        <a class="ms-2" @click.prevent="showLast()" href="#"><i class="fas fa-angle-double-right" title="last page"></i></a>
      </span>
      <span v-else class="ms-4 ps-1">&nbsp;</span>
      <a href="#" @click="showFirst" class="me-2 ms-3 text-info" v-b-tooltip.hover title="Refresh"><i class="fas fa-sync"></i></a>
    </div>
  </div>
  <div class="col-12 justify-content-center" v-if="reports">
    <table class="table table-striped small">
      <thead class="table-dark">
        <tr>
          <th>Report ID</th>
          <th>Request Date</th>
          <th>Requested By</th>
          <th class="text-center">Description</th>
          <th>&nbsp;</th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="(rpt, idx) in reports.resultList" :key="idx">
          <td>{{rpt.reportId}}</td>
          <td>
            <display-date :value="rpt.requestDate" timezone="UTC"/>
             <small class="text-info"><display-date :value="rpt.requestDate" :timezone="$localtz"/></small>
          </td>
          <td>{{rpt.requestedBy}}</td>
          <td>
            {{rpt.description}}<br/>
            <small class="text-info">({{rpt.fileName}})</small>
          </td>
          <td><a @click.prevent="downloadReport(rpt)" style="cursor: pointer;"><i class="fa fa-download"></i></a></td>
        </tr>
      </tbody>
    </table>
  </div>
  <div class="col-12 text-center" v-else>
    <div class="text-warning fw-bold">No reports available</div>
  </div>
  <div class="d-none">
    <a ref="downloadLink"></a>
  </div>
</div>
</template>

<script>
export default {
  name: 'MerchantReports',
  props: ['merchantId'],
  data () {
    return {
      reports: {},
      offset: 0,
      limit: 50
    }
  },
  mounted () {
    this.getReportsList()
  },
  methods: {
    getReportsList: function () {
      this.axios.get('/ax/txnreports/' + this.merchantId + '/' + this.offset + '/' + this.limit)
        .then(response => {
          this.reports = response.data
        })
        .catch(error => {
          console.log(error.response.status)
        })
    },
    downloadReport: function (rpt) {
      this.axios.get('/ax/report/link/' + rpt.reportId)
      .then(response => {
        if (response.data) {
          const s3Url = response.data

          this.axios.get(s3Url, { responseType: 'blob' })
            .then(response => {
              const url = URL.createObjectURL(new Blob([response.data], { type: 'blob' }))
              var link = this.$refs.downloadLink
              link.href = url
              link.setAttribute('download', rpt.fileName)
              link.click()
            })
        }
      })
      .catch(error => {
        console.log(error.response.status)
      })
    },
    hasPrev: function () {
      return this.reports.currentPage > 1
    },
    hasNext: function () {
      return this.reports.currentPage < this.reports.totalPages
    },
    showPrev: function () {
      this.offset -= this.limit
      this.getReportsList()
    },
    showNext: function () {
      this.offset += this.limit
      this.getReportsList()
    },
    showFirst: function () {
      this.offset = 0
      this.getReportsList()
    },
    showLast: function () {
      this.offset = (this.reports.totalPages - 1) * this.limit
      this.getReportsList()
    }
  }
}
</script>
