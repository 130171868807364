<template>
<div class="form-group" :class="groupClass">
  <label v-if="label">{{label}}</label>
  <div class="input-group">
    <div class="input-group-prepend" v-if="prepend">
      <span class="input-group-text">{{prepend}}</span>
    </div>
    <input class="form-control" type="number" step="any" ref="inputelem"
      :placeholder="placeholder"
      v-model="inputData"
      :class="[{'is-invalid': hasError()}, inputClass]"/>
    <div class="input-group-append" v-if="append">
      <span class="input-group-text">{{append}}</span>
    </div>
  </div>
  <small class="invalid-feedback" v-if="hasError()">{{errorMsg}}</small>
</div>
</template>

<script>
export default {
  name: 'InputNumber',
  props: {
    label: String,
    value: [String, Number],
    inputClass: String,
    groupClass: String,
    placeholder: String,
    focus: Boolean,
    prepend: String,
    append: String,
    errors: { type: Object, default: () => ({}) },
    errorKey: String
  },
  computed: {
    inputData: {
      get () {
        return this.value
      },
      set (val) {
        this.$emit('input', val)
      }
    }
  },
  mounted () {
    if (this.focus) {
      this.$refs.inputelem.focus()
    }
  },
  data () {
    return {
      errorMsg: ''
    }
  },
  methods: {
    hasError: function () {
      if (this.errors.fldErrors && this.errorKey) {
        const err = this.errors.getError(this.errorKey)
        if (err) {
          this.errorMsg = err.defaultMessage
          return true
        }
      }
      return false
    }
  }
}
</script>

<style scoped>
label { font-size: 75%; margin-bottom: 1px; font-weight: 600; }
::placeholder { font-size: 75%; color: #999; }
:-ms-input-placeholder { font-size: 75%; color: #999; }
::-ms-input-placeholder { font-size: 75%; color: #999; }
/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}
</style>
