<template>
<div>
  <div class="row">
    <h5 class="col text-center mb-3 ms-auto me-auto">Settlement Reports</h5>
  </div>
  
  <div class="row mb-3">
    <div class="col-auto ms-auto small">
      <a href="#" @click.prevent="showSettlementInputForm"><i class="fas fa-plus-square"></i> Generate New Report</a>
    </div>
  </div>
  
  <div class="d-flex flex-row w-100  small mb-2 font-italic text-info">
    <div class="ms-2">Total no. of reports: {{result.totalRecs}}</div>
    <div class="me-2 ms-auto">
      <span v-if="hasPrev()">
        <a class="me-2" @click.prevent="showFirst()" href="#"><i class="fas fa-angle-double-left" title="first page"></i></a>
        <a class="me-2" @click.prevent="showPrev()" href="#"><i class="fas fa-angle-left" title="previous page"></i></a>
      </span>
      <span>Page {{result.currentPage}} of {{result.totalPages}}</span>
      <span v-if="hasNext()">
        <a class="ms-2" @click.prevent="showNext()" href="#"><i class="fas fa-angle-right" title="next page"></i></a>
        <a class="ms-2" @click.prevent="showLast()" href="#"><i class="fas fa-angle-double-right" title="last page"></i></a>
      </span>
      <span v-else class="ms-4 ps-1">&nbsp;</span>
      <a href="#" @click="showFirst" class="me-2 ms-3 text-info" v-b-tooltip.hover title="Refresh"><i class="fas fa-sync"></i></a>
    </div>
  </div>
  <div class="row">
    <table class="table table-striped">
      <thead class="table-dark">
        <tr>
          <th>Date Generated</th>
          <th>Merchant ID</th>
          <th>Description</th>
          <th>&nbsp;</th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="rpt in result.resultList" :key="rpt.reportId">
          <td class="text-nowrap">
            {{rpt.requestDate | moment("timezone", 'UTC', "YYYY MMM DD - HH:mm zz")}}<br/>
            <small class="text-info">{{rpt.requestDate | moment("timezone", $localtz, "YYYY MMM DD - HH:mm zz")}}</small>
          </td>
          <td>{{rpt.merchantId}}</td>
          <td>{{rpt.description}}<br/><small class="text-info">({{rpt.fileName}})</small></td>
          <td class="text-nowrap">
            <a href="#" @click.prevent="downloadReport(rpt)"><i class="fa fa-download"></i></a>
            <a href="#" @click.prevent="confirmDelete(rpt)"><i class="fa fa-trash text-danger ms-2"></i></a>
          </td>
        </tr>
      </tbody>
    </table>
  </div>

  <div class="d-none">
    <a ref="downloadLink"></a>
  </div>
  
  <b-modal id="settlement-input-form" title="Generate Settlement Report" size="lg" hide-footer no-close-on-backdrop>
    <div>
      <form>
        <fieldset :disabled="formDisabled">
        <div class="row mb-1">
          <div class="col-lg-4 col-form-label">Transactions Date Range</div>
          <input-date v-model="txnDateStart" group-class="col-auto" placeholder="yyyy-MM-dd"></input-date>
          <input-date v-model="txnDateEnd" group-class="col-auto" placeholder="yyyy-MM-dd"></input-date>
        </div>
        <div class="row mb-4">
          <div class="col-lg-4 col-form-label">CBs/Refunds Date Range</div>
          <input-date v-model="adjDateStart" group-class="col-auto" placeholder="yyyy-MM-dd"></input-date>
          <input-date v-model="adjDateEnd" group-class="col-auto" placeholder="yyyy-MM-dd"></input-date>
        </div>
        <hr/>
        
        <div class="row">
          <input-select label="Merchant" v-model="selMxIdx" placeholder="select merchant" group-class="col-auto">
            <option v-for="(mx, idx) in merchants" :key="idx" :value="idx.toString()">{{mx.name}}</option>
          </input-select>
          
          <div v-if="formDisabled" class="col-auto ms-auto text-center text-primary mt-3">
            <b-spinner variant='primary' label='Please wait'></b-spinner>
            <span class="ms-3 mt-1">Processing ... Please wait</span>
          </div>
          <div v-else class="col-auto ms-auto mt-3">
            <button class="btn btn-secondary me-2" @click="$bvModal.hide('settlement-input-form')">Cancel</button>
            <button class="btn btn-primary" @click.self.prevent="onGenerateReport">Generate Report</button>
          </div>
        </div>
        
        <hr class="mt-4"/>
        
        <div class="row mt-2" v-if="selMxIdx != -1">
          <div class="col-auto">
            <input class="form-check-input" type="checkbox" value="true" v-model="selAllAccts">
            <label class="form-check-label ms-2"> All Accounts</label>
          </div>
          <input-select v-model="procIdFilter" class="col-auto ms-auto">
            <option value="ALL">Any Processor</option>
            <option v-for="proc in activeProcs" :key="proc.procId">{{proc.procId}}</option>
          </input-select>
        </div>
        <div class="row" v-if="selMxIdx != -1">
          <ul class="col-auto">
            <li class="form-check" v-for="acct in selMerchant.accounts" :key="acct.accountId">
              <input class="form-check-input" type="checkbox" :value="acct.accountId" v-model="acctIdList" :disabled="selAllAccts">
              <label class="form-check-label">{{acct.accountId}} -  {{acct.accountName}}</label>
            </li>
          </ul>
        </div>
        </fieldset>
      </form>
    </div>
  </b-modal>
</div>
</template>

<script>
import moment from 'moment'
import HelpersMixin from '../utils/HelpersMixin.js'
import ProcListMixin from '../utils/ProcListMixin.js'
export default {
  name: 'MercSettlementReports',
  mixins: [HelpersMixin, ProcListMixin],
  data () {
    return {
      isLoading: false,
      searchParams: { 
        offset: 0, 
        size: 50, 
        sortField: 'requestDate',
        sort: 'DESC',
        criteria: [{ fieldName: 'reportType', value: 'SETTLEMENT', matchType: 'MATCH' }] },
      formDisabled: false,
      txnDateStart: '',
      txnDateEnd: '',
      adjDateStart: '',
      adjDateEnd: '',
      merchants: [],
      selMerchant: {},
      selAccountId: 'ALL',
      selMxIdx: '-1',
      pollCount: 0,
      selAllAccts: true,
      acctIdList: [],
      procIdFilter: 'ALL',
      result: {}
    }
  },
  watch: {
    selMxIdx: function (newValue) {
      this.selMerchant = this.merchants[newValue]
      this.selAccountId = 'ALL'
    },
    selAllAccts: function (newValue) {
        if (newValue) {
            this.acctIdList = []
        }
    }
  },
  mounted () {
    this.getMerchants()
    this.getReports()
    this.getProcList()
    var baseMoment = moment().utc().subtract(7, 'days')
    this.txnDateStart = baseMoment.startOf('isoweek').format('YYYY-MM-DD')
    this.txnDateEnd = baseMoment.endOf('isoweek').format('YYYY-MM-DD')
    this.adjDateStart = baseMoment.startOf('isoweek').format('YYYY-MM-DD')
    this.adjDateEnd = baseMoment.endOf('isoweek').format('YYYY-MM-DD')
  },
  methods: {
    getReports: function () {
      this.axios.post('/ax/reports', this.searchParams)
      .then(response => {
        this.result = response.data
      })
      .catch(error => this.handleError(error))
    },
    downloadReport: function (rpt) {
      this.axios.get('/ax/report/link/' + rpt.reportId)
      .then(response => {
        if (response.data) {
          const s3Url = response.data

          this.axios.get(s3Url, { responseType: 'blob' })
            .then(response => {
              const url = URL.createObjectURL(new Blob([response.data], { type: 'blob' }))
              var link = this.$refs.downloadLink
              link.href = url
              link.setAttribute('download', rpt.fileName)
              link.click()
            })
        }
      })
      .catch(error => this.handleError(error))
    },
    hasPrev: function () {
      return this.result.currentPage > 1
    },
    hasNext: function () {
      return this.result.currentPage < this.result.totalPages
    },
    showPrev: function () {
      this.searchParams.offset -= this.searchParams.limit
      this.getReports()
    },
    showNext: function () {
      this.searchParams.offset += this.searchParams.limit
      this.getReports()
    },
    showFirst: function () {
      this.searchParams.offset = 0
      this.getReports()
    },
    showLast: function () {
      this.searchParams.offset = (this.result.totalPages - 1) * this.searchParams.limit
      this.getReports()
    },
    getMerchants: function () {
      this.axios.get('/ax/merchants')
        .then(response => {
          this.merchants = response.data
          this.merchants.sort((m1, m2) => (m1.name > m2.name) ? 1 : -1 )
          this.selMxIdx = '-1'
          this.selMerchant = { accounts: [] }
          this.selAccountId = 'ALL'
        })
        .catch(error => this.handleError(error))
    },
    getMerchantList: function () {
      this.axios.get('/ax/merchant/codevaluelist')
        .then(response => {
          this.merchantSelectList = response.data
        })
        .catch(error => this.handleError(error))
    },
    showSettlementInputForm: function () {
      this.formDisabled = false
      this.$bvModal.show('settlement-input-form')
    },
    onGenerateReport: function () {
      if (!this.selMerchant || !this.selMerchant.merchantId) {
        this.$toasted.error('Please select a merchant')
        return
      }
      if (!this.selAllAccts && this.acctIdList.length < 1) {
          this.$toasted.error('Please select account(s)')
          return
      }
      this.formDisabled = true
      
      var settlementParams = {
          merchantId: this.selMerchant.merchantId,
          txnDateStart: this.txnDateStart,
          txnDateEnd: this.txnDateEnd,
          adjDateStart: this.adjDateStart,
          adjDateEnd: this.adjDateEnd,
      }
      if (!this.selAllAccts) {
          settlementParams.accountIdList = this.acctIdList
      }
      if (this.procIdFilter && this.procIdFilter !== 'ALL') {
          settlementParams.procId = this.procIdFilter
      }
      this.axios.post('/ax/mxsettlement/adhoc', settlementParams)
      .then(response => {
        console.log('response: ' + response.data)
        this.pollCount = 0
        this.getReportInfo(response.data)
      })
      .catch(error => {
        this.handleError(error)
        this.formDisabled = false
      })
    },
    getReportInfo: function (rptId) {
      this.pollCount++
      if (this.pollCount > 5) {
        this.$bvModal.hide('settlement-input-form')
        this.$toasted.error('The report generation is taking longer than expected ... refresh this page later')
        return
      }
      this.axios.get('/ax/report/' + rptId)
      .then(response => {
        var reportInfo = response.data
        if (!reportInfo || reportInfo.status === 'INPROCESS') {
          setTimeout(this.getReportInfo, 6000, rptId)
        } else {
          this.$bvModal.hide('settlement-input-form')
          if (reportInfo.status === 'INERROR') {
            this.$toasted.error('The report generation encountered an error ... please contact tech support')
          } else {
            this.result.resultList.unshift(reportInfo)
            this.$toasted.success('Successfully generated ' + reportInfo.fileName)
          }
        }
      })
      .catch(error => {
        this.handleError(error)
        this.formDisabled = false
      })
    },
    confirmDelete: function (rpt) {
      this.$bvModal.msgBoxConfirm('Are you sure you want to delete ' + rpt.fileName + ' ? ', {
        title: 'Please Confirm',
        bodyClass: 'font-weight-bold text-danger text-center',
        centered: true
      })
      .then(value => {
        if (value === true) {
          this.deleteReport(rpt)
        }
      })
      .catch(err => {
        this.handleError(err)
      })
    },
    deleteReport: function (rpt) {
      const idx = this.result.resultList.indexOf(rpt)
      this.axios.delete('/ax/report/' + rpt.reportId)
      .then (() => {
        this.result.resultList.splice(idx, 1)
        this.$toasted.info('Report ' + rpt.fileName + ' deleted')
      })
      .catch(error => this.handleError(error))
    }
  }
}
</script>
