<template>
<div>
  <h5 class="text-center mb-5">Merchant Settlement Calculator</h5>

  <div v-if="view === 'form'">
    <div class="border py-3 bg-light">
      <div class="row mb-2">
        <div class="col-md-3 col-form-label fw-bold text-end ms-auto">Transactions Date Range</div>
        <input-date v-model="txnDateStart" group-class="col-auto" placeholder="yyyy-MM-dd"></input-date>
        <input-date v-model="txnDateEnd" group-class="col-auto me-auto" placeholder="yyyy-MM-dd"></input-date>
      </div>
      <div class="row">
        <div class="col-md-3 col-form-label fw-bold text-end ms-auto">CBs/Refunds Date Range</div>
        <input-date v-model="adjDateStart" group-class="col-auto" placeholder="yyyy-MM-dd"></input-date>
        <input-date v-model="adjDateEnd" group-class="col-auto me-auto" placeholder="yyyy-MM-dd"></input-date>
      </div>
    </div>
    <div class="border py-3 mt-2 bg-light">
      <div class=" row input-group">
        <input-select label="Merchant" v-model="selMxIdx" placeholder="select merchant" group-class="col-auto ms-auto">
          <option v-for="(mx, idx) in merchants" :key="idx" :value="idx.toString()">{{mx.name}}</option>
        </input-select>
        <input-select label="Account" v-model="selAccountId" placeholder="select account" group-class="col-auto">
          <option v-for="acct in selMerchant.accounts" :key="acct.accountId" :value="acct.accountId">{{acct.accountName}}</option>
        </input-select>
          <input-select label="Processor" v-model="procIdFilter" class="col-auto">
            <option value="ALL">Any Processor</option>
            <option v-for="proc in activeProcs" :key="proc.procId">{{proc.procId}}</option>
          </input-select>
        <div class="col-auto me-auto pt-4">
          <button class="btn btn-primary" @click.prevent="getSettlement">Calculate Settlement</button>
        </div>
      </div>
    </div>
  </div>

  <div v-if="view === 'result'">
    <div class="text-end text-info"><a href="#" @click.prevent="view = 'form'">Reset</a></div>
    <div class="row">
      <div class="col-5">
        <h5>Summary</h5>
        <table class="table table-bordered table-sm small">
          <thead class="table-dark">
            <tr><th>Transaction Type</th><th>Count</th></tr>
          </thead>
          <tbody>
            <tr><td>Total</td><td>{{setlm.txnTotalCount}}</td></tr>
            <tr><td>Success</td><td>{{setlm.successCount}}</td></tr>
            <tr><td>Failed</td><td>{{setlm.failedCount}}</td></tr>
            <tr><td>Chargebacks</td><td>{{setlm.chargebackCount}}</td></tr>
            <tr><td>Refunds</td><td>{{setlm.refundCount}}</td></tr>
            <tr><td>Refunded Chargebacks</td><td>{{setlm.refundedCbCount}}</td></tr>
          </tbody>
        </table>
      </div>
      <div class="col-6 pt-4 border border-info my-3 mx-auto setlm-header text-info">
        <table class="table table-borderless">
          <tbody>
            <tr>
              <td>Merchant</td>
              <td class="fw-bold">{{selMerchant.name}} ({{selMerchant.merchantId}})</td>
            </tr>
            <tr>
              <td>Account</td>
              <td class="fw-bold">{{selAccountId}}</td>
            </tr>
            <tr>
              <td>Transactions Period</td>
              <td class="fw-bold">{{txnDateStart}} - {{txnDateEnd}}</td>
            </tr>
            <tr>
              <td>CBs/Refunds Period</td>
              <td class="fw-bold">{{adjDateStart}} - {{adjDateEnd}}</td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
    <div class="row">
      <table class="table table-bordered table-sm">
        <thead class="table-dark">
          <tr><th>&nbsp;</th><th class="text-end pe-2">Amount (USD)</th></tr>
        </thead>
        <tbody>
          <tr class="fw-bold">
            <td>Gross Sales (net of adjustments)</td>
            <td class="text-end">${{setlm.grossSales | number('0,0.00')}}</td>
          </tr>
          <tr><td colspan="2">&nbsp;</td></tr>
          <tr>
            <td>Rolling Reserve <span class="small ps-2 text-info">({{setlm.rollingReservePct}}%)</span></td>
            <td class="text-end">$({{setlm.rollingReserve | number('0,0.00')}})</td>
          </tr>
          <tr>
            <td>Processing Fee <span class="small ps-2 text-info">({{setlm.processingFeePct}}%)</span></td>
            <td class="text-end">$({{setlm.processingFee | number('0,0.00')}})</td>
          </tr>
          <tr>
            <td>Transaction Fee (Succeeded) <span class="small ps-2 text-info">(${{setlm.transactionFeeRate}} per transaction)</span></td>
            <td class="text-end">$({{setlm.transactionFee | number('0,0.00')}})</td>
          </tr>
          <tr>
            <td>Transaction Fee (Declined) <span class="small ps-2 text-info">(${{setlm.transactionDeclineFeeRate}} per transaction)</span></td>
            <td class="text-end">$({{setlm.transactionDeclineFee | number('0,0.00')}})</td>
          </tr>
          <tr>
            <td>Chargeback Amount</td>
            <td class="text-end">$({{setlm.chargebackAmount | number('0,0.00')}})</td>
          </tr>
          <tr>
            <td>Chargeback Fee <span class="small ps-2 text-info">(${{setlm.chargebackFeeRate}} per chargeback)</span></td>
            <td class="text-end">$({{setlm.chargebackFee | number('0,0.00')}})</td>
          </tr>
          <tr>
            <td>Refund Amount</td>
            <td class="text-end">$({{setlm.refundAmount | number('0,0.00')}})</td>
          </tr>
          <tr>
            <td>Refund Fee <span class="small ps-2 text-info">(${{setlm.refundFeeRate}} per refund)</span></td>
            <td class="text-end">$({{setlm.refundFee | number('0,0.00')}})</td>
          </tr>
          <tr>
            <td>Refunded CB Amount</td>
            <td class="text-end">$({{setlm.refundedCbAmount | number('0,0.00')}})</td>
          </tr>
          <tr>
            <td>Refunded CB Fee <span class="small ps-2 text-info">(${{setlm.refundedCbFeeRate}} per refunded CB)</span></td>
            <td class="text-end">$({{setlm.refundedCbFee | number('0,0.00')}})</td>
          </tr>
          <tr>
            <td>Settlement Fee</td>
            <td class="text-end">$({{setlm.settlementFee | number('0,0.00')}})</td>
          </tr>
          <tr>
            <td>Conversion Fee  <span class="small ps-2 text-info">({{setlm.conversionFeePct}}%)</span></td>
            <td class="text-end">$({{setlm.conversionFeeAmount | number('0,0.00')}})</td>
          </tr>
          <tr><td colspan="2">&nbsp;</td></tr>
          <tr class="bg-dark text-white fw-bold">
            <td>Net Settlement</td>
            <td class="text-end">${{setlm.netSettlement | number('0,0.00')}}</td>
          </tr>
          <tr><td colspan="2">&nbsp;</td></tr>
          <tr class="small text-warning font-italic">
            <td>Estimated Reserve Release <span class="small ps-2 text-info">({{setlm.releasedDateStart}} - {{setlm.releasedDateEnd}})</span></td>
            <td class="text-end">${{setlm.reserveReleased | number('0,0.00')}}</td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>

  <wait-modal :loading="isLoading" message="Processing Settlement ... please wait"/>
</div>
</template>

<script>
import HelpersMixin from '../utils/HelpersMixin.js'
import ProcListMixin from '../utils/ProcListMixin.js'
import moment from 'moment'
export default {
  name: 'MercSettlementCalc',
  mixins: [HelpersMixin, ProcListMixin],
  data () {
    return {
      merchants: [],
      selMerchant: {},
      selAccountId: '',
      selMxIdx: '-1',
      txnDateStart: '',
      txnDateEnd: '',
      adjDateStart: '',
      adjDateEnd: '',
      setlm: {},
      view: 'form',
      fxDiff: '0',
      procIdFilter: 'ALL'
    }
  },
  watch: {
    selMxIdx: function (newValue, oldValue) {
      this.selMerchant = this.merchants[newValue]
      this.selAccountId = ''
    }
  },
  mounted () {
    this.getMerchants()
    this.getProcList()
    var baseMoment = moment().utc().subtract(7, 'days')
    this.txnDateStart = baseMoment.startOf('isoweek').format('YYYY-MM-DD')
    this.txnDateEnd = baseMoment.endOf('isoweek').format('YYYY-MM-DD')
    this.adjDateStart = baseMoment.startOf('isoweek').format('YYYY-MM-DD')
    this.adjDateEnd = baseMoment.endOf('isoweek').format('YYYY-MM-DD')
  },
  methods: {
    getMerchants: function () {
      this.axios.get('/ax/merchants')
        .then(response => {
          this.merchants = response.data
          this.merchants.sort((m1, m2) => (m1.name > m2.name) ? 1 : -1 )
          this.selMxIdx = '-1'
          this.selMerchant = {}
          this.selAccountId = ''
        })
        .catch(error => this.handleError(error))
    },
    getSettlement: function () {
      if (!this.selMerchant.merchantId || !this.selAccountId) {
        this.$toasted.error('Please select merchant and account')
        return
      }
      console.log('selAccountId: ' + this.selAccountId)
      var params = {
        merchantId: this.selMerchant.merchantId,
        accountId: this.selAccountId,
        txnDateStart: this.txnDateStart,
        txnDateEnd: this.txnDateEnd,
        adjDateStart: this.adjDateStart,
        adjDateEnd: this.adjDateEnd
      }
      if (this.procIdFilter && this.procIdFilter !== 'ALL') {
          params.procId = this.procIdFilter
      }
      this.isLoading = true
      this.axios.post('/ax/settlement', params)
        .then(response => {
          this.isLoading = false
          this.setlm = response.data
          this.view = 'result'
        })
        .catch(error => this.handleError(error))
    }
  }
}
</script>

<style scoped>
label { font-size: 75%; margin-bottom: 1px; font-weight: 600; }
.setlm-header { background-color: #ffffe6;}
</style>
