<template>
<div class="container-fluid mb-2">
  <div class="row text-white bg-dark pt-2 pe-2">
    <div class="col-auto">
      <span>{{companyName}}</span>
      <p class="small">Admin Console</p>
    </div>
    <div v-if="isTestEnv" class="col-auto me-auto text-danger fw-bold fs-6">TEST ENVIRONMENT</div>
    
    <template v-if="isLoggedIn()">
    <div class="col-auto ms-auto bg-dark">
      <ul class="list-group list-group-horizontal-md">
        <li class="list-group-item bg-dark"><router-link to="/summaries">SUMMARIES</router-link></li>
        <li class="list-group-item bg-dark"><router-link to="/transactions">TRANSACTIONS</router-link></li>
        <li class="list-group-item bg-dark"><router-link to="/memotxns">REFUNDS/CHARGEBACKS</router-link></li>
        <li class="list-group-item bg-dark"><router-link to="/refundrequests">REFUND REQUESTS</router-link></li>
      </ul>
    </div>

    <div class="col-auto dropdown mx-0 px-1">
      <button class="btn btn-secondary btn-sm dropdown-toggle mt-2 me-0" type="button" id="dropdownMenuButton1" data-bs-toggle="dropdown" aria-expanded="false">
        <i class="fas fa-cogs"></i>
      </button>
      <ul class="dropdown-menu py-0" aria-labelledby="dropdownMenuButton1">
        <li class="bg-info py-2 mt-0 text-white fw-bold text-center">Settings</li>
        <li><router-link to="/merchants" class="dropdown-item"><i class="fa fa-arrow-right me-2"></i>Merchants</router-link></li>
        <li><router-link to="/tpattribs" class="dropdown-item"><i class="fa fa-arrow-right me-2"></i>Processors</router-link></li>
        <li><router-link to="/routingrules" class="dropdown-item"><i class="fa fa-arrow-right me-2"></i>Routing Rules</router-link></li>
        <li><router-link to="/referencedata" class="dropdown-item"><i class="fa fa-arrow-right me-2"></i>Reference Data</router-link></li>
        <li><router-link to="/cacherefresh" class="dropdown-item"><i class="fa fa-arrow-right me-2"></i>Cache Refresh</router-link></li>
        <li><router-link to="/mercsettlementcalc" class="dropdown-item"><i class="fa fa-arrow-right me-2"></i>Merchant Settlement Calculator</router-link></li>
        <li><router-link to="/mercsettlementreports" class="dropdown-item"><i class="fa fa-arrow-right me-2"></i>Merchant Settlement Reports</router-link></li>
        <li><router-link to="/reservereleasecalc" class="dropdown-item"><i class="fa fa-arrow-right me-2"></i>Merchant Reserve Release Calculator</router-link></li>
        <li><router-link to="/procsettlementcalc" class="dropdown-item"><i class="fa fa-arrow-right me-2"></i>Processor Settlement Calculator</router-link></li>
        <li><router-link to="/procreservecalc" class="dropdown-item"><i class="fa fa-arrow-right me-2"></i>Processor Reserve Calculator</router-link></li>
        <li><router-link to="/emailextract" class="dropdown-item"><i class="fa fa-arrow-right me-2"></i>Email Extract</router-link></li>
      </ul>
    </div>
    
    <div class="col-auto dropdown mx-0 px-1">
      <button class="btn btn-secondary btn-sm dropdown-toggle mt-2 ms-0" type="button" id="dropdownMenuButton2" data-bs-toggle="dropdown" aria-expanded="false">
        <i class="fa fa-user-circle"></i>
      </button>
      <ul class="dropdown-menu py-0" aria-labelledby="dropdownMenuButton2">
        <li class="bg-info py-2 mt-0 text-white fw-bold text-center">{{$store.state.token.userId}}</li>
        <li><router-link to="/pwchange" class="dropdown-item"><i class="fa fa-lock me-2"></i> Change Password</router-link></li>
        <li><a class="dropdown-item" href="#" @click.prevent="logout"><i class="fa fa-power-off me-2"></i>Logout</a></li>
      </ul>
    </div>
    </template>
  </div>
</div>
</template>

<script>
export default {
  name: 'AdminHeader',
  data () {
    return {
      companyName: '',
      isTestEnv: false
    }
  },
  computed: {
    logoWidth: function () {
      if (this.isLoggedIn()) {
        return '90%'
      } else {
        return '130px'
      }
    }
  },
  mounted () {
    if (window.location.hostname.includes('localhost') || window.location.hostname.includes('test') || window.location.hostname.includes('dev')) {
      this.isTestEnv = true
    }
    this.extractCompanyName()
    this.axios.get('/ax/sessionCheck')
      .then(response => {
        console.log('Authenticated: ' + response.status + ' current route: ' + this.$route.path)
      })
      .catch(error => {
        console.log('ERROR on home: ' + error.response.status)
      })
  },
  methods: {
    isLoggedIn: function () {
      if (this.$store.state.token && this.$store.state.token.tokenId) {
        return true
      } else {
        return false
      }
    },
    logout: function () {
      this.axios.get('/ax/logout')
        .then(response => {
          console.log('Logged out: ' + response.status)
          this.$store.commit('clearToken')
          this.$router.push('/login')
        })
        .catch(error => {
          console.log(error)
          this.$store.commit('clearToken')
          this.$router.push('/login')
        })
    },
    extractCompanyName: function () {
      var sa = document.location.host.split('.')
      var x = sa.length - 2
      if (x < 0) {
        x = 0
      }
      this.companyName = sa[x].toUpperCase()
    }
  }
}
</script>

<style scoped>
.dropdown-item { font-size: .8em; border-top: 1px solid #d3d3d3; padding-top: .8em; padding-bottom: .8em;}

li.list-group-item a {
    text-decoration: none;
    font-size: .75em;
    color: white;
    padding-left: 1px; 
}
</style>
