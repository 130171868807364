<template>
  <div class="row">
    <input-select v-model="periodOpt" group-class="col-auto me-0 pe-0" input-class="text-white bg-secondary fw-bold text-center">
      <option value="TODAY">Today</option>
      <option value="THISWEEK">This Week</option>
      <option value="LASTWEEK">Last Week</option>
      <option value="LAST3DAYS">Last 3 Days</option>
      <option value="LAST7DAYS">Last 7 Days</option>
      <option value="THISMONTH">This Month</option>
      <option value="LASTMONTH">Last Month</option>
      <option value="DATERANGE">Date Range</option>
    </input-select>
    <template v-if="periodOpt === 'DATERANGE'">
      <input-date v-model="startDate" group-class="col-auto" placeholder="yyyy-MM-dd"></input-date>
      <div class="col-auto mt-2 mx-0 px-0">to</div>
      <input-date v-model="endDate" group-class="col-auto" placeholder="yyyy-MM-dd"></input-date>
      <div class="col-auto">
        <button class="btn btn-primary btn-sm ms-1 pb-2" @click.prevent="broadcastEvent()">Go</button>
      </div>
    </template>
    <template v-else>
      <div class="col-auto pt-2 text-info ms-0 mb-1 pb-0 rounded">
        <span class="fw-bold ">{{startDate}}</span>
        <span v-if="periodOpt !== 'TODAY'" class="small px-2">to</span>
        <span v-if="periodOpt !== 'TODAY'" class="fw-bold">{{endDate}}</span>
      </div>
    </template>
    <input-select v-model="timezone" group-class="col-auto">
      <option v-for="tz in tzList" :key="tz.refCode" :value="tz.refCode">{{tz.refValue}}</option>
    </input-select>
  </div>

</template>

<script>
import moment from 'moment'
import HelpersMixin from '../utils/HelpersMixin.js'
export default {
  name: 'PeriodSelector',
  mixins: [HelpersMixin],
  props: {
    defaultPeriod: String,
    dateRangeStart: null,
    dateRangeEnd: null
  },
  data () {
    return {
      periodOpt: '',
      period: {},
      startDate: '',
      endDate: '',
      auxTz: this.$localtz,
      timezone: 'UTC'
    }
  },
  mounted () {
    this.getTimezones()
    if (this.defaultPeriod) {
      this.periodOpt = this.defaultPeriod
    } else {
      this.periodOpt = 'TODAY'
    }
    this.calcPeriod()
    this.$watch('periodOpt', this.calcPeriod)
    this.$watch('timezone', this.onTimezoneChange)
    this.$watch('startDate', this.onDateRangeChange)
    this.$watch('endDate', this.onDateRangeChange)
  },
  methods: {
    calcPeriod: function () {
      var mnow = moment().utc()
      if (this.periodOpt === 'TODAY') {
        this.startDate = mnow.format('YYYY-MM-DD')
        this.endDate = mnow.format('YYYY-MM-DD')
      } else if (this.periodOpt === 'THISWEEK') {
        this.startDate = mnow.startOf('isoweek').format('YYYY-MM-DD')
        this.endDate = mnow.endOf('isoweek').format('YYYY-MM-DD')
      } else if (this.periodOpt === 'LASTWEEK') {
        mnow = mnow.subtract(7, 'days')
        this.startDate = mnow.startOf('isoweek').format('YYYY-MM-DD')
        this.endDate = mnow.endOf('isoweek').format('YYYY-MM-DD')
      } else if (this.periodOpt === 'LAST3DAYS') {
        this.endDate = mnow.format('YYYY-MM-DD')
        this.startDate = moment().utc().subtract(3, 'days').format('YYYY-MM-DD')
      } else if (this.periodOpt === 'LAST7DAYS') {
        this.endDate = mnow.format('YYYY-MM-DD')
        this.startDate = moment().utc().subtract(7, 'days').format('YYYY-MM-DD')
      } else if (this.periodOpt === 'THISMONTH') {
        this.startDate = mnow.startOf('month').format('YYYY-MM-DD')
        this.endDate = mnow.endOf('month').format('YYYY-MM-DD')
      } else if (this.periodOpt === 'LASTMONTH') {
        mnow = mnow.subtract(1, 'months')
        this.startDate = mnow.startOf('month').format('YYYY-MM-DD')
        this.endDate = mnow.endOf('month').format('YYYY-MM-DD')
      } else {
        this.periodOpt = 'DATERANGE'
        if (this.dateRangeStart) {
          this.startDate = this.dateRangeStart
        } else {
          this.startDate = moment().utc().subtract(3, 'days').format('YYYY-MM-DD')
        }
        if (this.dateRangeEnd) {
          this.endDate = this.dateRangeEnd
        } else {
          this.endDate = mnow.format('YYYY-MM-DD')
        }
        //return
      }
      this.broadcastEvent()    
    },
    onTimezoneChange: function () {
      if (this.timezone === 'UTC') {
        this.auxTz = this.$localtz
      } else {
        this.auxTz = this.timezone
      }
      this.broadcastEvent()
    },
    broadcastEvent: function () {
      this.period = {periodOpt: this.periodOpt, startDate: this.startDate, endDate: this.endDate, timezone: this.timezone, auxTz: this.auxTz}
      this.$emit('periodUpdated', this.period)
    },
    onDateRangeChange: function () {
      if (this.periodOpt !== 'DATERANGE') {
        return
      }
      this.$emit('dateRangeChanged')
    }
  }
  
}
</script>
