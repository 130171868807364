<template>
<div class="container">
  <div class="row">
    <input-text label="Account Name" v-model="mxa.accountName" placeholder="enter account name"
      :errors="errors" error-key="accountName" group-class="col-6"/>
    <div class="col-auto ms-auto pt-4 fw-bold">
      <input-switch label="" v-model="isActive" showstate state-label-on="ACTIVE" state-label-off="INACTIVE"/>
    </div>
  </div>
  <div class="row">
    <input-text label="Website URL" v-model="mxa.website" group-class="col"/>
  </div>
  <div class="row">
    <div class="col-6">
      <input-select label="Currency" v-model="mxa.currency" placeholder="select currency">
        <option>USD</option>
        <option>CAD</option>
        <option>EUR</option>
      </input-select>
      <input-select label="Settlement Type" v-model="settlementType" placeholder="select type">
        <option value="FIAT">FIAT</option>
        <option value="CRYPTO">CRYPTO</option>
      </input-select>
      <input-text label="Settlement Destination" v-model="mxa.settlementAttrib" placeholder="enter crypto address or bank name"
        :errors="errors" error-key="settlementAttrib"/>
      <input-number label="Daily Limit (Overall)" v-model="mxa.dailyLimit" placeholder="enter daily limit"
        :errors="errors" error-key="dailyLimit" :prepend="'$'"/>
      <input-number label="Daily Limit (VISA)" v-model="mxa.dailySubLimits.VISA" placeholder="enter daily limit for VISA"
        :errors="errors" error-key="dailySubLimit" :prepend="'$'"/>
      <input-number label="Monthly Limit (Overall)" v-model="mxa.monthlyLimit" placeholder="enter monthly limit"
        :errors="errors" error-key="monthlyLimit" :prepend="'$'"/>
      <input-number label="Monthly Limit (VISA)" v-model="mxa.monthlySubLimits.VISA" placeholder="enter monthly limit for VISA"
        :errors="errors" error-key="monthlySubLimit" :prepend="'$'"/>
    </div>
    <div class="col-6">
      <input-number
        label="Settlement Fee (per settlement)" v-model="mxa.settlementFee" placeholder="0.00"
        :errors="errors" error-key="settlementFee" :prepend="'$'"/>
      <input-number
        label="Settlement Conversion Fee ((% of net)" v-model="mxa.conversionFeePct" placeholder="0.00"
        :errors="errors" error-key="conversionFeePct" :prepend="'%'"/>
      <input-number label="Processing Fee (% of success amount)" v-model="mxa.processingFee" placeholder="0.00"
        :errors="errors" error-key="processingFee" :prepend="'%'"/>
      <input-number label="Transaction Fee (Succeeded) (amount per txn)" v-model="mxa.transactionFee" placeholder="0.00"
        :errors="errors" error-key="processingFee" :prepend="'$'"/>
      <input-number label="Transaction Fee (Declined) (amount per txn)" v-model="mxa.transactionDeclineFee" placeholder="0.00"
                    :errors="errors" error-key="processingFee" :prepend="'$'"/>
      <input-number label="Rolling Reserve ((% of success amount)" v-model="mxa.rollingReserve" placeholder="0.00"
        :errors="errors" error-key="rollingReserve" :prepend="'%'"/>
      <input-number label="Chargeback Fee (amount per chargeback)" v-model="mxa.chargebackFee" placeholder="0.00"
        :errors="errors" error-key="chargebackFee" :prepend="'$'"/>
      <input-number label="Refund Fee (amount per refund)" v-model="mxa.refundFee" placeholder="0.00"
        :errors="errors" error-key="refundFee" :prepend="'$'"/>
      <input-number label="Refunded Chargeback Fee (amount per refunded cb)" v-model="mxa.refundedCbFee" placeholder="0.00"
        :errors="errors" error-key="refundFee" :prepend="'$'"/>
    </div>
  </div>
  <div class="row">
    <div class="col">
      <label for="notes" class="form-label fw-bold" style="font-size: 75%; margin-bottom: 1px;">Notes</label>
      <textarea id="notes" v-model="mxa.notes" rows="3" class="form-control"></textarea>
    </div>
  </div>
  <div class="row">
    <div class="col text-end pt-3">
      <button class="btn btn-secondary me-2" @click="onCancel()">Cancel</button>
      <button class="btn btn-primary" @click.self.prevent.once="onSave">Save</button>
    </div>
  </div>
</div>
</template>

<script>
import HelpersMixin from '../utils/HelpersMixin.js'
import { ErrorsContainer } from '../utils/ErrorsContainer.js'
export default {
  name: 'MerchantAccountForm',
  mixins: [HelpersMixin],
  props: {
    merchantId: String,
    merchantAccount: Object
  },
  data () {
    return {
      mxa: { merchantId: this.merchantId, accountId: '', dailySubLimits: {}, monthlySubLimits: {} },
      settlementType: 'FIAT',
      isActive: true,
      errors: new ErrorsContainer(),
      mode: 'add'
    }
  },
  mounted () {
    if (this.merchantAccount && this.merchantAccount.accountId) {
      this.mode = 'update'
      Object.assign(this.mxa, this.merchantAccount)
      this.settlementType = this.mxa.settlementType
      this.isActive = this.mxa.active
      this.$forceUpdate()
    } else {
      this.mode = 'add'
    }
  },
  methods: {
    onSave: function () {
      this.$forceUpdate()
      this.errors.clearAll()
      this.mxa.active = this.isActive
      this.mxa.settlementType = this.settlementType
      this.axios.post('/ax/mxaccount/' + this.merchantId + '/' + this.mode, this.mxa)
        .then(() => {
          this.$emit('saved')
        })
        .catch(error => {
          this.handleError(error)
        })
    },
    onCancel: function () {
      this.$emit('cancelled')
    }
  }
}
</script>
