<template>
<div>
  <b-modal id="reportgen-modal" title="Export Generation" hide-footer no-close-on-backdrop>
    <div class="row justify-content-center" v-if="mode === 'INPROCESS'">
      <b-spinner variant='primary' label='Please wait'></b-spinner>
      <span class="mb-2 mx-3 text-warning">Generating export file ... Please wait</span>
    </div>
    <div class="text-danger text-center py-5" v-else-if="mode === 'INERROR'">{{errMsg}}</div>
    <div class="text-success text-center fw-bold py-3" v-else>
      Export file<br/>{{reportInfo.fileName}}<br/> successfully generated
    </div>
  </b-modal>
  <div class="d-none">
    <a ref="downloadLink"></a>
  </div>
</div>
</template>

<script>
export default {
  name: 'FileExporter',
  props: ['apiGenerate', 'apiRetrieve', 'paramsForGenerate'],
  data () {
    return {
      mode: 'INPROCESS',
      errMsg: '',
      pollCount: 0,
      reportInfo: {}
    }
  },
  methods: {
    generate: function () {
      this.reportInfo = {}
      this.mode = 'INPROCESS'
      this.$bvModal.show('reportgen-modal')
      this.axios.post(this.apiGenerate, this.paramsForGenerate)
        .then(response => {
          this.reportInfo = response.data
          this.pollCount = 0
          setTimeout(this.download, 5000, this.reportInfo.reportId)
        })
        .catch(error => {
          this.mode = 'INERROR'
          console.log(JSON.stringify(error.response))
          if (error.response.data && error.response.data.msgText) {
            this.errMsg = error.response.data.msgText
          } else {
            this.errMsg = error.response.data.status + '- ' + error.response.data.error
          }
        })
    },
    download: function (reportId) {
      this.pollCount++
      this.axios.get(this.apiRetrieve + reportId)
        .then(response => {
          if (response.data) {
            clearInterval(this.reportPoller)
            this.mode = 'COMPLETED'
            const s3Url = response.data
            this.axios.get(s3Url, { responseType: 'blob' })
              .then(response => {
                const url = URL.createObjectURL(new Blob([response.data], { type: 'text/csv' }))
                var link = this.$refs.downloadLink
                link.href = url
                link.setAttribute('download', this.reportInfo.fileName)
                link.click()
                this.$bvModal.hide('reportgen-modal')
              })
          } else {
            this.mode = 'INERROR'
            this.errMsg = 'Report file is empty'
          }
        })
        .catch(error => {
          if (error.response.status === 422) {
            this.readErrorFromBlob(error.response.data)
          } else {
            console.log(JSON.stringify(error.response))
            this.mode = 'INERROR'
            this.errMsg = 'Unexpected error encountered ... check console log'
          }
        })
    },
    readErrorFromBlob: async function (err) {
      if (err.msgCode === 'E5005') {
        setTimeout(this.download, 6000, this.reportInfo.reportId)
      } else {
        this.mode = 'INERROR'
        this.errMsg = err.msgText
      }
    }
  }
}
</script>
