<template>
<div>
  <div v-if="view === 'list'">
    <h5 class="text-center mb-3">Processor Routing Rules</h5>
    <div class="text-end small text-info mb-2"><a @click="createNew()" href="#"><i class="fas fa-plus-square"></i> Create New</a>
    </div>
    <table class="table table-striped table-hover small">
      <thead class="table-dark">
        <tr>
          <th width="20%">Rule ID</th>
          <th width="10%">Priority</th>
          <th width="40%">Conditions</th>
          <th width="20%">Processor ID</th>
          <th width="5%">Skip Bal.</th>
          <th width="5%">&nbsp;</th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="rule in rulesList" :key="rule.ruleId">
          <td class="text-primary fw-bold" @click="showDetails(rule)" style="cursor: pointer;">{{rule.ruleId}}</td>
          <td>{{rule.priority}}</td>
          <td>
            <span class="font-italic text-warning" v-if="rule.conditions == null || rule.conditions.length == 0">( No conditions )</span>
            <span v-else v-for="(cond, idx) in rule.conditions" :key="idx">
              {{cond.attrName}} &nbsp; {{cond.condType}} &nbsp; {{cond.condValue}}<br/>
            </span>
          </td>
          <td>{{rule.routeToProcId}}</td>
          <td>{{rule.skipBalancing}}</td>
          <td>
            <a @click="confirmRuleDel(rule)" v-b-tooltip.hover title="Delete Rule"><i class="fas fa-trash"></i></a>
            <a @click="copyRule(rule)" class="ms-2" v-b-tooltip.hover title="Copy Rule"><i class="fas fa-copy"></i></a>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
  <routing-rule-form v-if="view === 'form'" :rule-prop="selected"
    @saved="getRules" @cancelled="view = 'list'"></routing-rule-form>
  <b-modal id="confirmDelModal" title="Confirm Deletion" @ok="deleteRule">
    <div class="text-center">
      <span class="fw-bold text-danger">Are you sure you want to delete rule {{selected ? selected.ruleId : ""}} ?</span>
    </div>
  </b-modal>
</div>
</template>

<script>
import RoutingRuleForm from '../components/RoutingRuleForm.vue'
export default {
  name: 'RoutingRules',
  components: {
    RoutingRuleForm
  },
  data () {
    return {
      rulesList: {},
      view: 'list',
      selected: null
    }
  },
  mounted () {
    this.getRules()
  },
  methods: {
    getRules: function () {
      this.view = 'list'
      this.axios.get('/ax/procroutingrules', { headers: { 'ccpgadmin-authz': this.$store.state.token.tokenId } })
        .then(response => {
          this.isLoading = false
          this.rulesList = response.data
        })
        .catch(error => {
          this.isLoading = false
          console.log(error.response.status)
        })
    },
    createNew: function () {
      this.selected = null
      this.view = 'form'
    },
    copyRule: function (rule) {
      this.selected = JSON.parse(JSON.stringify(rule))
      this.selected.ruleId = null
      this.view = 'form'
    },
    showDetails: function (rule) {
      this.selected = rule
      this.view = 'form'
    },
    confirmRuleDel: function (rule) {
      this.selected = rule
      this.$bvModal.show('confirmDelModal')
    },
    deleteRule: function () {
      console.log('delete rule ' + this.selected.ruleId)
      var ruleIdForDel = this.selected.ruleId
      this.axios.delete('/ax/procroutingrules/' + ruleIdForDel)
        .then(response => {
          this.isLoading = false
          this.$bvModal.hide('confirmDelModal')
          this.getRules()
          this.selected = null
          this.$toasted.success(ruleIdForDel + ' successfully deleted')
        })
        .catch(error => {
          this.isLoading = false
          console.log(error.response.status)
          this.$toasted.error('Unexpected error encountered ... check console log')
        })
    }
  }
}
</script>
