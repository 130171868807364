<template>
<div>
  <div v-if="view === 'list'">
    <h5 class="text-center mb-3">Processor Attributes</h5>
    <div class="text-end small text-info mb-2"><a @click.prevent="createNew()" href="#"><i class="fas fa-plus-square"></i> Create New</a>
    </div>
    <table class="table table-striped table-hover small">
      <thead class="table-dark">
        <tr>
          <th>3rd-Party ID</th>
          <th>Group Code</th>
          <th>Description</th>
          <th>Load Weight</th>
          <th>Last Update</th>
          <th>Last Update By</th>
          <th>Status 
            <a href="#" @click.prevent="showAll = !showAll" v-b-tooltip.hover.v-info :title="statusFilterTitle"><i class="fas fa-filter text-info ps-2"></i></a>
          </th>
          <th>&nbsp;</th>
        </tr>
      </thead>
      <tbody>
        <template v-for="tpa in sortByTpId(tpaList)">
        <tr :key="tpa.thirdPartyId" v-if="showAll || tpa.active">
          <td class="text-primary fw-bold" @click="showDetails(tpa)" style="cursor: pointer;">{{tpa.tpId}}</td>
          <td>{{tpa.group}}</td>
          <td>{{tpa.tpDesc}}</td>
          <td>{{tpa.loadWeight}}</td>
          <td>{{tpa.lastUpdate | moment("timezone", $localtz, "YYYY-MM-DD HH:mm zz")}}</td>
          <td>{{tpa.lastUpdateBy}}</td>
          <td>
            <span v-if="tpa.active" class="text-success">Active</span>
            <span v-else class="text-warning">Inactive</span>
          </td>
          <td>
            <a @click="copyProc(tpa)" class="ms-2" v-b-tooltip.hover title="Copy Processsor"><i class="fas fa-copy"></i></a>
          </td>
        </tr>
        </template>
      </tbody>
    </table>
  </div>
  <tp-attribs-form v-if="view === 'form'" :tp-attrib="selected"
    @saved="getTpAttribs()" @cancelled="view = 'list'"></tp-attribs-form>
</div>
</template>

<script>
import TpAttribsForm from '../components/TpAttribsForm.vue'
export default {
  name: 'TpAttribs',
  components: { TpAttribsForm },
  data () {
    return {
      tpaList: {},
      view: 'list',
      showAll: false,
      selected: null
    }
  },
  mounted () {
    this.getTpAttribs()
  },
  computed: {
    statusFilterTitle: function () {
      if (this.showAll) {
        return 'Hide Inactive'
      } else {
        return 'Show Inactive'
      }
    }
  },
  methods: {
    getTpAttribs: function () {
      this.view = 'list'
      this.axios.get('/ax/alltpattribs')
        .then(response => {
          this.isLoading = false
          this.tpaList = response.data
        })
        .catch(error => {
          this.isLoading = false
          console.log(error.response.status)
        })
    },
    createNew: function () {
      this.selected = null
      this.view = 'form'
    },
    showDetails: function (tpa) {
      this.selected = tpa
      this.view = 'form'
    },
    sortByTpId: function (procList) {
      if (!procList) {
        return null
      }
      return Array.from(Object.values(procList)).sort((a, b) => (a.tpId.toLowerCase() > b.tpId.toLowerCase()) ? 1 : -1)
    },
    copyProc: function (tpa) {
        this.selected = JSON.parse(JSON.stringify(tpa))
        this.selected.tpId = null
        this.view = 'form'
    }
    
  }
}
</script>
