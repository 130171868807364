<template>
<div>
  <h5 class="text-center mb-5">Processor Reserve Calculator</h5>
  
  <div v-if="view === 'form'">
    <div class="row mb-2">
      <input-date v-model="dateStart" label="From" group-class="col-auto" placeholder="yyyy-MM-dd"></input-date>
      <input-date v-model="dateEnd" label="To" group-class="col-auto" placeholder="yyyy-MM-dd"></input-date>
      <input-select v-model="timezone" label="Timezone" group-class="col-auto">
        <option v-for="tz in tzList" v-bind:key="tz.refCode" :value="tz.refCode">{{tz.refValue}}</option>
      </input-select>
      <input-number v-model="rrPercent" label="Reserve Percent" group-class="col-auto me-auto" append="%"></input-number>
    </div>
    
    <proc-multi-select ref="procMultiSelect"></proc-multi-select>
    
    <div class="row mt-5">
      <button class="btn btn-primary col-auto mx-auto" v-on:click="calcReserve">Calculate Reserve</button>
    </div>
  </div>
  
  <div v-if="view === 'result'">
    <div class="row">
      <div class="col-auto mx-auto">
        <p class="text-end text-info"><a v-on:click="view = 'form'">Reset</a></p>
        <h5>Reserve Summary</h5>
        <table class="table table-bordered">
          <tr>
            <td class="pe-5">Date Range</td>
            <td>{{procReserve.formattedDateStart}} - {{procReserve.formattedDateEnd}}</td>
          </tr>
          <tr>
            <td>Time Zone</td>
            <td class="text-center">{{lookupTzDesc(procReserve.timezone)}}</td>
          </tr>
          <tr>
            <td>No. of Successful Transactions</td>
            <td class="text-end">{{procReserve.reserveBasisCount}}</td>
          </tr>
          <tr>
            <td class="pe-5">Total Amount of Successful Transactions</td>
            <td class="text-end">${{procReserve.reserveBasisAmount | number('0,0.00')}}</td>
          </tr>
          <tr>
            <td>Reserve Percent</td>
            <td class="text-end">{{procReserve.reservePct}}%</td>
          </tr>
          <tr>
            <td colspan="2"></td>
          </tr>
          <tr class="bg-dark text-white">
            <td class="fw-bold">Amount Reserved</td>
            <td class="text-end fw-bold">${{procReserve.reserveAmount | number('0,0.00')}}</td>
          </tr>
        </table>
      </div>
    </div>
  </div>
  
</div>
</template>

<script>
import moment from 'moment'
import HelpersMixin from '../utils/HelpersMixin.js'
import ProcMultiSelect from '../components/ProcMultiSelect.vue'
export default {
  name: 'ProcReserveCalc',
  components: { ProcMultiSelect },
  mixins: [HelpersMixin],
  data () {
    return {
      dateStart: '',
      dateEnd: '',
      timezone: 'UTC',
      rrPercent: 10,
      procIds: [],
      procReserve: {},
      isLoading: false,
      view: 'form'
    }
  },
  mounted () {
    this.getTimezones()
    
    var baseMoment = moment().utc().subtract(7, 'days')
    this.dateStart = baseMoment.startOf('isoweek').format('YYYY-MM-DD')
    this.dateEnd = baseMoment.endOf('isoweek').format('YYYY-MM-DD')
  },
  methods: {
    calcReserve: function () {
      this.procIds = this.$refs.procMultiSelect.getSelectedProcs()
      if (this.procIds.length < 1) {
        this.$toasted.error('Please select 1 or more processors')
        return
      }
      this.isLoading = true
      this.axios.post('/ax/procreserve', {procIds: this.procIds, dateStart: this.dateStart, dateEnd: this.dateEnd, timezone: this.timezone, rrPercent: this.rrPercent})
      .then(response => {
        this.isLoading = false
        this.procReserve = response.data
        this.view = 'result'
      })
      .catch(error => {
        this.isLoading = false
        this.handleError(error)
      })
    }
  }
}
</script>
