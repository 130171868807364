<template>
<div>
  <h5 class="text-center mb-5">Merchant Reserve Release Calculator</h5>

  <div v-if="view === 'form'">
    <div class="row">
      <input-date v-model="rcparams.dateStart" label="From" group-class="col-auto" placeholder="yyyy-MM-dd"></input-date>
      <input-date v-model="rcparams.dateEnd" label="To" group-class="col-auto" placeholder="yyyy-MM-dd"></input-date>
      <input-select v-model="rcparams.timezone" label="Timezone" group-class="col-auto me-auto">
        <option v-for="tz in tzList" v-bind:key="tz.refCode" :value="tz.refCode">{{tz.refValue}}</option>
      </input-select>
    </div>
    <div class="row mb-2">
      <input-number v-model="rcparams.rrPercent" label="Reserve Percent" group-class="col-lg-2 col-md-3 col-auto" input-class="col-auto" append="%"></input-number>
      <input-number v-model="rcparams.receivedAmount" label="Amount Received" group-class="col-auto me-auto"></input-number>
    </div>
    
    <proc-multi-select ref="procMultiSelect"></proc-multi-select>
    
    <div class="row mt-5">
      <button class="btn btn-primary col-auto mx-auto" v-on:click="calcReserveRelease">Calculate Reserve</button>
    </div>
  </div>

  <div v-if="view === 'result'">
    <div class="row">
      <p class="col-auto ms-auto text-info"><a href="javascript:void(0);" v-on:click="view = 'form'">Reset</a></p>
    </div>
    
    <div class="row">
      <div class="col-lg-6 text-center pt-4 border border-info mx-auto mb-3 setlm-header text-info">
        <p class="mb-0">{{result.dateStart}} - {{result.dateEnd}}</p>
        <p class="mt-0 pt-0 small">{{lookupTzDesc(result.timezone)}}</p>
        <p>{{result.procIds}}</p>
      </div>
    </div>

    <div class="row">
      <table class="table table-bordered">
        <thead class="text-center table-dark small">
          <tr>
            <th rowspan="2">Merchant</th>
            <th colspan="2">Total Transactions</th>
            <th rowspan="2">Expected RR Release</th>
            <th rowspan="2">Allocated RR Release</th>
            <th rowspan="2">Allocation %</th>
          </tr>
          <tr class="small">
            <th>Count</th>
            <th>Amount</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="mr in result.merchantReserveList" v-bind:key="mr.merchantId">
            <td>{{mr.merchantName}}<br/><span class="small text-info">{{mr.merchantId}}</span></td>
            <td class="text-center">{{mr.reserveDetails.basisCount}}</td>
            <td class="text-end">{{mr.reserveDetails.basisAmount | number('0,0.00')}}</td>
            <td class="text-end">{{mr.reserveDetails.calculatedReserve | number('0,0.00')}}</td>
            <td class="text-end">{{mr.allocatedReleased | number('0,0.00')}}</td>
            <td class="text-center">{{mr.allocatedPct}}</td>
          </tr>
        </tbody>
        <tfoot class="table-dark">
          <tr>
            <th>Totals</th>
            <th class="text-center">{{result.procReserve.basisCount}}</th>
            <th class="text-end">{{result.procReserve.basisAmount | number('0,0.00')}}</th>
            <th class="text-end">{{result.procReserve.calculatedReserve | number('0,0.00')}}</th>
            <th class="text-end">{{result.procReleased | number('0,0.00')}}</th>
            <th>&nbsp;</th>
          </tr>
        </tfoot>
      </table>
    </div>
  </div>
  
  <wait-modal :loading="isLoading" message="Calculating Reserve Release ... please wait"/>
</div>
</template>

<script>
import moment from 'moment'
import HelpersMixin from '../utils/HelpersMixin.js'
import ProcMultiSelect from '../components/ProcMultiSelect.vue'
export default {
  name: 'ReserveReleaseCalc',
  components: { ProcMultiSelect },
  mixins: [HelpersMixin],
  data () {
    return {
      rcparams: { dateStart: '', dateEnd: '', timezone: 'UTC', rrPercent: 10, receivedAmount: 0, procIds: [] },
      result: {},
      isLoading: false,
      view: 'form'
    }
  },
  mounted () {
    this.getTimezones()
    
    var baseMoment = moment().utc().subtract(7, 'days')
    this.rcparams.dateStart = baseMoment.startOf('isoweek').format('YYYY-MM-DD')
    this.rcparams.dateEnd = baseMoment.endOf('isoweek').format('YYYY-MM-DD')
  },
  methods: {
    calcReserveRelease: function () {
      this.rcparams.procIds = this.$refs.procMultiSelect.getSelectedProcs()
      if (this.rcparams.procIds.length < 1) {
        this.$toasted.error('Please select 1 or more processors')
        return
      }
      this.isLoading = true
      this.axios.post('/ax/reserverelease/calc', this.rcparams)
      .then(response => {
        this.isLoading = false
        this.result = response.data
        this.view = 'result'
      })
      .catch(error => {
        this.isLoading = false
        this.handleError(error)
      })
    }
  }
}
</script>

<style scoped>
.setlm-header { background-color: #ffffe6; font-weight: bold;}
</style>
