<template>
  <div class="form-check form-switch">
    <input type="checkbox" class="form-check-input" v-model="inputData"/>
    <label class="form-check-label">
      {{label}}
      <template v-if="showstate">
        <span v-if="label"> - </span>
        <span v-if="value" class="text-success">{{stateLabel}}</span>
        <span v-else class="text-warning">{{stateLabel}}</span>
      </template>
    </label>
  </div>
</template>

<script>
export default {
  name: 'InputSwitch',
  props: {
    label: String,
    value: Boolean,
    stateLabelOn: String,
    stateLabelOff: String,
    showstate: { type: Boolean, default: false }
  },
  computed: {
    inputData: {
      get () {
        return this.value
      },
      set (val) {
        this.$emit('input', val)
      }
    },
    stateLabel() {
      if (!this.showstate) {
        return ''
      }
      if (this.value) {
        if (this.stateLabelOn) {
          return this.stateLabelOn
        } else {
          return 'ENABLED'
        }
      } else {
        if (this.stateLabelOff) {
          return this.stateLabelOff
        } else {
          return 'DISABLED'
        }
      }
    }
  }
}
</script>