<template>
<div class="row justify-content-center">
  <div class="col-md-9">
    <h6 class="text-info row mb-4">
      <div class="col-6">Transaction: {{txn.txnId}}</div>
      <div class="col-6 text-end">Amount: ${{txn.txnAmount.value  | number('0,0.00')}} {{txn.txnAmount.currency}}</div>
    </h6>
    <form>
      <input-text label="Processor reference" v-model="procRef" placeholder="processor's reference number"/>
      <template v-if="updateType !== 'TO_SUCCESS'">
      <input-select v-model="resultCode" label="Result Code">
        <option v-for="rc in resultCodes" v-bind:key="rc.code" :value="rc">{{rc.name}}</option>
      </input-select>
      <input-text label="Result Text" v-model="resultText"></input-text>
      </template>
      <template v-else>
      <input-text label="Descriptor" v-model="descriptor"></input-text>
      </template>
    </form>
    <div class="text-end mt-4">
      <button class="btn btn-secondary me-2" v-on:click="onCancel()">Cancel</button>
      <button class="btn btn-primary" v-on:click="onSave()">Save</button>
    </div>
  </div>
</div>
</template>

<script>
export default {
  name: 'TxnUpdateForm',
  props: {
    txn: Object,
    updateType: String
  },
  data () {
    return {
      procRef: '',
      resultCode: {},
      resultText: '',
      descriptor: '',
      resultCodes: [
        { code: '01', name: 'AUTHREQD', desc: '3DS/VBV authorization required' },
        { code: '02', name: 'PENDING', desc: 'Pending' },
        { code: '70', name: 'DONOTHONOUR', desc: 'Do not honour' },
        { code: '81', name: 'ADDRMISMATCH', desc: 'Address mismatch' },
        { code: '82', name: 'CARDLIMIT', desc: 'Card limit exceeded' },
        { code: '83', name: 'MAXDAILYTXN', desc: 'Daily card txn count exceeded' },
        { code: '84', name: 'EXPIREDCARD', desc: 'Expired Card' },
        { code: '85', name: 'DUPTXN', desc: 'Duplicate transaction' },
        { code: '86', name: 'RESTRICTED', desc: 'Restricted card' },
        { code: '87', name: 'HIGHRISK', desc: 'High Risk' },
        { code: '88', name: 'NOPROCESSOR', desc: 'No processor available' },
        { code: '89', name: 'AUTHTIMEDOUT', desc: '3DS/VBV authorization timed out' },
        { code: '90', name: 'CARDNOTSUPPORTED', desc: 'Card not supported' },
        { code: '91', name: 'CARDINVALID', desc: 'Card details invalid' },
        { code: '92', name: 'CVVINVALID', desc: 'CVV/CVC invalid' },
        { code: '93', name: 'INSUFFICIENTFUNDS', desc: 'Insufficient funds' },
        { code: '94', name: 'NONUSDCARD', desc: 'Non-USD Card' },
        { code: '95', name: 'NOTWHITELISTED', desc: 'Card not whitelisted' },
        { code: '96', name: 'AUTHFAILED', desc: '3DS/VBV authorization failed' },
        { code: '97', name: 'FRAUD', desc: 'Fraudulent card' },
        { code: '98', name: 'DECLINED', desc: 'Declined' },
        { code: '99', name: 'SYSERR', desc: 'System error' }
      ]
    }
  },
  watch: {
    resultCode: function (newValue) {
      this.resultText = newValue.desc
    }
  },
  mounted () {
    this.procRef = this.txn.procRef
    this.descriptor = this.txn.procDescriptor
  },
  methods: {
    onSave: function () {
      var params = { txnId: this.txn.txnId, procRef: this.procRef }
      var apiPath = ''
      if (this.updateType === 'TO_SUCCESS') {
        params.descriptor = this.descriptor
        apiPath = '/ax/txnupdate/success'
      } else {
        if (!this.resultCode || !this.resultCode.code) {
          this.$toasted.error('Please select a result code')
          return
        }
        params.resultCode = this.resultCode.name
        params.resultText = this.resultText
        if (this.updateType === 'TO_FAILED') {
          apiPath = '/ax/txnupdate/failed'
        } else if (this.updateType === 'CHG_RESULT') {
          apiPath = '/ax/txnupdate/failedresult'
        } else {
          return
        }
      }
      this.axios.post(apiPath, params)
      .then(response => {
        this.$emit('saved')
      })
      .catch(error => {
        if (error.response.status === 422) {
          this.$toasted.error(error.response.data.msgText)
        } else {
          console.log(JSON.stringify(error.response))
          this.$toasted.error('Unexpected error. HTTP Status: ' + error.response.status)
        }
      })
    },
    onCancel: function () {
      this.$emit('cancelled')
    }
  }
}
</script>
