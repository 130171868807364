<template>
<div class="mb-1" :class="groupClass">
  <label v-if="label">{{label}}</label>
  <select class="form-select" ref="inputelem" :style="dispStyle"
    :placeholder="placeholder"
    v-model="inputData"
    :class="[{'is-invalid': hasError()}, inputClass]">
    <option v-if="placeholder" value="" disabled>{{placeholder}}</option>
    <slot></slot>
  </select>
  <small class="invalid-feedback" v-if="hasError()">{{errorMsg}}</small>
</div>
</template>

<script>
export default {
  name: 'InputSelect',
  props: {
    label: String,
    value: String,
    inputClass: String,
    groupClass: String,
    placeholder: String,
    focus: Boolean,
    errors: { type: Object, default: () => ({}) },
    errorKey: String
  },
  mounted () {
    if (this.focus) {
      this.$refs.inputelem.focus()
    }
  },
  data () {
    return {
      errorMsg: ''
    }
  },
  computed: {
    inputData: {
      get () {
        return this.value
      },
      set (val) {
        this.$emit('input', val)
      }
    },
    dispStyle: function () {
      if (this.value === undefined || this.value === '') {
        return 'font-size: small; color: #999; height: 3em;'
      }
      return ''
    }
  },
  methods: {
    hasError: function () {
      if (this.errors.fldErrors && this.errorKey) {
        const err = this.errors.getError(this.errorKey)
        if (err) {
          this.errorMsg = err.defaultMessage
          return true
        }
      }
      return false
    }
  }
}
</script>

<style scoped>
label { font-size: 75%; margin-bottom: 1px; font-weight: 600; }
::placeholder { font-size: 75%; color: #999; }
:-ms-input-placeholder { font-size: 75%; color: #999; }
::-ms-input-placeholder { font-size: 75%; color: #999; }
</style>
