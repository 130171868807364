<template>
<div>
  <h5 class="text-center mb-5">Extract Email List</h5>

  <div class="row">
    <input-select label="Merchant" v-model="merchantId" placeholder="Select Merchant" group-class="col col-5 mx-auto">
      <option v-for="(mx, idx) in merchants" v-bind:key="idx" :value="mx.merchantId">{{mx.name}}</option>
    </input-select>
  </div>
  <div class="row mt-3">
    <button class="btn btn-primary col col-auto mx-auto" v-on:click="generateExtract">Generate Email Extract</button>
  </div>

  <file-exporter ref="emaillistexport"
    apiGenerate="/ax/emailextract/generate"
    :paramsForGenerate="paramsForGenerate"
    apiRetrieve="/ax/emailextract/link/">
  </file-exporter>

</div>
</template>

<script>
import FileExporter from '../components/FileExporter.vue'
export default {
  name: 'EmailExtract',
  components: {
    FileExporter
  },
  data () {
    return {
      merchantId: '',
      merchants: [],
      paramsForGenerate: { merchantId: '' }
    }
  },
  mounted () {
    this.getMerchants()
  },
  methods: {
    getMerchants: function () {
      this.axios.get('/ax/merchants')
        .then(response => {
          this.merchants = response.data
          this.selMxIdx = '0'
          this.selMerchant = this.merchants[0]
          this.selAccountId = this.selMerchant.accounts[0].accountId
        })
        .catch(error => {
          console.log(error.response.status)
        })
    },
    generateExtract: function () {
      this.paramsForGenerate.merchantId = this.merchantId

      this.$refs.emaillistexport.generate()
    }
  }
}
</script>
