<template>
<div class="h3 text-center">
  <div class="row justify-content-center" v-if="isLoading">
    <b-spinner variant='primary' label='Please wait'></b-spinner>
    <span class="ms-3 mt-1">Loading ... Please wait</span>
  </div>
</div>
</template>

<script>
export default {
  name: 'Home',
  data () {
    return {
      isLoading: true
    }
  },
  mounted () {
    this.$router.push('/summaries')
  }
}
</script>
