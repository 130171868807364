<template>
<div class="row" v-if="loading">
  <div class="col-auto mx-auto">
    <b-spinner variant='primary' label='Please wait'></b-spinner>
    <span v-if="message" class="ms-3 mt-1">{{message}}</span>
    <span v-else class="ms-3 mt-1">Loading ... Please wait</span>
  </div>
</div>
</template>

<script>
export default {
  name: 'WaitLoading',
  props: {
    loading: Boolean,
    message: String
  }
}
</script>